export const CREATE_STORE_REQUEST = "CREATE_STORE_REQUEST";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_FAIL = "CREATE_STORE_FAIL";

export const ALL_STORE_PRODUCT_REQUEST = "ALL_STORE_PRODUCT_REQUEST";
export const ALL_STORE_PRODUCT_SUCCESS = "ALL_STORE_PRODUCT_SUCCESS";
export const ALL_STORE_PRODUCT_FAIL = "ALL_STORE_PRODUCT_FAIL";

export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_RESET = "UPDATE_STORE_RESET";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";

export const UPDATE_STORE_PRODUCT_REQUEST = "UPDATE_STORE_PRODUCT_REQUEST";
export const UPDATE_STORE_PRODUCT_SUCCESS = "UPDATE_STORE_PRODUCT_SUCCESS";
export const UPDATE_STORE_PRODUCT_FAIL = "UPDATE_STORE_PRODUCT_FAIL";
export const UPDATE_STORE_PRODUCT_RESET = "UPDATE_STORE_PRODUCT_RESET";

export const STORE_PRODUCT_DETAILS_REQUEST = "STORE_PRODUCT_DETAILS_REQUEST";
export const STORE_PRODUCT_DETAILS_FAIL = "STORE_PRODUCT_DETAILS_FAIL";
export const STORE_PRODUCT_DETAILS_SUCCESS = "STORE_PRODUCT_DETAILS_SUCCESS";

export const ALL_STORE_REQUEST = "ALL_STORE_REQUEST";
export const ALL_STORE_SUCCESS = "ALL_STORE_SUCCESS";
export const ALL_STORE_FAIL = "ALL_STORE_FAIL";

export const STORE_DETAILS_REQUEST = "STORE_DETAILS_REQUEST";
export const STORE_DETAILS_SUCCESS = "STORE_DETAILS_SUCCESS";
export const STORE_DETAILS_FAIL = "STORE_DETAILS_FAIL";


export const DELETE_STORE_REQUEST = "DELETE_STORE_REQUEST";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_RESET = "DELETE_STORE_RESET";
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL";

export const CLEAR_STORE_ERRORS = "CLEAR_STORE_ERRORS";