import React, { useEffect, Fragment, useState } from "react";
import CheckoutSteps from "../ReturnOrder/ReturnCheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/metaData";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { createReturnOrder, clearReturnErrors } from "../../actions/returnAction";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Loader from "../layout/loader/Loader";
import InfoAlert from "../Utils/InfoAlert";
import { useAlert } from "react-alert";
import axios from 'axios';
import { translator } from "../../translator"
const apiHelper = require("../../helpers")

const ConfirmOrder = () => {
    let navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { returnShippingInfo, returnCartItems } = useSelector((state) => state.returnCart);
    const { order } = useSelector((state) => state.orderDetails);
    const { error } = useSelector((state) => state.newReturnOrder);
    const { token } = useSelector((state) => state.token);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    let loading = false
    const subtotal = returnCartItems.reduce(
        (acc, item) => acc + item.quantity * item.price,
        0
    );
    const shippingCharges = subtotal < 500 ? 0 : 50;

    const tax = subtotal * 0.18;

    const totalPrice = (subtotal + tax) - shippingCharges;

    const address = `${returnShippingInfo.address}, ${returnShippingInfo.city}, ${returnShippingInfo.state}, ${returnShippingInfo.pinCode}, ${returnShippingInfo.country}`;

    const proceedToPayment = async () => {
        loading = true
        const data = {
            subtotal,
            shippingCharges,
            tax,
            totalPrice,
        };

        sessionStorage.setItem("returnOrderInfo", JSON.stringify(data));

        // let order_Ids = null
        // for (let i of returnCartItems) {
        //     if (i.order_id) {
        //         order_Ids = i.order_id
        //     }
        // }

        const orders = {
            returnShippingInfo,
            orderItems: returnCartItems,
            itemsPrice: subtotal,
            taxPrice: tax,
            shippingPrice: shippingCharges,
            totalPrice: totalPrice,
            order_id: order._id,
            user_id: order.user
        }

        // dispatch(createReturnOrder(orders));
        try {
            const response = await axios.post(apiHelper.api + "return/new", orders, config);
            if (response.data.success) {
                localStorage.clear()
                loading = false
                navigate("/returnsuccess");
            } else {
                loading = false
                alert.error(response.data.message);
            }

        } catch (error) {
            // Handle error
            loading = false
            localStorage.clear()
            alert.error(error);
        }

    };
    const [isInfoAlertOpen, setIsInfoAlertOpen] = useState(false);
    let cancelConfirmation = false
    const handleInfoAlert = (value) => {
        loading = true
        if (value === true) {
            cancelConfirmation = value
            proceedToPayment(); // Call the handler after state update
        } else {
            loading = false
        }
    };

    const openInfoAlert = () => {
        setIsInfoAlertOpen(true);
    };

    const closeInfoAlert = () => {
        setIsInfoAlertOpen(false);
    };
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearReturnErrors());
        }
    }, [dispatch, error]);

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title="Confirm Order" />
                    <CheckoutSteps activeStep={1} />
                    <div className="orderDetailsPage">
                        <div className="order-summary-container">
                            <header>{translator[language]["Confirm Return Order Details"]}</header>
                            <div className="order-summary">
                                <div className="row">
                                    <div className="col">
                                        <p className="label"><LocalShippingIcon />{translator[language]["Shipping Info"]}</p>
                                        <p className="value"><span>{user.name}</span></p>
                                        <p className="value"><span>{returnShippingInfo.phoneNo}</span></p>
                                        <p className="value"><span>{address}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="label"><CurrencyRupeeIcon />{translator[language]["Amount to be paid by plumbpigen"]}</p>
                                        <p className="value"><span className="label">{translator[language]["Sub Total"]} </span><span>₹{subtotal.toFixed(2)}</span></p>
                                        <p className="value"><span className="label">{translator[language]["GST"]} </span><span>₹{tax.toFixed(2)}</span></p>
                                        <p className="value"><span className="label">{translator[language]["Shipping Charges to be given to plumbpigen"]} </span><span>₹{shippingCharges.toFixed(2)}</span></p>
                                        <p className="value"><span className="label">{translator[language]["Please Note"]}</span><span>{translator[language]["Total amount to be paid by plumbpigen is (Sub Total + GST)-Shipping Charges"]}</span></p>
                                        <p className="valueMoney"><span>Rs {totalPrice.toFixed(2)}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="awesome-phrase-container3">
                        <h1 style={{ fontWeight: 'bolder' }}>{translator[language]["Your Return Bag Items:"]}</h1>
                        <div className="orderDetailsCartItemsContainer">
                            {returnCartItems &&
                                returnCartItems.map((item) => (
                                    <div key={item.product}>
                                        <img src={item.image} alt="Product" />
                                        <Link to={`/product/${item.product}`}>
                                            {item.name}
                                        </Link>{" "}
                                        <span>
                                            {item.quantity} X ₹{item.price} ={" "}
                                            <b>₹{item.price * item.quantity}</b>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {isInfoAlertOpen === false && cancelConfirmation === false ? (
                        <div style={{ margin: '10%' }} className="button-container">
                            <button className="button" onClick={openInfoAlert}>{translator[language]["Place Return Order"]}</button>
                        </div>
                    ) : (
                        <InfoAlert
                            open={isInfoAlertOpen}
                            onClose={closeInfoAlert}
                            onSubmitValueChange={handleInfoAlert}
                            msg={" It's been practicing its 'stay' command. 🐾❤️! Are you sure about letting these item go?"}
                        />
                    )}
                    {/* <div className="confirmOrderPage">
                <div>
                    <div className="confirmshippingArea">
                        <Typography style={{ fontWeight: 'bolder' }}>Return Shipping Info</Typography>
                        <div className="orderDetailsContainerBox">
                            <div>
                                <p>Name:</p>
                                <span>{user.name}</span>
                            </div>
                            <div>
                                <p>Phone:</p>
                                <span>{returnShippingInfo.phoneNo}</span>
                            </div>
                            <div>
                                <p>Address:</p>
                                <span>{address}</span>
                            </div>
                        </div>
                    </div>
                    <div className="confirmCartItems">
                        <Typography style={{ fontWeight: 'bolder' }}>Your Return Cart Items:</Typography>
                        <div className="confirmCartItemsContainer">
                            {returnCartItems &&
                                returnCartItems.map((item) => (
                                    <div key={item.product}>
                                        <img src={item.image} alt="Product" />
                                        <Link to={`/product/${item.product}`}>
                                            {item.name}
                                        </Link>{" "}
                                        <span>
                                            {item.quantity} X ₹{item.price} ={" "}
                                            <b>₹{item.price * item.quantity}</b>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="orderSummary">
                        <Typography style={{ fontWeight: 'bolder' }}>Order Summery</Typography>
                        <div className="orderDetailsContainerBox">
                            <div>
                                <p>Subtotal:</p>
                                <span>₹{subtotal}</span>
                            </div>
                            <div>
                                <p>Shipping Charges:</p>
                                <span>₹{shippingCharges}</span>
                            </div>
                        </div>

                        <div className="orderSummaryTotal">
                            <p>
                                <b>Total:</b>
                            </p>
                            <span>₹{totalPrice}</span>
                        </div>

                        <button onClick={proceedToPayment}>Place Return Order</button>
                    </div>
                </div>
            </div> */}
                </Fragment>
            )}
        </Fragment>
    );
};

export default ConfirmOrder;