import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearCommissionErrors, myCommissions } from "../../actions/commissionAction.js";
import MetaData from "../layout/metaData";
import { Chart, registerables } from 'chart.js';
import money from '../../images/assets/money.png'
import "../Admin//dashboard.css";
// import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Loader from "../layout/loader/Loader.js";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MyCommissionsDetails from "./CommissionDetails.js";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import { useNavigate } from 'react-router-dom';
import { translator } from "../../translator"

const Dashboard = () => {
  let navigate = useNavigate();
  function yyyymmdd() {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
  }
  function Dates(date) {
    var x = new Date(date);
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
  }
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  let loading = false

  Chart.register(...registerables);
  const dispatch = useDispatch();

  const { error, commissions } = useSelector((state) => state.myCommissions);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };
  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);
  useEffect(() => {
    loading = false
  }, [commissions]);

  useEffect(() => {
    if (error) {
      loading = false
      alert.error(error);
      dispatch(clearCommissionErrors());
    }
    loading = true
    dispatch(myCommissions());
  }, [dispatch, error]);


  let totalCommissions = 0;
  let todaysCommissions = 0;
  let MonthlyCommissions = 0;
  let Todaydate = yyyymmdd()

  commissions &&
    commissions.forEach((item) => {
      totalCommissions += item.CommissionPerOrder;
      if (Todaydate === Dates(item.CommissionIssueDate)) {
        todaysCommissions += item.CommissionPerOrder;
      }
      if (Dates(item.CommissionIssueDate) >= Dates(firstDay) && Dates(item.CommissionIssueDate) <= Dates(lastDay)) {
        MonthlyCommissions += item.CommissionPerOrder;
      }
    });

  const navigateForRequest = () => {
    navigate("/commission/request");
  }
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Earnings" />
          <div id="order-details" className="orderDetailsPage">
            <div className="order-summary-container">
              <img src={money} alt="Earnings" />
              <header>{translator[language]["Your Earnings Summary"]}</header>
              <div className="button-container">
                <Button variant="outlined" size="small" style={{ color: '#002244' }} onClick={navigateForRequest}><RequestPageIcon />{translator[language]["Request Earnings"]}</Button>
              </div>
              <div className="order-summary">
                <div className="row">
                  <div className="col">
                    <p className="label"><CurrencyRupeeIcon sx={{ mr: 1 }} />{translator[language]["Total Earnings"]}</p>
                    <p className="value"><span>₹{totalCommissions.toFixed(2)}</span></p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="label"><CalendarTodayIcon sx={{ mr: 1 }} />{translator[language]["Today's Earnings"]}</p>
                    <p className="value">₹{todaysCommissions.toFixed(2)}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="label"><CalendarMonthIcon sx={{ mr: 1 }} /> {translator[language]["This Month's Earnings"]}</p>
                    <p className="value">₹{MonthlyCommissions.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
            <MyCommissionsDetails />

          </div>
        </Fragment>
      )
      }
    </Fragment >
    // <div className="dashboard">
    //   <MetaData title="Dashboard - User Panel" />
    //   <Sidebar />

    //   <div className="dashboardContainer">
    //     {/* <Typography style={{ fontWeight: 'bolder' }} component="h1">Your Earnings</Typography> */}
    //     <div className="awesome-phrase-container3">

    //       Your Earnings
    //     </div>
    //     <Box p={2}>
    //       <Grid container spacing={2}>
    //         <Grid item xs={12} sm={6} md={3}>
    //           <Card variant="outlined" sx={{ backgroundColor: '#002f5e' }}>
    //             <CardContent>
    //               <Grid container spacing={1} alignItems="center">
    //                 <Grid item>
    //                   <CurrencyRupeeIcon fontSize="large" sx={{ color: '#69f0ae' }} />
    //                 </Grid>
    //                 <Grid item>
    //                   <h1 style={{ color: 'white' }} variant="h6">Total Earnings</h1>
    //                   <h1 style={{ color: 'white' }} variant="h4">₹{totalCommissions.toFixed(2)}</h1>
    //                 </Grid>
    //               </Grid>
    //             </CardContent>
    //           </Card>
    //         </Grid>
    //         <Grid item xs={12} sm={6} md={3}>
    //           <Card variant="outlined" sx={{ backgroundColor: '#002f5e' }}>
    //             <CardContent>
    //               <Grid container spacing={1} alignItems="center">
    //                 <Grid item>
    //                   <CalendarTodayIcon fontSize="large" sx={{ color: '#69f0ae' }} />
    //                 </Grid>
    //                 <Grid item>
    //                   <h1 style={{ color: 'white' }} variant="h6">Today</h1>
    //                   <h1 style={{ color: 'white' }} variant="h4">₹{todaysCommissions.toFixed(2)}</h1>
    //                 </Grid>
    //               </Grid>
    //             </CardContent>
    //           </Card>
    //         </Grid>

    //         <Grid item xs={12} sm={6} md={3}>
    //           <Card variant="outlined" sx={{ backgroundColor: '#002f5e' }}>
    //             <CardContent>
    //               <Grid container spacing={1} alignItems="center">
    //                 <Grid item>
    //                   <CalendarMonthIcon fontSize="large" sx={{ color: '#69f0ae' }} />
    //                 </Grid>
    //                 <Grid item>
    //                   <h1 style={{ color: 'white' }} variant="h6">This Month</h1>
    //                   <h1 style={{ color: 'white' }} variant="h4">₹{MonthlyCommissions.toFixed(2)}</h1>
    //                 </Grid>
    //               </Grid>
    //             </CardContent>
    //           </Card>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //     {/* <div className="dashboardSummary">
    //       <div>
    //         <p>
    //           Total Commission <br /> ₹{totalCommissions.toFixed(2)}
    //         </p>
    //       </div>
    //       <div className="dashboardSummaryBox2">
    //         <Link to="">
    //           <p>Today</p>
    //           <p>₹{todaysCommissions.toFixed(2)}</p>
    //         </Link>
    //         <Link to="">
    //           <p>This Month</p>
    //           <p>₹{MonthlyCommissions.toFixed(2)}</p>
    //         </Link>
    //       </div>
    //     </div> */}

    //     {/* <div className="lineChart">
    //       <Line data={lineState} />
    //     </div>

    //     <div className="doughnutChart">
    //       <Doughnut data={doughnutState} />
    //     </div> */}
    //   </div>
    // </div>
  );
};

export default Dashboard;