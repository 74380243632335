import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Typography, Stepper, StepLabel, Step } from "@material-ui/core";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import "./CheckoutSteps.css";
import { translator } from "../../translator"

const CheckoutSteps = ({ activeStep }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };

  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);
  const steps = [
    {
      label: <Typography>{translator[language]["Shipping Details"]}</Typography>,
      icon: <LocalShippingIcon />,
    },
    {
      label: <Typography>{translator[language]["Confirm Order"]}</Typography>,
      icon: <LibraryAddCheckIcon />,
    },
    {
      label: <Typography>{translator[language]["Payment"]}</Typography>,
      icon: <AccountBalanceIcon />,
    },
  ];

  const stepStyles = {
    boxSizing: "border-box",
  };

  return (
    <Fragment>
      <Stepper alternativeLabel activeStep={activeStep} style={stepStyles}>
        {steps.map((item, index) => (
          <Step
            key={index}
            active={activeStep === index ? true : false}
            completed={activeStep >= index ? true : false}
          >
            <StepLabel
              style={{
                color: activeStep >= index ? "#01a2f5" : "rgba(0, 0, 0, 0.649)",
              }}
              icon={item.icon}
            >
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Fragment>
  );
};

export default CheckoutSteps;
