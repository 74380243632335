import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LoginIcon from '@mui/icons-material/Login';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import "./navBar.css";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { translator } from "../../translator"
// import FavoriteIcon from '@mui/icons-material/Favorite';

const BottomNavBar = (props) => {
    const { myProp } = props
    const user_detail = myProp.user_details
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");
    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    return (
        <div>
            {user_detail && (user_detail.role === "guide") &&
                <div className="navbar">
                    <div>
                        <Link className="carouselLink" to="/">
                            <div className="icon">
                                <HomeIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Home</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/guide/english/menu
                    ">
                            <div className="icon">
                                <MenuIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Menu</p>
                    </div>
                </div>
            }
            {/* {user && (user.role === "admin" || user.role === "developer") &&
                <div className="navbar">
                    <div>
                        <Link className="carouselLink" to="/admin/dashboard">
                            <div className="icon">
                                <DashboardIcon />
                            </div>
                            <p className="iconDescription">Dashboard</p>
                        </Link>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/products">
                            <div className="icon">
                                <PlumbingIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Products</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/guide/selectlanguage">
                            <div className="icon">
                                <MenuBookIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Guide</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/admin/orders">
                            <div className="icon">
                                <ListAltIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Orders</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/admin/stores">
                            <div className="icon">
                                <StoreMallDirectoryIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Stores</p>
                    </div>
                </div>
            } */}
            {/* {user && user.role === "user" &&
                <div className="navbar">
                    <div>
                        <Link className="carouselLink" to="/commission/dashboard">
                            <div className="icon">
                                <AttachMoneyIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Earnings</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/products">
                            <div className="icon">
                                <PlumbingIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Products</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/orders">
                            <div className="icon">
                                <ListAltIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Orders</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/bag">
                            <div className="icon">
                                <LocalMallIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Bag</p>
                    </div>
                </div>
            } */}
            {!user_detail &&
                <div className="navbar">
                    <div>
                        <Link className="carouselLink" to="/login">
                            <div className="icon">
                                <LoginIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Login"]}</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/products">
                            <div className="icon">
                                <PlumbingIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Products"]}</p>
                    </div>
                </div>
            }
            {/* {user && user.role === "storeEmployee" &&
                <div className="navbar">
                    <div>
                        <Link className="carouselLink" to="/store/dashboard">
                            <div className="icon">
                                <StoreMallDirectoryIcon />
                            </div>

                        </Link>
                        <p className="iconDescription">Dashboard</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/products">
                            <div className="icon">
                                <PlumbingIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Products</p>
                    </div>
                    <div>
                        <Link to="/store/orders">
                            <div className="icon">
                                <ListAltIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">Orders</p>
                    </div>
                </div>
            } */}
            {user_detail &&
                <div className="navbar">
                    <div>
                        <Link className="carouselLink" to="/">
                            <div className="icon">
                                <HomeOutlinedIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Home"]}</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/products">
                            <div className="icon">
                                <PlumbingIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Products"]}</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/uploadorder">
                            <div className="icon">
                                <CameraAltOutlinedIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Upload"]}</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/orders">
                            <div className="icon">
                                <ReceiptLongOutlinedIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Orders"]}</p>
                    </div>
                    <div>
                        <Link className="carouselLink" to="/bag">
                            <div className="icon">
                                <ShoppingBagOutlinedIcon />
                            </div>
                        </Link>
                        <p className="iconDescription">{translator[language]["Bag"]}</p>
                    </div>
                </div>
            }
        </div>
    )
};

export default BottomNavBar;