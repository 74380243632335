import {
    ADD_TO_RETURN_CART,
    REMOVE_RETURN_CART_ITEM,
    SAVE_RETURN_SHIPPING_INFO,
    // MY_CART_REQUEST,
    // MY_CART_SUCCESS,
    // MY_CART_FAIL,
    // CREATE_CART_REQUEST,
    // CREATE_CART_SUCCESS,
    // CREATE_CART_FAIL,
  } from "../constants/returnCartConstants";
  import axios from "axios";
const apiHelper = require("../helpers")
  // Add to Cart
export const addItemsToReturnCart = (id, quantity, order_id) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  const { data } = await axios.get(`${apiHelper.api}product/${id}`,config);
    
    dispatch({
      type: ADD_TO_RETURN_CART,
      payload: {
        product: data.product._id,
        name: data.product.name,
        price: data.product.price,
        image: data.product.images[0].url,
        stock: data.product.Stock,
        type: data.product.type,
        brand: data.product.brand,
        category: data.product.category,
        quantity,
        order_id
      },
    });
  
      localStorage.setItem("returnCartItems", JSON.stringify(getState().returnCart.returnCartItems));
      
  };
  
  // REMOVE FROM CART
  export const removeItemsFromReturnCart = (id) => async (dispatch, getState) => {
    dispatch({
      type: REMOVE_RETURN_CART_ITEM,
      payload: id,
    });
      localStorage.setItem("returnCartItems", JSON.stringify(getState().returnCart.returnCartItems));
  };
  
  // SAVE SHIPPING INFO
  export const saveReturnShippingInfo = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_RETURN_SHIPPING_INFO,
      payload: data,
    });

    localStorage.setItem("returnShippingInfo", JSON.stringify(data));
  };

//  // Create Cart
//  export const createOrder = (cart) => async (dispatch) => {
//   try {
//     dispatch({ type: CREATE_CART_REQUEST });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     const { data } = await axios.post("/api/v1/cart/new", cart, config);

//     dispatch({ type: CREATE_CART_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({
//       type: CREATE_CART_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };

//   // My Cart
//   export const myOrders = () => async (dispatch) => {
//     try {
//       dispatch({ type: MY_CART_REQUEST });
  
//       const { data } = await axios.get("/api/v1/cart/me");
  
//       dispatch({ type: MY_CART_SUCCESS, payload: data.orders });
//     } catch (error) {
//       dispatch({
//         type: MY_CART_FAIL,
//         payload: error.response.data.message,
//       });
//     }
//   };RETURN_