import {
    ALL_STORE_PRODUCT_FAIL,
    ALL_STORE_PRODUCT_REQUEST,
    ALL_STORE_PRODUCT_SUCCESS,
    ALL_STORE_FAIL,
    ALL_STORE_REQUEST,
    ALL_STORE_SUCCESS,
    STORE_DETAILS_REQUEST,
    STORE_DETAILS_FAIL,
    STORE_DETAILS_SUCCESS,
    UPDATE_STORE_PRODUCT_REQUEST,
    UPDATE_STORE_PRODUCT_SUCCESS,
    UPDATE_STORE_PRODUCT_FAIL,
    STORE_PRODUCT_DETAILS_REQUEST,
    STORE_PRODUCT_DETAILS_FAIL,
    STORE_PRODUCT_DETAILS_SUCCESS,
    CREATE_STORE_REQUEST,
    CREATE_STORE_SUCCESS,
    CREATE_STORE_FAIL,
    UPDATE_STORE_REQUEST,
    UPDATE_STORE_SUCCESS,
    UPDATE_STORE_FAIL,
    DELETE_STORE_REQUEST,
    DELETE_STORE_SUCCESS,
    DELETE_STORE_FAIL,
    CLEAR_STORE_ERRORS,
} from "../constants/storeConstants";

import axios from "axios";
const apiHelper = require("../helpers")

// Create Store
export const createStore = (store) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: CREATE_STORE_REQUEST });
        const { data } = await axios.post(apiHelper.api + "store/new", store, config);

        dispatch({ type: CREATE_STORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CREATE_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update Store
export const updateStore = (id, form) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: UPDATE_STORE_REQUEST });
        const { data } = await axios.put(
            `${apiHelper.api}admin/store/${id}`,
            form,
            config
        );
        dispatch({ type: UPDATE_STORE_SUCCESS, payload: data.success });


    } catch (error) {
        dispatch({
            type: UPDATE_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Products For store
export const getStoreProduct = (store_id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    let payload = {
        store_id: store_id
    }
    try {
        dispatch({ type: ALL_STORE_PRODUCT_REQUEST });
        const { data } = await axios.post(apiHelper.api + "store/products", payload, config);

        dispatch({
            type: ALL_STORE_PRODUCT_SUCCESS,
            payload: data.stores.products,
        });
    } catch (error) {
        dispatch({
            type: ALL_STORE_PRODUCT_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update Store Product
export const updateStoreProduct = (id, productData, store_id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    // let payload = {
    //     product_id: id,
    //     productData: productData,
    //     store_id: store_id
    // }
    try {
        dispatch({ type: UPDATE_STORE_PRODUCT_REQUEST });

        const { data } = await axios.put(
            `${apiHelper.api}store/product/${id}`,
            productData,
            config
        );

        dispatch({
            type: UPDATE_STORE_PRODUCT_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_STORE_PRODUCT_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Store Products Details
export const getStoreProductDetails = (id, store_id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    let payload = {
        product_id: id,
        store_id: store_id
    }
    try {
        dispatch({ type: STORE_PRODUCT_DETAILS_REQUEST });

        const { data } = await axios.post(`${apiHelper.api}store/product/${id}`, payload, config);
        dispatch({
            type: STORE_PRODUCT_DETAILS_SUCCESS,
            payload: data.product,
        });
    } catch (error) {
        dispatch({
            type: STORE_PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// get All Store
export const getAllStores = () => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: ALL_STORE_REQUEST });
        const { data } = await axios.get(`${apiHelper.api}admin/stores`, config);

        dispatch({ type: ALL_STORE_SUCCESS, payload: data.stores });
    } catch (error) {
        dispatch({ type: ALL_STORE_FAIL, payload: error.response.data.message });
    }
};

// get Store Details
export const getStoreDetails = (id, reason) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    if (reason && (reason === 'for_store_owner' || reason === 'for_admin_order_dashboard')) {
        let payload = { store_id: id }
        try {
            dispatch({ type: STORE_DETAILS_REQUEST });
            const { data } = await axios.post(apiHelper.api + "store/details", payload, config);

            dispatch({ type: STORE_DETAILS_SUCCESS, payload: data.store });
        } catch (error) {
            dispatch({ type: STORE_DETAILS_FAIL, payload: error.response.data.message });
        }
    } else {

        try {
            dispatch({ type: STORE_DETAILS_REQUEST });
            const { data } = await axios.get(`${apiHelper.api}admin/store/${id}`, config);

            dispatch({ type: STORE_DETAILS_SUCCESS, payload: data.store });
        } catch (error) {
            dispatch({ type: STORE_DETAILS_FAIL, payload: error.response.data.message });
        }
    }
};

// Delete Store
export const deleteStore = (id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: DELETE_STORE_REQUEST });

        const { data } = await axios.delete(`${apiHelper.api}admin/store/${id}`, config);

        dispatch({ type: DELETE_STORE_SUCCESS, payload: data.success });
    } catch (error) {
        dispatch({
            type: DELETE_STORE_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clearing Errors
export const clearStoreErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_STORE_ERRORS });
};