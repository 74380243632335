import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    TextField,
} from '@mui/material';

function InfoAlert({ onSubmitValueChange, open, onClose, msg }) {
    // const [submitValue, setSubmitValue] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (value) => {
        onSubmitValueChange(value);
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{msg}</DialogTitle>
            <Box m="20px">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6}>
                        <Button onClick={() => handleSubmit(true)} variant="outlined" color="primary">
                            Yes
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleSubmit(false)} variant="outlined" color="secondary">
                            No
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}

export default InfoAlert;
