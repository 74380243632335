import React, { Fragment, useEffect, useState } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector } from "react-redux";
import MetaData from "../layout/metaData";
import "./ConfirmOrder.css";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { translator } from "../../translator"

const ConfirmOrder = () => {
  let navigate = useNavigate();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };

  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);

  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  let shippingCharges = subtotal > 1500 ? 0 : 100;

  for (let item of cartItems) {
    if (item && item.type && (item.type === "PIPE" || item.type === " SWR PIPE")) {
      if (item.quantity > 5 && item.quantity <= 50) {
        shippingCharges = shippingCharges + 50;
      } else if (item.quantity > 50) {
        shippingCharges = shippingCharges + 100;
      }
      break
    }
  }

  const tax = subtotal * 0.18;
  // const tax = 0;

  const totalPrice = subtotal + tax + shippingCharges;

  const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;

  const user_note = shippingInfo.user_note

  const scheduledDelivery = shippingInfo.scheduledDelivery

  const scheduledDeliveryTimestamp = shippingInfo.selectedDateTime


  const proceedToPayment = () => {
    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
      scheduledDelivery,
      scheduledDeliveryTimestamp
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));

    navigate("/process/payment");
  };

  return (
    <Fragment>
      <MetaData title="Confirm Order" />
      <CheckoutSteps activeStep={1} />
      <div className="orderDetailsPage">
        <div className="order-summary-container">
          <header>{translator[language]["Confirm Order Details"]}</header>
          <div className="order-summary">
            <div className="row">
              <div className="col">
                <p className="label"><LocalShippingIcon sx={{ mr: 1 }} />{translator[language]["Shipping Info"]}</p>
                <p className="value"><span>{user.name}</span></p>
                <p className="value"><span>{shippingInfo.phoneNo}</span></p>
                <p className="value"><span>{address}</span></p>
                {user_note && user_note.length > 0 &&
                  <p className="value"><span>{user_note}</span></p>
                }
              </div>
            </div>
            {scheduledDelivery === true && scheduledDeliveryTimestamp &&
              <div className="row">
                <div className="col">
                  <p className="label"><AccessTimeOutlinedIcon sx={{ mr: 1 }} />{translator[language]["Delivery on"]}</p>
                  <p className="value"><span>{scheduledDeliveryTimestamp}</span></p>
                </div>
              </div>
            }
            <div className="row">
              <div className="col">
                <p className="label"><CurrencyRupeeIcon sx={{ mr: 1 }} />{translator[language]["Bill Details"]}</p>
                <p className="value"><span className="label">{translator[language]["Sub Total:"]} </span><span>₹{subtotal.toFixed(2)}</span></p>
                <p className="value"><span className="label">{translator[language]["Delivery Fees-"]} </span><span>₹{shippingCharges.toFixed(2)}</span></p>
                <p className="value"><span className="label">{translator[language]["GST:"]} </span><span>₹{tax.toFixed(2)}</span></p>
                <p className="valueMoney"><span>Rs {totalPrice.toFixed(2)}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="awesome-phrase-container3">
        <h1 style={{ fontWeight: 'bolder' }}>{translator[language]["Your Bag Items:"]}</h1>
        <div className="orderDetailsCartItemsContainer">
          {cartItems &&
            cartItems.map((item) => (
              <div key={item.product}>
                <img src={item.image} alt="Product" />
                <Link to={`/product/${item.product}`}>
                  {item.name}
                </Link>{" "}
                {item.brand}
                <span>
                  {item.quantity} X ₹{item.price} ={" "}
                  <b>₹{item.price * item.quantity}</b>
                </span>
              </div>
            ))}
        </div>
      </div>
      <div style={{ margin: '10%' }} className="button-container">
        <button className="button2" onClick={proceedToPayment}>{translator[language]["Proceed To Payment"]}</button>
      </div>
      <br></br>
      <br></br>
    </Fragment>
  );
};

export default ConfirmOrder;