import React, { Fragment } from "react";
import MetaData from "../layout/metaData";
import CommissionSidebar from "./CommissionSidebar.js";
import MyOrders from '../Order/MyOrder.js'

const YourOrderList = () => {

    return (
        <Fragment>
            <MetaData title={`ALL ORDERS`} />
            <div className="dashboard">
                <CommissionSidebar />
                <MyOrders />
            </div>
        </Fragment>
    );
};

export default YourOrderList;