import React, { Fragment, useEffect, useState } from "react";
import "./orderDetails.css";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/metaData";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { getOrderDetails, clearErrors, updateOrder, } from "../../actions/orderAction";
import { addItemsToReturnCart, saveReturnShippingInfo } from "../../actions/returnCartActions";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import { useParams } from 'react-router-dom'
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import { useNavigate } from 'react-router-dom';
import TagIcon from '@mui/icons-material/Tag';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import InfoAlert from "../Utils/InfoAlert";
import myorders from '../../images/assets/orders.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import axios from "axios";
import return_orders from '../../images/assets/return_orders.png'
import { translator } from "../../translator"
const apiHelper = require("../../helpers")

const OrderDetails = () => {
  let navigate = useNavigate();
  const { id } = useParams()
  let { order, error, loading } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.token);
  const [returns, setReturns] = useState({});
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };
  const returnOrderSubmitHandler = () => {
    for (let i of order.orderItems) {
      if (i.type === 'PIPE' || i.type === 'SWR PIPE') {
        if (i.category === 'APVC' || i.category === 'CPVC') {
          if (i.quantity >= 16) {
            dispatch(addItemsToReturnCart(i.product, i.quantity, i._id));
          }
        } else if (i.quantity >= 20) {
          dispatch(addItemsToReturnCart(i.product, i.quantity, i._id));
        }
      } else {
        dispatch(addItemsToReturnCart(i.product, i.quantity, i._id));
      }
    }
    let address = order.shippingInfo.address
    let city = order.shippingInfo.city
    let state = order.shippingInfo.state
    let country = order.shippingInfo.country
    let pinCode = order.shippingInfo.pinCode
    let phoneNo = order.shippingInfo.phoneNo
    dispatch(
      saveReturnShippingInfo({ address, city, state, country, pinCode, phoneNo })
    );
    alert.success("Item Added To Return Bag");
    navigate(`/return/${id}`);
  }
  const updateOrderSubmitHandler = () => {
    if (cancelConfirmation === true) {
      const myForm = { status: 'Cancelled' }
      dispatch(updateOrder(id, myForm));
    }
  };
  const checkOrderSubmitHandler = () => {
    navigate(`/checkorder/${id}`);
  };
  // const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const alert = useAlert();

  const [isInfoAlertOpen, setIsInfoAlertOpen] = useState(false);
  let cancelConfirmation = false
  const handleInfoAlert = (value) => {
    if (value === true) {
      loading = true
      cancelConfirmation = value
      updateOrderSubmitHandler(); // Call the handler after state update
    }
  };

  const openInfoAlert = () => {
    setIsInfoAlertOpen(true);
  };

  const closeInfoAlert = () => {
    setIsInfoAlertOpen(false);
  };
  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);
  useEffect(() => {
    if (order && order._id) {
      axios.post(apiHelper.api + 'getReturnOrderByOrderId', { order_id: order._id }, config)
        .then(response => {
          if (response.data.success) {
            setReturns(response.data.returns)
          }
        }).catch(error => {
          alert.error(error.response.data.message)
        });
    }
  }, [order])
  useEffect(() => {
    if (error) {
      loading = false
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      loading = false
      alert.error(updateError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      loading = false
      alert.success("Order Cancelled Successfully");
      dispatch({ type: UPDATE_ORDER_RESET });
    }

    dispatch(getOrderDetails(id));
  }, [dispatch, alert, error, id, isUpdated, updateError]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Order Details" />
          {order &&
            <div id="order-details" className="orderDetailsPage">
              <div className="order-summary-container">
                <img src={myorders} alt="Orders" />
                <header>{translator[language]["Order Summary"]}</header>
                <div className="order-summary">
                  <div className="row">
                    <div className="col">
                      <p className="label"><TagIcon sx={{ mr: 1 }} />{translator[language]["Order ID:"]}</p>
                      <p className="value"><span>{order && order._id}</span></p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="label"><HourglassBottomIcon sx={{ mr: 1 }} />{translator[language]["Status:"]}</p>
                      <p className="value">{order.orderStatus && order.orderStatus}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="label"><LocalShippingIcon sx={{ mr: 1 }} />  {translator[language]["Shipping Info:"]}</p>
                      <p className="value">{order.shippingInfo &&
                        `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="label"><EventNoteIcon sx={{ mr: 1 }} />  {translator[language]["Your Note"]}</p>
                      <p className="value">{order && order.shippingInfo && order.user_note}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="label"><CurrencyRupeeIcon sx={{ mr: 1 }} />{translator[language]["Sub Total:"]}</p>
                      <p className="value">{order.itemsPrice && order.itemsPrice}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="label"><AssuredWorkloadOutlinedIcon sx={{ mr: 1 }} /> {translator[language]["GST:"]}</p>
                      <p className="value">{order.taxPrice && order.taxPrice.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="label"><CurrencyRupeeIcon sx={{ mr: 1 }} />{translator[language]["Payment Status:"]}</p>
                      <p className="value">{order && order.paymentInfo && order.paymentInfo.status}</p>
                      <p className="value">{order && order.paymentInfo && order.paymentInfo.paymentMode}</p>
                      <p className="value">{translator[language]["Delivery Fees-"]} <span>Rs {order && order.shippingPrice}</span> </p>
                      <p className="valueMoney"><span>Rs {order && order.totalPrice && order.totalPrice.toFixed(2)}</span></p>
                    </div>
                  </div>
                </div>
                {order.orderStatus === 'Returned' &&
                  <div className="awesome-phrase-container2">
                    <p className="awesome-phrase">
                      {translator[language]["Order is returned"]} 🔄📦
                    </p>
                  </div>
                }
                {(order.returnOrder === false && order.orderStatus !== 'Cancelled' && order.orderStatus !== 'Returned') &&
                  <div id="exclude1" className="awesome-phrase-container">
                    <p className="awesome-phrase">
                      {translator[language]["You have requested to Process Commission on this Order and hence it cannot be Returned"]} 🚫
                    </p>
                  </div>
                }
                {order.orderStatus === 'Processing' &&
                  <div className="awesome-phrase-container2">
                    <p className="awesome-phrase">
                      {translator[language]["Order is placed"]} 📦👍
                    </p>
                  </div>
                }
                {order.orderStatus === 'Shipped' && order.orderAccepted === false &&
                  <div className="awesome-phrase-container2">
                    <p className="awesome-phrase">
                      {translator[language]["Order is shipped and will be delivered soon!"]} 🎉
                    </p>
                  </div>
                }
                {order.orderStatus === 'Cancelled' &&
                  <div className="awesome-phrase-container">
                    <p className="awesome-phrase">
                      {translator[language]["Order is Cancelled'"]} ❌😔
                    </p>
                  </div>
                }
                {order.orderStatus === 'Delivered' && order.returnOrder === true &&
                  <div className="awesome-phrase-container2">
                    <p className="awesome-phrase">
                      {translator[language]["Order is delivered"]} ✅
                    </p>
                  </div>
                }
                {order && order.orderAccepted === true && order.orderStatus !== 'Delivered' && order.orderStatus !== 'Returned' &&
                  <div className="awesome-phrase-container2">
                    <p className="awesome-phrase">
                      {translator[language]["You have accepted the order"]} 🥳
                    </p>
                  </div>
                }
                {cancelConfirmation === false && order && order.orderStatus !== 'Cancelled' && order.orderStatus !== 'Delivered' && order.orderStatus !== 'Returned' && order.orderAccepted === false &&
                  <div className="awesome-phrase-container">
                    <p className="awesome-phrase">
                      {translator[language]["Cancel order before it is shipped!"]} ❌
                    </p>
                  </div>
                }
                {isInfoAlertOpen === false && cancelConfirmation === false && order && order.orderStatus !== 'Cancelled' && order.orderStatus !== 'Delivered' && order.orderAccepted === false && order.orderStatus !== 'Returned' ? (
                  <div className="button-container">
                    <button onClick={openInfoAlert} className="button">{translator[language]["Cancel Order"]}</button>
                  </div>
                ) : (
                  <InfoAlert
                    open={isInfoAlertOpen}
                    onClose={closeInfoAlert}
                    onSubmitValueChange={handleInfoAlert}
                    msg={" We'll miss you 😔! Are you sure you want to cancel this order?"}
                  />
                )}
                {order && order.orderStatus !== 'Delivered' && order.orderStatus !== 'Cancelled' && order.orderStatus === 'Shipped' && order.orderStatus !== 'Returned' && order.orderAccepted === false &&
                  <div className="button-container">
                    <button onClick={checkOrderSubmitHandler} className="button2">{translator[language]["Check Items"]}</button>
                  </div>
                }
                {order.orderStatus === 'Delivered' && order.returnOrder === true &&
                  <div className="button-container">
                    <button onClick={returnOrderSubmitHandler} className="button">{translator[language]["Return Items"]}</button>
                  </div>
                }
                {order.orderStatus === 'Delivered' &&
                  < div className="button-container">
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: "/order/bill" }} state={{ order }} className="button2">{translator[language]["View Bill"]}</Link>
                  </div>
                }
              </div>
              {returns && returns._id &&
                <div className="order-summary-container">
                  <Accordion >
                    <AccordionSummary>
                      <Typography style={{ display: 'flex', alignItems: 'center', color: '#002244', fontWeight: 'bold', marginTop: '5%' }} variant="h6"><img x={{ display: 'inline', mr: 1 }} src={return_orders} alt="Return Order" />{translator[language]["You have Returned Few Items"]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <header>{translator[language]["Return Order Summary"]}</header>
                        <div className="order-summary">
                          <div className="row">
                            <div className="col">
                              <p className="label"><TagIcon />{translator[language]["Return Order ID:"]}</p>
                              <p className="value"><span>{returns && returns._id}</span></p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p className="label"><HourglassBottomIcon />{translator[language]["Status:"]}</p>
                              <p className="value">{returns && returns.returnStatus}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p className="label"><LocalShippingIcon />{translator[language]["Shipping Info:"]}</p>
                              <p className="value">{returns && returns.returnShippingInfo &&
                                `${returns.returnShippingInfo.address}, ${returns.returnShippingInfo.city}, ${returns.returnShippingInfo.state}, ${returns.returnShippingInfo.pinCode}, ${returns.returnShippingInfo.country}`}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p className="label"><CurrencyRupeeIcon />{translator[language]["Payment Status:"]}</p>
                              <p className="value">{returns && returns.returnPaymentInfo && returns.returnPaymentInfo.status}</p>
                              <p className="value">{returns && returns.returnPaymentInfo && returns.returnPaymentInfo.mode}</p>
                              <p className="value">{translator[language]["Return Delivery Fees-"]} <span>Rs {returns && returns.returnShippingPrice}</span> </p>
                              <p className="valueMoney"><span>Rs {returns.returnTotalPrice && returns.returnTotalPrice.toFixed(2)}</span></p>
                            </div>
                          </div>
                        </div>
                        <div className="awesome-phrase-container4">
                          <h1 style={{ fontWeight: 'bolder' }}>{translator[language]["Return Order Items:"]}</h1>
                          <div className="orderDetailsCartItemsContainer">
                            {returns && returns.returnItems &&
                              returns.returnItems.map((item) => (
                                <div key={item.product}>
                                  <img src={item.image} alt="Product" />
                                  <Link to={`/product/${item.product}`}>
                                    {item.name}
                                  </Link>{" "}
                                  <span>
                                    {item.quantity} X ₹{item.price} ={" "}
                                    <b>₹{item.price * item.quantity}</b>
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              }
              {/* <div className="orderDetailsContainer">
                <h1 style={{ marginBottom: '20px', color: '#01a2f5to' }}>
                  Order #{order && order._id}
                </h1>
                {order.orderStatus === 'Returned' &&
                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'tomato', color: 'white' }}>Order is returned</h1>
                  </div>
                }
                {order.returnOrder === false &&
                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'tomato', color: 'white' }}>You have requested to Process Commission on this Order and hence it cannot be Returned</h1>
                  </div>
                }
                {order.orderStatus === 'Processing' &&
                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'lightgreen', color: 'white' }}>Order is placed</h1>
                  </div>
                }
                {order.orderStatus === 'Shipped' &&
                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'lightgreen', color: 'white' }}>Order is shipped and will be delivered soon!</h1>
                  </div>
                }
                {order.orderStatus === 'Cancelled' &&
                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'tomato', color: 'white' }}>Order is Cancelled</h1>
                  </div>
                }
                {order.orderStatus === 'Delivered' && order.returnOrder === true &&
                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'lightgreen', color: 'white' }}>Order is delivered</h1>
                    <button onClick={returnOrderSubmitHandler} className="card-button">Return Items</button>
                  </div>

                }
                {order && order.orderStatus !== 'Cancelled' && order.orderStatus !== 'Delivered' && order.orderStatus !== 'Returned' &&

                  <div className="statu-container">
                    <h1 className="status-title" style={{ marginBottom: '20px', color: '#002244' }} >Cancel order before it is delivered!</h1>
                    <button style={{ backgroundColor: 'tomato' }} onClick={updateOrderSubmitHandler} className="card-button">Cancel Order</button>
                  </div>
                }

                <h1 style={{ fontWeight: 'bolder' }}>Shipping Info</h1>
                <div className="orderDetailsContainerBox">
                  <div>
                    <p></p>
                    <span>{order.user && order.user.name}</span>
                  </div>
                  <div>
                    <p></p>
                    <span>
                      {order.shippingInfo && order.shippingInfo.phoneNo}
                    </span>
                  </div>
                  <div>
                    <p></p>
                    <span>
                      {order.shippingInfo &&
                        `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                    </span>
                  </div>
                  <div>
                    <p></p>
                    <span>
                      {order.shippingInfo &&
                        order.user_note}
                    </span>
                  </div>
                </div>
                <h1 style={{ fontWeight: 'bolder' }}>Payment</h1>
                <div className="orderDetailsContainerBox">
                  <div>
                    <p style={{ fontWeight: 'bolder' }}
                      className={
                        order.paymentInfo &&
                          order.paymentInfo.status === "PAID"
                          ? "greenColor"
                          : "redColor"
                      }
                    >
                      {order.paymentInfo &&
                        order.paymentInfo.status === "PAID"
                        ? "PAID"
                        : "NOT PAID"}
                    </p>
                  </div>

                  <div>
                    <p style={{ fontWeight: 'bolder' }}>Amount:</p>
                    <span>Rs {order && order.totalPrice && order.totalPrice.toFixed(2)}</span>
                  </div>
                </div>
                <h1 style={{ fontWeight: 'bolder' }}>Order Status</h1>
                <div className="orderDetailsContainerBox">
                  <div>
                    <p style={{ fontWeight: 'bolder' }}
                      className={
                        order.orderStatus && order.orderStatus === "Delivered"
                          ? "greenColor"
                          : "redColor"
                      }
                    >
                      {order.orderStatus && order.orderStatus}
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="awesome-phrase-container7">
                <h1 style={{ fontWeight: 'bolder' }}>{translator[language]["Order Items:"]}</h1>
                <div className="orderDetailsCartItemsContainer">
                  {order.orderItems &&
                    order.orderItems.map((item) => (
                      <div key={item.product}>
                        <img src={item.image} alt="Product" />
                        <Link to={`/product/${item.product}`}>
                          {item.name}
                        </Link>{" "}
                        <span>
                          <b>{item.brand}</b>
                        </span>
                        <span>
                          {item.quantity} X ₹{item.price} ={" "}
                          <b>₹{item.price * item.quantity}</b>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          }
        </Fragment>
      )
      }
    </Fragment >
  );
};

export default OrderDetails;