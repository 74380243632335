import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstant";
import axios from "axios";
const apiHelper = require("../helpers")

// Login
export const login = (id, password, login_type) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: LOGIN_REQUEST });
    if (login_type === 'phone') {
      let phone = id
      const { data } = await axios.post(
        `${apiHelper.api}login`,
        { phone, password, login_type },
        config
      );
      dispatch({ type: LOGIN_SUCCESS, payload: data });
    } else {
      let email = id
      const { data } = await axios.post(
        `${apiHelper.api}login`,
        { email, password, login_type },
        config
      );
      dispatch({ type: LOGIN_SUCCESS, payload: data });
    }

  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};
export const switchUser = (target_user) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await axios.post(
      `${apiHelper.api}switchUser`,
      { target_user },
      config
    );
    dispatch({ type: LOGIN_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};

// Register
export const register = (userData, store) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    if (store === 'store') {
      dispatch({ type: REGISTER_USER_REQUEST });

      // const config = { headers: { "Content-Type": "multipart/form-data" } };

      const { data } = await axios.post(`${apiHelper.api}admin/adduser`, userData, config);

      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    } else {
      dispatch({ type: REGISTER_USER_REQUEST });

      // const config = { headers: { "Content-Type": "multipart/form-data" } };

      const { data } = await axios.post(`${apiHelper.api}register`, userData, config);

      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    }
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Load User
export const loadUser = () => async (dispatch, getState) => {
  const { token } = getState().token
  // console.log(token)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const { data } = await axios.get(`${apiHelper.api}me`, config);
    dispatch({ type: LOAD_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.response.data.message });
  }
};

// Logout User
export const logout = () => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    await axios.get(`${apiHelper.api}logout`, config);

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
  }
};

// Update Profile
export const updateProfile = (userData) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    // const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.put(`${apiHelper.api}me/update`, userData, config);

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Password
export const updatePassword = (passwords) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    // const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `${apiHelper.api}password/update`,
      passwords,
      config
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Forgot Password
export const forgotPassword = (email) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    // const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(`${apiHelper.api}password/forgot`, email, config);

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    // const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `${apiHelper.api}password/reset/${token}`,
      passwords,
      config
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get All Users
export const getAllUsers = () => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data } = await axios.get(`${apiHelper.api}admin/users`, config);

    dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error.response.data.message });
  }
};

// get  User Details
export const getUserDetails = (id) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const { data } = await axios.get(`${apiHelper.api}admin/user/${id}`, config);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: USER_DETAILS_FAIL, payload: error.response.data.message });
  }
};

// Update User
export const updateUser = (id, userData) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    // const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `${apiHelper.api}admin/user/${id}`,
      userData,
      config
    );

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const { data } = await axios.delete(`${apiHelper.api}admin/user/${id}`, config);

    dispatch({ type: DELETE_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};