import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
// import "./commissionDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { clearCommissionErrors, updateCommission, myCommissions } from "../../actions/commissionAction";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/metaData";
import { useNavigate } from "react-router-dom";
import CommissionSidebar from "./CommissionSidebar.js";
import { UPDATE_COMMISSION_RESET } from "../../constants/commissionConstant";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import Button from '@mui/material/Button';
import '../Admin/productList.css'
import InfoAlert from "../Utils/InfoAlert";

const RequestCommissions = () => {
    const dispatch = useDispatch();

    const alert = useAlert();

    let navigate = useNavigate();
    // const [checkboxSelection, setCheckboxSelection] = React.useState(true);
    let { loading, error, commissions } = useSelector((state) => state.myCommissions);
    const { updateError, isUpdated } = useSelector((state) => state.commission);
    const { user } = useSelector((state) => state.user);

    const columns = [

        { field: "orderId", headerName: "O-ID", minWidth: 300, flex: 1 },
        { field: "id", headerName: "E-ID", minWidth: 300, flex: 1 },

        {
            field: "createdAt",
            headerName: "Date",
            minWidth: 150,
            flex: 0.4,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            minWidth: 270,
            flex: 0.5,
        },
        {
            field: "status",
            headerName: "Commission Status",
            minWidth: 150,
            flex: 0.5,
            cellClassName: (params) => {
                return params.getValue(params.id, "status") === "Pending"
                    ? "greenColor"
                    : "redColor";
            },
        },
    ];



    const rows = [];
    commissions &&
        commissions.forEach((item, index) => {
            if (item.processCommission === false && item.CommissionStatus === 'Pending') {
                rows.push({
                    orderId: item.orderId,
                    id: item._id,
                    status: item.CommissionStatus,
                    amount: item.CommissionPerOrder,
                    createdAt: item.CommissionIssueDate
                });
            }
        });
    const [selectionModel, setSelectionModel] = React.useState(() =>
        rows.map((r) => r.id),
    );

    const requestCommissions = () => {
        loading = true
        if (selectionModel.length >= 1) {
            dispatch(updateCommission('', selectionModel, 'requestCommission'));
            loading = false
        } else {
            loading = false
            alert.error('Please Select Atleast One Commission!');
        }
    }

    const [isInfoAlertOpen, setIsInfoAlertOpen] = useState(false);
    let requestConfirmation = false
    const handleInfoAlert = (value) => {
        loading = true
        if (value === true) {
            requestConfirmation = value
            requestCommissions(); // Call the handler after state update
        }
    };

    const openInfoAlert = () => {
        setIsInfoAlertOpen(true);
    };

    const closeInfoAlert = () => {
        setIsInfoAlertOpen(false);
    };

    useEffect(() => {
        if (error) {
            loading = false
            alert.error(error);
            dispatch(clearCommissionErrors());
        }
        if (updateError) {
            loading = false
            alert.error(updateError);
            dispatch(clearCommissionErrors());
            dispatch(clearCommissionErrors());
        }
        if (isUpdated) {
            loading = false
            alert.success("Request Sent Successfully and your commissions will be processed soon");
            dispatch({ type: UPDATE_COMMISSION_RESET });
            navigate("/commission/request");
        }

        dispatch(myCommissions());
    }, [dispatch, alert, error, isUpdated, updateError, navigate]);

    return (
        <Fragment>
            <MetaData title={`${user.name} - Commissions`} />
            {loading ? (
                <Loader />
            ) : (
                <div className="productListContainer" id="list">
                    <div className="awesome-phrase-container7">
                        <header>Request Earnings</header>
                    </div>
                    {/* <div style={{ marginLeft: '20px' }}>
                            <button style={{ margin: '20px' }} onClick={(e) => requestCommissions(e, 'all')} className="requestCommission">REQUEST SELECTED COMMISSIONS</button>
                            </div> */}
                    {/* <div className="card">
                        <div class="card-buttons" >
                            <Button variant="outlined" size="small" style={{ color: '#002244', backgroundColor: '#69f0ae', padding: '10px' }} onClick={(e) => requestCommissions(e, 'all')} className="card-button"><RequestPageIcon />REQUEST</Button>
                        </div>
                    </div> */}
                    {isInfoAlertOpen === false && requestConfirmation === false ? (
                        <div className="button-container">
                            <Button variant="outlined" size="small" style={{ color: '#002244' }} onClick={openInfoAlert} ><RequestPageIcon />Request</Button>
                        </div>
                    ) : (
                        <InfoAlert
                            open={isInfoAlertOpen}
                            onClose={closeInfoAlert}
                            onSubmitValueChange={handleInfoAlert}
                            msg={"💸 Heads up! Once you claim your Earning, you will not be able to return corresponding orders!😅 Are you sure?"}
                        />
                    )}
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        className="myOrdersTable"
                        autoHeight
                        cellClassName
                        sx={{
                            color: 'black',
                            margin: '2%',
                        }}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default RequestCommissions;