import {
    ADD_TO_TRANSFER_ORDER_ITEMWISE_CART,
    REMOVE_TRANSFER_ORDER_ITEMWISE_CART_ITEM,
    SAVE_TRANSFER_ORDER_ITEMWISE_STORE_INFO,
    // MY_CART_REQUEST,
    // MY_CART_SUCCESS,
    // MY_CART_FAIL,
    // CLEAR_ERRORS,
} from "../constants/transferOrderItemWiseCartConstants";
  
  export const transferOrderItemWiseCartReducer = (
      state = { transferOrderItemWiseCartItems: [], transferOrderItemWiseStoreInfo: {} },
    action
  ) => {
    switch (action.type) {
        case ADD_TO_TRANSFER_ORDER_ITEMWISE_CART:
        const item = action.payload;
  
            const isItemExist = state.transferOrderItemWiseCartItems.find(
          (i) => i.product === item.product
        );
  
        if (isItemExist) {
          return {
            ...state,
              transferOrderItemWiseCartItems: state.transferOrderItemWiseCartItems.map((i) =>
              i.product === isItemExist.product ? item : i
            ),
          };
        } else {
          return {
            ...state,
              transferOrderItemWiseCartItems: [...state.transferOrderItemWiseCartItems, item],
          };
        }
  
        case REMOVE_TRANSFER_ORDER_ITEMWISE_CART_ITEM:
        return {
          ...state,
            transferOrderItemWiseCartItems: state.transferOrderItemWiseCartItems.filter((i) => i.product !== action.payload),
        };
  
        case SAVE_TRANSFER_ORDER_ITEMWISE_STORE_INFO:
        return {
          ...state,
            transferOrderItemWiseStoreInfo: action.payload,
        };
  
      default:
        return state;
    }
  };

  // export const myCartReducer = (state = { orders: [] }, action) => {
  //   switch (action.type) {
  //     case MY_CART_REQUEST:
  //       return {
  //         loading: true,
  //       };
  
  //     case MY_CART_SUCCESS:
  //       return {
  //         loading: false,
  //         orders: action.payload,
  //       };
  
  //     case MY_CART_FAIL:
  //       return {
  //         loading: false,
  //         error: action.payload,
  //       };
  //     case CLEAR_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  
  //     default:
  //       return state;
  //   }
  // };