import {
    ADD_TO_REJECT_ITEM_LIST,
    REMOVE_REJECT_ITEM_LIST,
    // SAVE_RETURN_SHIPPING_INFO,
    // MY_CART_REQUEST,
    // MY_CART_SUCCESS,
    // MY_CART_FAIL,
    // CLEAR_ERRORS,
  } from "../constants/rejectItemListConstants";
  
export const rejectItemListReducer = (
      state = { rejectCartItems: []},
    action
  ) => {
    switch (action.type) {
        case ADD_TO_REJECT_ITEM_LIST:
        const item = action.payload;
  
            const isItemExist = state.rejectCartItems.find(
          (i) => i.product === item.product
        );
  
        if (isItemExist) {
          return {
            ...state,
              rejectCartItems: state.rejectCartItems.map((i) =>
              i.product === isItemExist.product ? item : i
            ),
          };
        } else {
          return {
            ...state,
              rejectCartItems: [...state.rejectCartItems, item],
          };
        }
  
      case REMOVE_REJECT_ITEM_LIST:
        return {
          ...state,
            rejectCartItems: state.rejectCartItems.filter((i) => i.product !== action.payload),
        };
  
    //   case SAVE_RETURN_SHIPPING_INFO:
    //     return {
    //       ...state,
    //       returnShippingInfo: action.payload,
    //     };
  
      default:
        return state;
    }
  };

  // export const myCartReducer = (state = { orders: [] }, action) => {
  //   switch (action.type) {
  //     case MY_CART_REQUEST:
  //       return {
  //         loading: true,
  //       };
  
  //     case MY_CART_SUCCESS:
  //       return {
  //         loading: false,
  //         orders: action.payload,
  //       };
  
  //     case MY_CART_FAIL:
  //       return {
  //         loading: false,
  //         error: action.payload,
  //       };
  //     case CLEAR_ERRORS:
  //       return {
  //         ...state,
  //         error: null,
  //       };
  
  //     default:
  //       return state;
  //   }
  // };