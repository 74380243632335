import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
// import DiscountIcon from '@mui/icons-material/Discount';
import quotation_highlight from '../../images/assets/quotation_highlight.png'
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { translator } from "../../translator"

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.8),
        borderRadius: theme.spacing(1),
        // backgroundColor: '#F7F7F7   ',
        // backgroundColor: '#eceef7',
        margin: '3px',
        flex: 1,
    },
    icon: {
        fontSize: 30,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: '#002f5e'
    },
    description: {
        fontSize: 16,
        textAlign: 'center',
        color: '#01a2f5'
    },
}));

const QuotationHighlightCard = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const handleUploadCardClick = () => {
        navigate('/uploadorder');
    };
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");
    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language)
        }
    }
    useEffect(() => {
        setUserLanguage()
    }, [isAuthenticated]);
    return (
        <Box display="flex" p={2}>
            <Box flex={1} display="flex">
                <Card onClick={handleUploadCardClick} variant="outlined" className={classes.card}>
                    {/* <StarIcon className={classes.icon} /> */}
                    {/* <DiscountIcon /> */}
                    <img src={quotation_highlight} alt="quotation highlight" />
                    <h2 className={classes.title}>
                        {translator[language]["Get Instant Quotation"]}
                    </h2>
                    <h3 className={classes.description}>
                        🤝{translator[language]["Get instant, itemized quotes with all the tax trimmings on our website. No funny business - our quotes and bills are a match made in e-commerce heaven!"]}💰
                    </h3>
                </Card>
            </Box>
        </Box>
    );
};

export default QuotationHighlightCard;
