import {
    CREATE_COMMISSION_REQUEST,
    CREATE_COMMISSION_SUCCESS,
    CREATE_COMMISSION_FAIL,
    MY_COMMISSION_REQUEST,
    MY_COMMISSION_SUCCESS,
    MY_COMMISSION_FAIL,
    ALL_COMMISSIONS_REQUEST,
    ALL_COMMISSIONS_SUCCESS,
    ALL_COMMISSIONS_FAIL,
    UPDATE_COMMISSION_REQUEST,
    UPDATE_COMMISSION_SUCCESS,
    UPDATE_COMMISSION_FAIL,
    UPDATE_COMMISSION_RESET,
    DELETE_COMMISSION_REQUEST,
    DELETE_COMMISSION_SUCCESS,
    DELETE_COMMISSION_FAIL,
    DELETE_COMMISSION_RESET,
    COMMISSION_DETAILS_REQUEST,
    COMMISSION_DETAILS_SUCCESS,
    COMMISSION_DETAILS_FAIL,
    CLEAR_COMMISSION_ERRORS,
} from "../constants/commissionConstant";

export const newCommissionReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_COMMISSION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_COMMISSION_SUCCESS:
            return {
                loading: false,
                order: action.payload,
            };

        case CREATE_COMMISSION_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_COMMISSION_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const myCommissionsReducer = (state = { commissions: [] }, action) => {
    switch (action.type) {
        case MY_COMMISSION_REQUEST:
            return {
                loading: true,
            };

        case MY_COMMISSION_SUCCESS:
            return {
                loading: false,
                commissions: action.payload,
            };

        case MY_COMMISSION_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_COMMISSION_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const allCommissionsReducer = (state = { commissions: [] }, action) => {
    switch (action.type) {
        case ALL_COMMISSIONS_REQUEST:
            return {
                loading: true,
            };

        case ALL_COMMISSIONS_SUCCESS:
            return {
                loading: false,
                commissions: action.payload,
            };

        case ALL_COMMISSIONS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_COMMISSION_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const commissionReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_COMMISSION_REQUEST:
        case DELETE_COMMISSION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_COMMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true,
                // isUpdated: action.payload,
            };

        case DELETE_COMMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
            };

        case UPDATE_COMMISSION_FAIL:
        case DELETE_COMMISSION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_COMMISSION_RESET:
            return {
                ...state,
                isUpdated: false,
            };

        case DELETE_COMMISSION_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_COMMISSION_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const commissionDetailsReducer = (state = { commission: {} }, action) => {
    switch (action.type) {
        case COMMISSION_DETAILS_REQUEST:
            return {
                loading: true,
            };

        case COMMISSION_DETAILS_SUCCESS:
            return {
                loading: false,
                commission: action.payload,
            };

        case COMMISSION_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_COMMISSION_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};