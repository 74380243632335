export const ADD_TO_TRANSFER_ORDER_ITEMWISE_CART = "ADD_TO_TRANSFER_ORDER_ITEMWISE_CART";

export const REMOVE_TRANSFER_ORDER_ITEMWISE_CART_ITEM = "REMOVE_TRANSFER_ORDER_ITEMWISE_CART_ITEM";

export const SAVE_TRANSFER_ORDER_ITEMWISE_STORE_INFO = "SAVE_TRANSFER_ORDER_ITEMWISE_STORE_INFO";

// export const MY_CART_REQUEST = "MY_CART_REQUEST";
// export const MY_CART_SUCCESS = "MY_CART_SUCCESS";
// export const MY_CART_FAIL = "MY_CART_FAIL";

// export const CREATE_CART_REQUEST = "CREATE_CART_REQUEST";
// export const CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS";
// export const CREATE_CART_FAIL = "CREATE_CART_FAIL";

export const CLEAR_TRANSFER_ORDER_ITEMWISE_CART_ERRORS = "CLEAR_TRANSFER_ORDER_ITEMWISE_CART_ERRORS";