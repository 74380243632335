import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/metaData";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SideBar from "./StoreDashboardSidebar";
import {
    getOrderDetails,
    clearErrors,
    updateOrder,
} from "../../actions/orderAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button } from "@material-ui/core";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
// import "./storeProcessOrder.css";
import { useParams } from 'react-router-dom'
import { createCommission, clearCommissionErrors } from "../../actions/commissionAction";
import { getAllUsers } from "../../actions/userAction";
import { useLocation } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
    getStoreDetails,
    clearStoreErrors,
} from "../../actions/storeAction";

const StoreProcessOrder = () => {
    const { id } = useParams()
    const location = useLocation()
    const { order, error, loading } = useSelector((state) => state.orderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.order);
    const { users } = useSelector((state) => state.allUsers);
    const { user } = useSelector(state => state.user)
    const { store } = useSelector((state) => state.storeDetails);
    const store_id = location.state?.store_id_temp || user.store_id;
    const purpose = location.state?.purpose || "swiched_store_by_admin";
    let store_id_temp = ""
    let store_props = {}
    if ((user.role === 'admin' || user.role === 'developer') && purpose === "swiched_store_by_admin") {
        store_id_temp = store_id
    } else {
        store_id_temp = user.store_id
    }
    store_props = { store_id: store_id_temp, store_name: "" }
    let userList = []
    if (order) {
        for (let user of users) {
            if (order.store && user.store_id === order.deliveredByStore.store_id) {
                userList.push(user)
            }
        }
    }

    const updateOrderSubmitHandler = (e) => {

        e.preventDefault();


        const myForm = new FormData();

        myForm.set("status", status);
        myForm.set("deliveryBy", deliveryBy);
        myForm.set("paymentStatus", paymentStatus);
        myForm.set("paymentMode", paymentMode);

        if (status === "Delivered") {
            if (paymentStatus === "PAID") {
                dispatch(updateOrder(id, myForm, 'change_order_status'));

            } else {
                alert.error("Payment should be completed to make status Delivered!");
            }
        }
        if (status === "Shipped") {
            dispatch(updateOrder(id, myForm, 'change_order_status'));
        }


        if (status === 'Delivered') {
            let pipe_price = 0
            let pipe_commission = 0
            if (paymentStatus === "PAID") {
                for (let item of order.orderItems) {
                    if (item && item.type && (item.type === "PVC PIPE" || item.type === "CPVC PIPE" || item.type === "APVC PIPE")) {
                        pipe_price = pipe_price + item.price
                        pipe_commission = pipe_commission + item.quantity
                    }
                }
                const itemTotalPrice = (order.totalPrice + pipe_commission) - (order.taxPrice + order.shippingPrice + pipe_price)

                const commission = {
                    commissionAmount: (itemTotalPrice * 0.15).toFixed(2),
                    orderId: order._id,
                    user_id: order.user
                };

                dispatch(createCommission(commission));
            }
        }

    };

    const dispatch = useDispatch();
    const alert = useAlert();

    const [status, setStatus] = useState("");
    const [deliveryBy, setDeliveryBy] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentMode, setPaymentMode] = useState("");

    useEffect(() => {



        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearErrors());
            dispatch(clearCommissionErrors());
            dispatch(clearStoreErrors());

        }
        if (isUpdated) {
            alert.success("Order Updated Successfully");
            dispatch({ type: UPDATE_ORDER_RESET });
        }
        dispatch(getOrderDetails(id));
        dispatch(getAllUsers());
        dispatch(getStoreDetails(store_id, 'for_store_owner'))
    }, [dispatch, alert, error, id, isUpdated, updateError]);

    return (
        <Fragment>
            <MetaData title="Process Order" />
            <div className="dashboard">
                <SideBar myProp={store_props} />
                <div className="newProductContainer">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div
                            className="confirmOrderPage"
                            style={{
                                display: order && order.orderStatus === "Delivered" ? "block" : "grid",
                            }}
                        >
                            <div>
                                <div className="orderDetailsPage">
                                    <div className="order-summary-container">
                                        <header>Order Details</header>
                                        <div className="order-summary">
                                            <div className="row">
                                                <p className="label">Shipping Info</p>
                                                <div className="col">
                                                    <p className="value">{order && order.user && order.user.name}</p>
                                                </div>
                                                <div className="col">
                                                    <p className="value">
                                                        {order && order.shippingInfo && order.shippingInfo.phoneNo}
                                                    </p>
                                                </div>
                                                <div className="col">
                                                    <p className="value">
                                                        {order && order.shippingInfo &&
                                                            `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                                                    </p>
                                                </div>
                                            </div>
                                            {store && store.store_type !== 'distributor' &&
                                                <div className="row">
                                                    <p className="label">Payment Status</p>
                                                    <div className="col">
                                                        <p className="value">{order && order.paymentInfo && order.paymentInfo.status}</p>
                                                    </div>
                                                    <div className="col">
                                                        <p className="label">Payment Mode</p>
                                                        <p className="value">{order && order.paymentInfo && order.paymentInfo.paymentMode}</p>
                                                    </div>
                                                    <div className="col">
                                                        <p className="label">Amount</p>
                                                        <p className="valueMoney"><span>Rs {order && order.totalPrice && order.totalPrice.toFixed(2)}</span></p>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <p className="label">Order Status</p>
                                                <div className="col">
                                                    <p className="value">{order && order.orderStatus && order.orderStatus}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="confirmCartItemsContainer">

                                    {order && order.orderItems &&
                                        order.orderItems.map((item) => {
                                            if (Object.keys(store).length > 0) {
                                                const product = store.products.find((p) => p.product_id === item.product);

                                                return (
                                                    <div key={item.product}>
                                                        <img src={item.image} alt="Product" />
                                                        <Link to={`/product/${item.product}`}>{item.name}</Link>{" "}
                                                        {" "}
                                                        <span style={{ marginRight: '15px' }}><b>{product.product_brand}</b></span>{" "}
                                                        <span>
                                                            {item.quantity} X ₹{item.price} = <b>₹{item.price * item.quantity}</b>
                                                        </span>{" "}
                                                        {order.orderStatus !== "Delivered" && store && store.store_type !== 'distributor' &&
                                                            product.product_stock === 0 ? (

                                                            <span style={{ color: 'tomato' }}>Stock: {product && product.product_stock}</span>
                                                        ) : (
                                                            <span style={{ color: '#32de84' }}>Stock: {product && product.product_stock}</span>
                                                        )

                                                        }
                                                        {product.product_location && store && store.store_type !== 'distributor' &&
                                                            // <span style={{ color: '#01a2f5' }}>Location: test</span>
                                                            <span style={{ color: '#01a2f5' }}>Location: {product && product.product_location}</span>
                                                        }
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                            </div>
                            {/*  */}
                            {store && store.store_type !== 'distributor' &&
                                <div
                                    style={{
                                        display: order && order.orderStatus === "Delivered" ? "none" : "block",
                                    }}
                                >
                                    <form
                                        className="updateOrderForm"
                                        onSubmit={updateOrderSubmitHandler}
                                    >
                                        <h1>Process Order</h1>

                                        <div>
                                            <AccountTreeIcon />
                                            <select onChange={(e) => setStatus(e.target.value)}>
                                                <option value="">Choose Category</option>
                                                {order && order.orderStatus === "Processing" && (
                                                    <option value="Shipped">Shipped</option>
                                                )}

                                                {order && order.orderStatus === "Shipped" && (
                                                    <option value="Delivered">Delivered</option>
                                                )}
                                            </select>
                                        </div>
                                        {order && order.orderStatus === "Shipped" &&
                                            <h1>Delivered By</h1>
                                        }
                                        {order && order.orderStatus === "Shipped" &&
                                            <div>
                                                <LocalShippingIcon />
                                                <select onChange={(e) => setDeliveryBy(e.target.value)}>
                                                    <option value="">Choose Delivery Partner</option>
                                                    {order && order.orderStatus === "Shipped" &&
                                                        userList.map((item) => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        }
                                        {order && order.orderStatus === "Shipped" &&
                                            <h1>Payment Status</h1>
                                        }
                                        {order && order.orderStatus === "Shipped" &&
                                            <div>
                                                <CreditScoreIcon />
                                                <select onChange={(e) => setPaymentStatus(e.target.value)}>
                                                    <option value="">Payment Status</option>
                                                    {order && order.orderStatus === "Shipped" &&
                                                        <option value="PAID">PAID</option>
                                                    }
                                                    {order && order.orderStatus === "Shipped" &&
                                                        <option value="NOT PAID">NOT PAID</option>
                                                    }
                                                </select>
                                            </div>
                                        }
                                        {order && order.orderStatus === "Shipped" &&
                                            <h1>Payment Mode</h1>
                                        }
                                        {order && order.orderStatus === "Shipped" &&
                                            <div>
                                                <CurrencyRupeeIcon />
                                                <select onChange={(e) => setPaymentMode(e.target.value)}>
                                                    <option value="">Payment Mode</option>
                                                    {order && order.orderStatus === "Shipped" &&
                                                        <option value="cod">Cash On Delivery</option>
                                                    }
                                                    {order && order.orderStatus === "Shipped" &&
                                                        <option value="spod">Scan And Pay On Delivery</option>
                                                    }
                                                </select>
                                            </div>
                                        }
                                        <div className="card-buttons">
                                            <Button
                                                id="createProductBtn"
                                                variant="outlined" size="small" style={{ color: '#002244', backgroundColor: '#69f0ae', padding: '10px' }}
                                                type="submit"
                                                disabled={
                                                    loading ? true : false || status === "" ? true : false
                                                }
                                            >
                                                Process
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default StoreProcessOrder;