import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Admin/sidebar.css";
import logo from "../../images/logo2.png";
import { Link, useLocation } from "react-router-dom";
// import TreeView from '@mui/lab/TreeView';
// import TreeItem from '@mui/lab/TreeItem';
// import { TreeView, TreeItem } from "@material-ui/lab";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PostAddIcon from '@mui/icons-material/PostAdd';
// import AddIcon from '@mui/icons-material/Add';
// import ImportExportIcon from '@mui/icons-material/ImportExport';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { getStoreDetails, clearStoreErrors, } from "../../actions/storeAction";
import { useAlert } from "react-alert";
// import RateReviewIcon from '@mui/icons-material/RateReview';

const StoreSidebar = (props) => {
    const location = useLocation();

    // Function to check if the current link is active
    const isActive = (pathname) => {
        return location.pathname === pathname;
    };

    const dispatch = useDispatch();
    const { myProp } = props
    const store_id = myProp.store_id
    const store_name = myProp.store_name
    const id = myProp
    const alert = useAlert();
    const { error, store } = useSelector((state) => state.storeDetails);
    useEffect(() => {
        dispatch(getStoreDetails(store_id));
        if (error) {
            alert.error(error);
            dispatch(clearStoreErrors());
        }

    }, [dispatch, alert, error, store_id])
    return (
        <div className="sidebar">
            <Link style={{ border: 'none' }} to="/">
                <h1 style={{ color: '#01a2f5' }}>plumbpigen</h1>
            </Link>
            {/* <h2 className="sidebarstorename">{store_name}</h2> */}
            <Link to={{ pathname: "/store/dashboard" }} state={{ id }} className={isActive('/store/dashboard') ? 'active' : ''}>
                <p>
                    <StoreMallDirectoryIcon /> Dashboard
                </p>
            </Link>
            {store && store.store_type !== 'distributor' &&
                <Link to={{ pathname: "/store/products" }} state={{ store_id }} className={isActive('/store/products') ? 'active' : ''}>
                    <p>
                        <PostAddIcon /> Products
                    </p>
                </Link>
            }
            {/* <Link state={{ store_id }}>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ImportExportIcon />}
                >
                    <TreeItem nodeId="1" label="Products">
                        <Link to={{ pathname: "/store/products" }} state={{ store_id }}>
                            <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
                        </Link>
                    </TreeItem>
                </TreeView>
            </Link> */}
            <Link to={{ pathname: "/store/orders" }} state={{ store_id }} className={isActive('/store/orders') ? 'active' : ''}>
                <p>
                    <ListAltIcon />
                    Orders
                </p>
            </Link>
            {store && store.store_type !== 'distributor' &&
                <Link to={{ pathname: "/store/return" }} state={{ store_id }} className={isActive('/store/return') ? 'active' : ''}>
                    <p>
                        <KeyboardReturnIcon />
                        Return Orders
                    </p>
                </Link>
            }
            {/* <Link to="/admin/reviews">
        <p>
          <RateReviewIcon />
          Reviews
        </p>
      </Link> */}
        </div>
    );
};

export default StoreSidebar;