import {
    ALL_STORE_PRODUCT_FAIL,
    ALL_STORE_PRODUCT_REQUEST,
    ALL_STORE_PRODUCT_SUCCESS,
    ALL_STORE_FAIL,
    ALL_STORE_REQUEST,
    ALL_STORE_SUCCESS,
    STORE_DETAILS_REQUEST,
    STORE_DETAILS_FAIL,
    STORE_DETAILS_SUCCESS,
    UPDATE_STORE_PRODUCT_REQUEST,
    UPDATE_STORE_PRODUCT_SUCCESS,
    UPDATE_STORE_PRODUCT_FAIL,
    UPDATE_STORE_PRODUCT_RESET,
    STORE_PRODUCT_DETAILS_REQUEST,
    STORE_PRODUCT_DETAILS_FAIL,
    STORE_PRODUCT_DETAILS_SUCCESS,
    CREATE_STORE_REQUEST,
    CREATE_STORE_SUCCESS,
    CREATE_STORE_FAIL,
    UPDATE_STORE_REQUEST,
    UPDATE_STORE_SUCCESS,
    UPDATE_STORE_FAIL,
    DELETE_STORE_REQUEST,
    DELETE_STORE_SUCCESS,
    DELETE_STORE_FAIL,
    UPDATE_STORE_RESET,
    DELETE_STORE_RESET,
    CLEAR_STORE_ERRORS,
} from "../constants/storeConstants";

export const newStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_STORE_SUCCESS:
            return {
                loading: false,
                store: action.payload,
            };

        case CREATE_STORE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_STORE_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const storeProductDetailsReducer = (state = { storeProduct: {} }, action) => {
    switch (action.type) {
        case STORE_PRODUCT_DETAILS_REQUEST:

            return {
                loading: true,
                ...state,
            }
        case STORE_PRODUCT_DETAILS_SUCCESS:

            return {
                loading: false,
                storeProduct: action.payload,
            }
        case STORE_PRODUCT_DETAILS_FAIL:

            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_STORE_ERRORS:

            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const storeProductReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STORE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_STORE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };
        case UPDATE_STORE_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_STORE_PRODUCT_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case CLEAR_STORE_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const storeProductsReducer = (state = { storeProducts: [] }, action) => {
    switch (action.type) {
        case ALL_STORE_PRODUCT_REQUEST:
            return {
                loading: true,
                storeProducts: []
            }
        case ALL_STORE_PRODUCT_SUCCESS:
            return {
                loading: false,
                storeProducts: action.payload,
            };
        case ALL_STORE_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_STORE_ERRORS:

            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const allstoresReducer = (state = { stores: [] }, action) => {
    switch (action.type) {
        case ALL_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ALL_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                stores: action.payload,
            };

        case ALL_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_STORE_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const storeDetailsReducer = (state = { store: {} }, action) => {
    switch (action.type) {
        case STORE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case STORE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                store: action.payload,
            };

        case STORE_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_STORE_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const storeReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STORE_REQUEST:
        case DELETE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };

        case DELETE_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
                message: action.payload.message,
            };

        case UPDATE_STORE_FAIL:
        case DELETE_STORE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_STORE_RESET:
            return {
                ...state,
                isUpdated: false,
            };

        case DELETE_STORE_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_STORE_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};