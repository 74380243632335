import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/metaData";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import "../Admin/newProduct.css";
import Loader from "../layout/loader/Loader";
import PhoneIcon from '@mui/icons-material/Phone';
import { translator } from "../../translator"
const apiHelper = require("../../helpers")

const UploadOrderImage = () => {
    let navigate = useNavigate();
    const alert = useAlert();
    const [orderImages, setOrderImages] = useState([]);
    const [phoneNo, setPhoneNo] = useState("");
    const [orderImagesPreview, setOrderImagesPreview] = useState([]);
    const [showUploadButton, setShowUploadButton] = useState(true);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const { token } = useSelector((state) => state.token);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };
    let loading = false
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    const uploadOrderImagesChange = (e) => {
        const files = Array.from(e.target.files);

        // Create a copy of the existing orderImages and orderImagesPreview
        const newOrderImages = [...orderImages];
        const newOrderImagesPreview = [...orderImagesPreview];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    // Append the new image to the copies
                    newOrderImagesPreview.push(reader.result);
                    newOrderImages.push(reader.result);

                    // Set the state with the new copies
                    setOrderImagesPreview(newOrderImagesPreview);
                    setOrderImages(newOrderImages);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    // const uploadOrderImagesChange = (e) => {
    //     const files = Array.from(e.target.files);
    //     const newOrderImages = [...orderImages]
    //     const newOrderImagesPreview = [...orderImagesPreview]
    //     // setOrderImages([]);
    //     // setOrderImagesPreview([]);

    //     files.forEach((file) => {
    //         // if (file.size <= 20 * 1024) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             if (reader.readyState === 2) {
    //                 newOrderImagesPreview.push(reader.result)
    //                 newOrderImages.push(reader.result)
    //                 setOrderImagesPreview(newOrderImagesPreview);
    //                 setOrderImages(newOrderImages);
    //                 // setOrderImagesPreview((old) => [...old, reader.result]);
    //                 // setOrderImages((old) => [...old, reader.result]);
    //             }
    //         };
    //         reader.readAsDataURL(file);
    //         // } else {
    //         //     alert.error("file should be less than 20KB");
    //         //     return
    //         // }
    //     });
    // };
    const fetchData = async (payload) => {
        loading = true
        setShowUploadButton(false)
        axios.post(apiHelper.api + 'order/upload', payload, config)
            .then(response => {
                if (response.data.success) {
                    alert.success("Image Uploaded Successfully");
                    loading = false
                    setShowUploadButton(true)
                    navigate("/")
                } else {
                    alert.error(response.data.message);
                }
            }).catch(error => {
                loading = false
                setShowUploadButton(true)
                alert.error(error);
            });
    };

    const uploadOrderImageSubmitHandler = (e) => {
        loading = true
        setShowUploadButton(false)
        e.preventDefault();
        const myForm = new FormData();
        orderImages.forEach((image) => {
            myForm.append("orderImages", image);
        });
        myForm.set("user_id", user._id);
        myForm.set("phoneNo", phoneNo);
        fetchData(myForm);
    }
    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    return (
        <Fragment>
            {
                !showUploadButton ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <MetaData title="Upload Image" />
                        <div className="shippingContainer">
                            <div className="shippingBox">
                                <h2 className="shippingHeading">{translator[language]["Upload Your Order's Image"]}</h2>
                                <form
                                    className="shippingForm"
                                    encType="multipart/form-data"
                                    onSubmit={uploadOrderImageSubmitHandler}
                                >
                                    <div>
                                        <PhoneIcon />
                                        <input
                                            type="number"
                                            placeholder={translator[language]["Phone Number"]}
                                            required
                                            value={phoneNo}
                                            onChange={(e) => setPhoneNo(e.target.value)}
                                            size="10"
                                        />
                                    </div>
                                    <div id="createProductFormFile">
                                        <input
                                            type="file"
                                            placeholder={translator[language]["Choose Photo"]}
                                            name="avatar"
                                            accept="image/*"
                                            required
                                            onChange={uploadOrderImagesChange}
                                            multiple
                                        />
                                    </div>

                                    <div id="createProductFormImage">
                                        {orderImagesPreview.map((image, index) => (
                                            <img key={index} src={image} alt="Order Immage Preview" />
                                        ))}
                                    </div>
                                    {showUploadButton &&
                                        <Button
                                            id="createProductBtn"
                                            type="submit"
                                        // disabled={loading ? true : false}
                                        >
                                            {translator[language]["Upload"]}
                                        </Button>
                                    }
                                    {!showUploadButton &&
                                        <p>{translator[language]["Hold on, we are uploading you image."]}</p>
                                    }
                                </form>
                            </div>
                        </div>
                    </Fragment>
                )
            }
        </Fragment >
    )
}

export default UploadOrderImage;