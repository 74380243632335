import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
// import DiscountIcon from '@mui/icons-material/Discount';
import offerIcon from '../../images/assets/offerIcon2Outlined.png'
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        backgroundColor: '#f1f8e9',
        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        margin: '5px',
        flex: 1,
    },
    icon: {
        fontSize: 60,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: '#002f5e'
    },
    description: {
        fontSize: 16,
        textAlign: 'center',
        // color: '#1de9b6'
    },
}));

const OfferCard = () => {
    const classes = useStyles();

    return (
        <Box display="flex" p={2}>
            <Box flex={1} display="flex">
                <Card className={classes.card} variant="outlined">
                    {/* <StarIcon className={classes.icon} /> */}
                    {/* <DiscountIcon /> */}
                    <img src={offerIcon} alt="Offer Icon" />
                    <h2 className={classes.title}>
                        Exclusive Offer!
                    </h2>
                    <h3 className={classes.description}>
                        Hurry up! For the first 5 orders, get up to 40% discount on all items.
                        Valid until September 15th, 2023.
                    </h3>
                </Card>
            </Box>
        </Box>
    );
};

export default OfferCard;
