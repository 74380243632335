import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CLEAR_CATEGORY_ERRORS,
} from "../constants/categoryConstant";
import axios from "axios";
const apiHelper = require("../helpers")

// Add to Cart
export const createCategory = (categoryData) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: CREATE_CATEGORY_REQUEST });

        const { data } = await axios.post(
            `${apiHelper.api}admin/category/new`,
            categoryData,
            config
        );
        dispatch({
            type: CREATE_CATEGORY_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_CATEGORY_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const updateCategory = (id, categoryData) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: UPDATE_CATEGORY_REQUEST });

        const { data } = await axios.post(
            `${apiHelper.api}admin/category/${id}`,
            categoryData,
            config
        );
        dispatch({
            type: UPDATE_CATEGORY_SUCCESS, payload: data.success
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getCategoryDetails = (id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: CATEGORY_DETAILS_REQUEST });

        const { data } = await axios.post(`${apiHelper.api}admin/category/details/${id}`, config);
        dispatch({
            type: CATEGORY_DETAILS_SUCCESS,
            payload: data.category_details,
        });
    } catch (error) {
        dispatch({
            type: CATEGORY_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// get all categories
export const getAllCategories = () => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: ALL_CATEGORY_REQUEST });

        const { data } = await axios.get(apiHelper.api + "categories/all", config);

        dispatch({ type: ALL_CATEGORY_SUCCESS, payload: data.categories });
    } catch (error) {
        dispatch({
            type: ALL_CATEGORY_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clearing Errors
export const clearCategoryErrors = () => async (dispatch, getState) => {
    dispatch({ type: CLEAR_CATEGORY_ERRORS });
};