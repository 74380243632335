export const CREATE_RETURN_ORDER_REQUEST = "CREATE_RETURN_ORDER_REQUEST";
export const CREATE_RETURN_ORDER_SUCCESS = "CREATE_RETURN_ORDER_SUCCESS";
export const CREATE_RETURN_ORDER_FAIL = "CREATE_RETURN_ORDER_FAIL";

export const MY_RETURN_ORDERS_REQUEST = "MY_RETURN_ORDERS_REQUEST";
export const MY_RETURN_ORDERS_SUCCESS = "MY_RETURN_ORDERS_SUCCESS";
export const MY_RETURN_ORDERS_FAIL = "MY_RETURN_ORDERS_FAIL";

export const ALL_RETURN_ORDERS_REQUEST = "ALL_RETURN_ORDERS_REQUEST";
export const ALL_RETURN_ORDERS_SUCCESS = "ALL_RETURN_ORDERS_SUCCESS";
export const ALL_RETURN_ORDERS_FAIL = "ALL_RETURN_ORDERS_FAIL";

export const UPDATE_RETURN_ORDER_REQUEST = "UPDATE_RETURN_ORDER_REQUEST";
export const UPDATE_RETURN_ORDER_SUCCESS = "UPDATE_RETURN_ORDER_SUCCESS";
export const UPDATE_RETURN_ORDER_RESET = "UPDATE_RETURN_ORDER_RESET";
export const UPDATE_RETURN_ORDER_FAIL = "UPDATE_RETURN_ORDER_FAIL";

export const DELETE_RETURN_ORDER_REQUEST = "DELETE_RETURN_ORDER_REQUEST";
export const DELETE_RETURN_ORDER_SUCCESS = "DELETE_RETURN_ORDER_SUCCESS";
export const DELETE_RETURN_ORDER_RESET = "DELETE_RETURN_ORDER_RESET";
export const DELETE_RETURN_ORDER_FAIL = "DELETE_RETURN_ORDER_FAIL";

export const RETURN_ORDER_DETAILS_REQUEST = "RETURN_ORDER_DETAILS_REQUEST";
export const RETURN_ORDER_DETAILS_SUCCESS = "RETURN_ORDER_DETAILS_SUCCESS";
export const RETURN_ORDER_DETAILS_FAIL = "RETURN_ORDER_DETAILS_FAIL";

export const CLEAR_RETURN_ERRORS = "CLEAR_RETURN_ERRORS";