
import React, { Fragment, useEffect, useState } from "react";
// import Carousel from "react-material-ui-carousel";
import "./ProductDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { getProductDetails, clearErrors, } from "../../actions/productAction";
// import {
//   clearErrors,
//   getProductDetails,
//   newReview,
// } from "../../actions/productAction";
// import ReviewCard from "./ReviewCard.js";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import MetaData from "../layout/metaData";
import { addItemsToCart } from "../../actions/cartActions";
import { getAllCategories, clearCategoryErrors } from "../../actions/categoryActions";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
// } from "@material-ui/core";
// import { Rating } from "@material-ui/lab";
// import { NEW_REVIEW_RESET } from "../../constants/productConstants";
import { useParams } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom';
// import Search from "../Product/Search.js"

import BottomNavBar from "../layout/BottomNavBar.js"
import { Card, Divider } from '@mui/material';
import StraightenIcon from '@mui/icons-material/Straighten';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CategoryIcon from '@mui/icons-material/Category';
import add_product from '../../images/assets/add_product.png'
import { translator } from "../../translator"

const ProductDetails = () => {
  // const escapeRegex = (string) => {
  //   const uppercaseString = string.toUpperCase();
  //   const escapedString = uppercaseString.replace(/[.%*+?^${}()|[\]\\]/g, '\\$&');
  //   return escapedString;
  // }
  // let navigate = useNavigate();
  const { id } = useParams()
  const dispatch = useDispatch();
  const alert = useAlert();
  // let [keyword, setKeyword] = useState('');
  // const { categories } = useSelector((state) => state.allCategories);
  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };

  let user_props = {}
  if (user) {
    user_props = { user_details: user }
  }
  //   const { success, error: reviewError } = useSelector(
  //     (state) => state.newReview
  //   );

  //   const options = {
  //     size: "large",
  //     value: product.ratings,
  //     readOnly: true,
  //     precision: 0.5,
  //   };
  // const categories_general = [
  //   "UPVC",
  //   "CPVC",
  //   "PVC",
  //   "GI",
  //   "CP",
  // ];
  // let brands = [];
  // let sizes = []
  // let types = []
  // // console.log(product)
  // const [category, setCategory] = useState(product.category);
  // const [brand, setBrand] = useState(product.brand);
  // const [type, setType] = useState("");
  // const [size, setSize] = useState(product.size);

  const [quantity, setQuantity] = useState(1);

  // if (category) {
  //   if (categories) {
  //     for (let i of categories) {
  //       if (i.name === category) {
  //         brands = i.brands
  //         types = i.types
  //         sizes = i.sizes
  //       }
  //     }
  //   }
  // }
  //   const [open, setOpen] = useState(false);
  //   const [rating, setRating] = useState(0);
  //   const [comment, setComment] = useState("");

  const increaseQuantity = () => {
    if (product.Stock <= quantity) return;

    const qty = quantity + 1;
    setQuantity(qty);
  };

  const decreaseQuantity = () => {
    if (1 >= quantity) return;

    const qty = quantity - 1;
    setQuantity(qty);
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(id, quantity));
    alert.success("Item Added To Bag");
  };

  //   const submitReviewToggle = () => {
  //     open ? setOpen(false) : setOpen(true);
  //   };

  //   const reviewSubmitHandler = () => {
  //     const myForm = new FormData();

  //     myForm.set("rating", rating);
  //     myForm.set("comment", comment);
  //     myForm.set("productId", id);

  //     dispatch(newReview(myForm));

  //     setOpen(false);
  //   };
  // const searchSubmitHandler = e => {
  //   e.preventDefault();
  //   setKeyword(e.target.value)
  //   dispatch(getProduct(escapeRegex(e.target.value)))
  // }
  // const changeProductAttributes = () => {

  //   if (category !== product.category || brand !== product.brand || size !== product.size) {

  //     navigate('/products', { state: { sendingCategoriesFilter: { 'category': category, 'brand': brand, 'type': type, 'size': size } } });
  //   }
  // }
  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
      dispatch(clearCategoryErrors());
    }

    // if (reviewError) {
    //   alert.error(reviewError);
    //   dispatch(clearErrors());
    // }

    // if (success) {
    //   alert.success("Review Submitted Successfully");
    //   dispatch({ type: NEW_REVIEW_RESET });
    // }
    // changeProductAttributes()
    dispatch(getAllCategories());
    dispatch(getProductDetails(id));
  }, [dispatch, id, error, alert]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`${product.name}`} />
          <div className="ProductDetails">
            <div>
              <div className="Carousel">
                {product.images &&
                  product.images.map((item) => (
                    <img
                      className="CarouselImage"
                      key={item.url}
                      src={item.url}
                      alt={`product.name`}
                    />
                  ))}
              </div >
            </div>
            {/* <div className="awesome-phrase-container4">
              <p className="awesome-phrase">
                "This page may cause extreme excitement and an irresistible urge to click 'Add to Bag.'"!
              </p>
            </div> */}
            <Divider />
            <div className="detailsBlock">
              <div className="awesome-phrase-container8">
                <p className="awesome-phrase2">
                  💡 " {translator[language]["Light up your plumbing solutions! Click 'Add to Bag' and brighten your DIY game."]} 💡🔧"
                </p>
              </div>
              {/* <img
                style={{
                  display: 'block', // Ensures the image is treated as a block element // Sets horizontal margin to "auto" to center it
                }}
                src={add_product}
                alt="Add Product"
              /> */}
              {/* <div className="detailsBlock-1"> */}
              <Card sx={{ px: '12px', py: '10px', backgroundColor: '#F0F8FF' }} variant="outlined" className="detailsBlock-1">
                <h2>{product.name}</h2>
                <p> {translator[language]["Product"]} # {product._id}</p>
              </Card>

              {/* </div> */}
              <Card sx={{ px: '12px', my: '6px', backgroundColor: '#F0F8FF' }} variant="outlined" className="detailsBlock-3">
                <h1>{`₹${product.price}`}</h1>
                <h2 style={{ display: 'flex', alignItems: 'center' }}> <StraightenIcon sx={{ mr: 5, alignItems: 'center' }} /><span>    </span>{`${product.size}`}</h2>
                <h2 style={{ display: 'flex', alignItems: 'center' }}><BrandingWatermarkIcon sx={{ mr: 5, alignItems: 'center' }} /><span>   </span>{`${product.brand}`}</h2>
                <h2 style={{ display: 'flex', alignItems: 'center' }}><CategoryIcon sx={{ mr: 5, alignItems: 'center' }} /><span>    </span> {product.category}</h2>
              </Card>
              <Card sx={{ px: '12px', backgroundColor: '#F0F8FF' }} variant="outlined" className="detailsBlock-3">
                {/* <div className="detailsBlock-3"> */}

                <div className="detailsBlock-3-1">
                  <div className="detailsBlock-3-1-1">
                    <button onClick={(decreaseQuantity)} >-</button>
                    <input type="number" value={quantity} />
                    <button onClick={(increaseQuantity)} >+</button>
                  </div>
                  <button
                    disabled={product.Stock < 1 ? true : false}
                    onClick={addToCartHandler}
                  >
                    {translator[language]["Add to Bag"]}
                  </button>
                </div>
                {/* </div> */}
                <div className={product.Stock < 1 ? "redColorStock" : "greenColorStock"}>
                  {product.Stock < 1 ? translator[language]["Out Of Stock"] : translator[language]["In Stock"]}
                </div>
              </Card>
              {/* <button onClick={submitReviewToggle} className="submitReview">
                Submit Review
              </button> */}
            </div>
          </div>


          {/* <h3 className="reviewsHeading">REVIEWS</h3>

          <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={submitReviewToggle}
          >
            <DialogTitle>Submit Review</DialogTitle>
            <DialogContent className="submitDialog">
              <Rating
                onChange={(e) => setRating(e.target.value)}
                value={rating}
                size="large"
              />

              <textarea
                className="submitDialogTextArea"
                cols="30"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </DialogContent>
            <DialogActions>
              <Button onClick={submitReviewToggle} color="secondary">
                Cancel
              </Button>
              <Button onClick={reviewSubmitHandler} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {product.reviews && product.reviews[0] ? (
            <div className="reviews">
              {product.reviews &&
                product.reviews.map((review) => (
                  <ReviewCard key={review._id} review={review} />
                ))}
            </div>
          ) : (
            <p className="noReviews">No Reviews Yet</p>
          )} */}
          <BottomNavBar myProp={user_props} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductDetails;