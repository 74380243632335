import React from "react";
// import playStore from "../../../images/playstore.png";
// import appStore from "../../../images/Appstore.png";
import "./Footer.css";
// import { Link } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import india from '../../../images/assets/india.png'

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="leftFooter">
        <h4>DOWNLOAD OUR APP</h4>
        <p>Download App for Android and IOS mobile phone</p>
        <img src={playStore} alt="playstore" />
        <img src={appStore} alt="Appstore" />
      </div> */}


      <div className="midFooter">
        <h1>plumbpigen</h1>
        <p>Plumbing products, delivered to your door.</p>
        <p>Copyrights 2023 &copy; plumbpigen</p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h5 style={{ display: 'flex', alignItems: 'center' }}>
            Crafted With <FavoriteIcon style={{ fontSize: '20px', color: '#01a2f5', marginLeft: '5px', marginRight: '5px' }} /> In Belgaum,
            <img
              style={{ height: '22px', marginLeft: '5px' }}
              src={india}
              alt="India"
            />
          </h5>
        </div>
        <br></br>
        <img
          src={'../../../logo2.png'}
          alt="Logo"
          style={{ width: '40px', height: 'auto' }}
        />
      </div>
      {/* 
      <div className="leftFooter">
        <h1>Useful Links</h1>
        <div className="list">
          <Link className="link" to="/">
            <div className="link1" />Home
          </Link>
          <Link className="link" to="/cart">
            <div className="link1" />Cart
          </Link>
          <Link className="link" to="/products">
            <div className="link1" />Products
          </Link>
          <Link className="link" to="/account">
            <div className="link1" />Account
          </Link>
          <Link className="link" to="/orders">
            <div className="link1" />Order
          </Link>
          <Link className="link" to="/contact">
            <div className="link1" />Contact
          </Link>
          <Link className="link" to="/about">
            <div className="link1" />About
          </Link>
          <Link className="link" to="/">
            <li className="link1" />Terms
            </Link>
        </div>
      </div> */}
      {/* <div className="rightFooter">
        <h1>Contact Us</h1>
        <div className="list">
          <Link className="link" to="/contact">
            <div className="link1" />Contact Us
          </Link>
          <Link className="link" to="/about">
            <div className="link1" />About Us
          </Link>
        </div>
      </div> */}

      {/* <div className="rightFooter">
        <h4>Follow Us</h4>
        <a href="http://instagram.com/meabhisingh">Instagram</a>
        <a href="http://youtube.com/6packprogramemr">Youtube</a>
        <a href="http://instagram.com/meabhisingh">Facebook</a>
      </div> */}
    </footer>
  );
};

export default Footer;