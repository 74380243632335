import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import "./processCommission.css";
import { useSelector, useDispatch } from "react-redux";
import { clearCommissionErrors, updateCommission, getAllCommissions } from "../../actions/commissionAction";
import Loader from "../layout/loader/Loader";
// import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/metaData";
// import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar.js";
import { UPDATE_COMMISSION_RESET } from "../../constants/commissionConstant";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Stack from '@mui/material/Stack';
import { Button } from "@material-ui/core";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const ProcessCommissions = () => {
    const dispatch = useDispatch();

    const alert = useAlert();

    let navigate = useNavigate();
    // const [checkboxSelection, setCheckboxSelection] = React.useState(true);
    const { loading, error, commissions } = useSelector((state) => state.allCommissions);
    const { updateError, isUpdated } = useSelector((state) => state.commission);
    const { user } = useSelector((state) => state.user);

    const columns = [

        { field: "orderId", headerName: "O-ID", minWidth: 300, flex: 1 },
        { field: "userId", headerName: "U-ID", minWidth: 300, flex: 1 },
        { field: "id", headerName: "C-ID", minWidth: 300, flex: 1 },

        {
            field: "createdAt",
            headerName: "Date",
            minWidth: 150,
            flex: 0.4,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            minWidth: 270,
            flex: 0.5,
        },
        {
            field: "status",
            headerName: "Commission Status",
            minWidth: 150,
            flex: 0.5,
            cellClassName: (params) => {
                return params.getValue(params.id, "status") === "Pending"
                    ? "greenColor"
                    : "redColor";
            },
        },
    ];



    const rows = [];

    commissions &&
        commissions.forEach((item, index) => {
            if (item.processCommission === true && item.CommissionStatus === 'Pending') {
                rows.push({
                    orderId: item.orderId,
                    userId: item.userId,
                    id: item._id,
                    status: item.CommissionStatus,
                    amount: item.CommissionPerOrder,
                    createdAt: item.CommissionIssueDate
                });
            }
        });
    const [selectionModel, setSelectionModel] = React.useState(() =>
        rows.map((r) => r.id),
    );
    const requestCommissions = () => {
        if (selectionModel.length >= 1) {

            dispatch(updateCommission('', selectionModel, 'processCommission'));
        } else {
            alert.error('Please Select Atleast One Commission!');
        }
    }

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearCommissionErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearCommissionErrors());
            dispatch(clearCommissionErrors());
        }
        if (isUpdated) {
            alert.success("All Selected Commissions have been Processed ");
            dispatch({ type: UPDATE_COMMISSION_RESET });
            navigate("/admin/commissions/process");
        }

        dispatch(getAllCommissions());
    }, [dispatch, alert, error, isUpdated, updateError, navigate]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <Fragment>
            <MetaData title={`${user.name} - Commissions`} />
            {loading ? (
                <Loader />
            ) : (
                <div className="dashboard">
                    <div className="sidebartoggle">
                        <div className={isSidebarOpen ? '' : 'opensidebar'} >
                            <Sidebar />
                        </div>
                        <MenuOutlinedIcon onClick={toggleSidebar} />
                    </div>
                    <div className="productListContainer">
                        <div className="awesome-phrase-container3">

                            <h1>Process Commissions</h1>
                        </div>
                        <div className="card">
                            <div class="card-buttons" >
                                <Stack >
                                    <Button variant="outlined" style={{ color: '#002244', backgroundColor: '#69f0ae', padding: '10px' }} onClick={(e) => requestCommissions(e, 'all')} className="card-button"><PriceCheckIcon />Process</Button>
                                </Stack>
                            </div>
                        </div>
                        <DataGrid
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            className="myOrdersTable"
                            autoHeight
                            cellClassName
                            sx={{
                                margin: '2%',
                                bgcolor: '#002244',
                                boxShadow: 2,
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                            }}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ProcessCommissions;