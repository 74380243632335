import axios from "axios";
import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  ALL_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  CLEAR_ERRORS,
} from "../constants/productConstant"
const apiHelper = require("../helpers")

export const getProduct
  = (keyword = "", currentPage = 1, category, type, brand, size) =>
    async (dispatch, getState) => {
      const { token } = getState().token

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        withCredentials: true,
      };
      try {
        dispatch({
          type: ALL_PRODUCT_REQUEST
        });
        let link = `${apiHelper.api}products?keyword=${keyword}&page=${currentPage}`;
        if (category) {
          link = `${apiHelper.api}products?keyword=${keyword}&page=${currentPage}&category=${category}`;
        }
        //   if(brand){
        //     link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&brand=${brand}`;
        // }
        if (type) {
          link = `${apiHelper.api}products?keyword=${keyword}&page=${currentPage}&category=${category}&type=${type}`;
        }
        if (brand) {
          link = `${apiHelper.api}products?keyword=${keyword}&page=${currentPage}&category=${category}&type=${type}&brand=${brand}`;
        }
        if (size) {
          link = `${apiHelper.api}products?keyword=${keyword}&page=${currentPage}&category=${category}&type=${type}&brand=${brand}&size=${size}`;
        }
        const { data } = await axios.get(link, config);
        dispatch({
          type: ALL_PRODUCT_SUCCESS,
          payload: data,
        })
      } catch (error) {
        dispatch({
          type: ALL_PRODUCT_FAIL,
          payload: error.response.data.message,
        })
      }
    }


// Get All Products For Admin
export const getAdminProduct = () => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: ADMIN_PRODUCT_REQUEST });

    const { data } = await axios.get(apiHelper.api + "admin/products", config);

    dispatch({
      type: ADMIN_PRODUCT_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Product
export const createProduct = (productData) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });
    const { data } = await axios.post(
      `${apiHelper.api}admin/product/new`,
      productData,
      config
    );

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product
export const updateProduct = (id, productData) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const { data } = await axios.put(
      `${apiHelper.api}admin/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    const { data } = await axios.delete(`${apiHelper.api}admin/product/${id}`, config);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Products Details
export const getProductDetails = (id) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`${apiHelper.api}product/${id}`, config);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// NEW REVIEW
export const newReview = (reviewData) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });

    const { data } = await axios.put(`${apiHelper.api}review`, reviewData, config);

    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Reviews of a Product
export const getAllReviews = (id) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: ALL_REVIEW_REQUEST });

    const { data } = await axios.get(`${apiHelper.api}reviews?id=${id}`, config);

    dispatch({
      type: ALL_REVIEW_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: ALL_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Review of a Product
export const deleteReviews = (reviewId, productId) => async (dispatch, getState) => {
  const { token } = getState().token

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });

    const { data } = await axios.delete(
      `${apiHelper.api}reviews?id=${reviewId}&productId=${productId}`, config
    );

    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// clearing errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS })
}
