import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    TextField,
} from '@mui/material';

function DateTimePicker({ onDateTimeChange, open, onClose }) {
    const [selectedTimestamp, setSelectedTimestamp] = useState('');
    const [error, setError] = useState('');

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        if (selectedTimestamp === '') {
            setError('Please select a valid date and time.');
        } else if (new Date(selectedTimestamp) <= new Date()) {
            setError('Scheduled time must be in the future.');
        } else {
            setError('');
            onDateTimeChange(selectedTimestamp);
            onClose();
        }
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Schedule Delivery</DialogTitle>
            <Box m="20px">
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    label="Select Date and Time"
                                    type="datetime-local"
                                    value={selectedTimestamp}
                                    onChange={(e) => setSelectedTimestamp(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={handleSubmit} variant="outlined" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={handleClose} variant="outlined" color="secondary">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );
}

export default DateTimePicker;


// import React from 'react';
// import { DateTimePicker } from '@mui/x-date-pickers';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// // import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// // import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// function MyDateTimePicker({ onDateTimeChange }) {
//     return (
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <DateTimePicker
//                 label="Select Date and Time"
//                 value={new Date()} // Set the initial value here
//                 onChange={(newValue) => {
//                     // Call the callback with the new value
//                     onDateTimeChange(newValue);
//                 }}
//                 renderInput={(params) => <input {...params} />}
//             />
//         </LocalizationProvider>
//     );
// }

// export default MyDateTimePicker;
// import { useState } from 'react';
// import DateTimePicker from 'react-datetime-picker';
// function MyDateTimePicker({ onDateTimeChange }) {
//     const [selectedDateTime, setSelectedDateTime] = useState(null);
//     // const [value, onChange] = useState < Value > (new Date());
//     const handleDateTimeChange = (date) => {
//         setSelectedDateTime(date);
//         console.log(selectedDateTime)
//         onDateTimeChange(selectedDateTime);
//     };
//     return (
//         <div className='p-5'>
//             <DateTimePicker onChange={setSelectedDateTime} value={selectedDateTime} />
//         </div>
//     );
// }

// export default MyDateTimePicker;







