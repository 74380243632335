export const translator = {

    hindi: {

        // Home 

        "When it comes to plumbing, we're the experts - let us help you keep your cool (and water flowing)!": "जब बात प्लंबिंग की आती है, हम विशेषज्ञ हैं - आइए हम आपको ठंडा (और पानी का प्रवाह) बनाए रखने में मदद करें!",

        "Shop By Categories": "श्रेणियों के अनुसार खरीदारी करें",

        "Highlights": "विशेषताएँ",

        "We care For You": "हमें आपकी परवाह है",

        "Featured Products": "विशेष प्रोडक्टस",

        "Lost in the sauce? Check out our guide page for all the answers!": "सहायता के लिए हमारा मार्गदर्शक पृष्ठ देखें!",

        "Go to Guide": "गाइड पर जाएँ",

        // Quotation Highlight Card 

        "Get Instant Quotation": "तुरंत कोटेशन प्राप्त करें।",

        "Get instant, itemized quotes with all the tax trimmings on our website. No funny business - our quotes and bills are a match made in e-commerce heaven!": "हमारी वेबसाइट पर सटीक विवरण के साथ तुरंत कोटेशन प्राप्त करें। हमारे कोटेशन आपके अंतिम बिल के साथ मेल खाते हैं, पूरी पारदर्शीता के लिए।",



        // cart reminder 

        "Whoa there, shopping superstar!": "वाह ! , शॉपिंग सुपरस्टार!",

        "Looks like you have": "ऐसा लगता है कि आपके बैग में",

        "items in your bag": "आइटम हैं",

        "buy now!": "अभी खरीदें!",

        // Feature Card 

        "Upload Your Order": "अपने ऑर्डर की फोटो अपलोड करें",

        "Don't have time? upload your order in just two clicks and we will place order for you": "समय नहीं है? केवल दो क्लिक में अपने ऑर्डर की फोटो अपलोड करें और हम आपके लिए ऑर्डर करेंगे",

        // Highligh Card 

        "Get discount up to 35%": "35% तक की छूट पाएं",

        "Return unused items, no questions asked!": "अप्रयुक्त वस्तुएँ लौटाएँ, बिना किसी सवाल के ",

        "Shop from popular brands like Finolex, Supreme, Watertec": "फिनोलेक्स, सुप्रीम, वॉटरटेक जैसे लोकप्रिय ब्रांडों से खरीदारी करें",

        "Cancel order anytime before it is shipped": "शिपमेंट से पहले किसी भी समय ऑर्डर रद्द करें",

        "Delivery at your doorstep within one day.": "एक दिन के भीतर आपके दरवाजे पर डिलीवरी।",

        "Hassle-free shopping.": "परेशानी मुक्त खरीदारी",

        // Topbar Card 

        "Guide": "गाइड बुक",

        "Return": "सामान वापस करे",

        "Earnings": "कमाई",

        // Category Carousel 

        "APVC": "एपीवीसी",

        "PVC": "पीवीसी",

        "CPVC": "सीपीवीसी",

        "SWR": "एसडब्ल्यूआर",

        "GI": "जी.आई",

        "TAPS & ACCESSORIES": "नल और सहायक उपकरण",

        "S.S SINK": "एस.एस सिंक",

        "WATER STORAGE TANK": "पानी की टंकी",

        // Footer 

        "Plumbing products, delivered to your door.": "प्लंबिंग उत्पाद आपके द्वारपर पहुँचाए जाएंगे ",

        "Copyrights 2023": "कॉपीराइट 2023",

        "Copyrights 2023": "कॉपीराइट 2023",

        "Crafted With Love": "प्यार से बनाया गया. ",

        "In": "###provide correct context###",

        // Products 

        "Flush your plumbing problems down the drain with our products and services!": "हमारे उत्पादों और सेवाओं से अपनी पाइपलाइन संबंधी समस्याओं को दूर करें!",

        "Categories": "श्रेणियाँ",

        "Types": "प्रकार",

        "Brands": "ब्रांड्स",

        "Sizes": "आकार",

        // Product Details 

        "Light up your plumbing solutions! Click 'Add to Bag' and brighten your DIY game.": "अपने प्लंबिंग समाधानों को रोशन करें! 'बैग में जोड़ें' पर क्लिक करें और अपने DIY गेम को बेहतर बनाएं।",

        "Product": "उत्पाद",

        "Add to Bag": "बैग में जोड़ें",

        "In Stock": "स्टॉक में है",

        "Out Of Stock": "स्टॉक में नहीं है",

        // Upload Order Image 

        "Upload Your Order's Image": "अपने ऑर्डर की फोटो अपलोड करें",

        "Hold on, we are uploading your image.": "रुकिए, हम आपकी फोटो अपलोड कर रहे हैं।",

        "Upload": "अपलोड",

        // My Orders 

        "Today's Orders": "आज के ऑर्डर",

        "This Month's Orders": "इस महीने के ऑर्डर",

        "All Orders": "सभी ऑर्डर",

        "Today": "आज",

        "Month": "महीना",

        "All": "सभी",

        "Sort by date": "दिनांक के हिसाब से अलग करे",

        "Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing)?": "आपका शॉपिंग टाइम कैप्सूल: अच्छे पुराने दिन याद हैं (जैसे पिछले हफ्ते जब आपने वह चीज़ खरीदी थी)?",

        "Check your Image Order Status": "अपने फोट ऑर्डर की स्थिति जांचें",

        "Qty": "मात्रा",

        "View Details": "विवरण देखें",

        // My Orders Conversion Status 

        "Today's": "आज का",

        "This Month's Orders": "इस महीने के",

        "All Orders": "सभी",

        // Order Details 

        "Order Summary": "ऑर्डर सारांश",

        "Order ID:": "ऑर्डर कामतत्व:",

        "Status:": "स्थिति:",

        "Shipping Info:": "शिपिंग जानकारी: ",

        "Your Note": "आपका नोट",

        "Sub Total:": "उप कुल:",

        "GST:": "जीएसटी:",

        "Payment Status:": "भुगतान की स्थिति:",

        "Order is returned": "ऑर्डर वापस कर दिया गया है",

        "You have requested to Process Commission on this Order and hence it cannot be Returned": "आपने इस ऑर्डर पर कमीशन प्रक्रिया करने का अनुरोध किया है और इसलिए इसे वापस नहीं किया जा सकता है",

        "Order is placed": "ऑर्डर दे दिया गया है",

        "Order is shipped and will be delivered soon!": "ऑर्डर भेज दिया गया है और जल्द ही वितरित किया जाएगा!",

        "Order is Cancelled": "ऑर्डर रद्द कर दिया गया है",

        "Order is delivered": "ऑर्डर डिलीवर हो गया है",

        "You have accepted the order": "आपने ऑर्डर स्वीकार कर लिया है",

        "Cancel order before it is shipped!": "शिपमेंट से पहले ऑर्डर रद्द करें!",

        "Cancel Order": "ऑर्डर रद्द",

        "Check Items": "आइटम जांचें",

        "Return Items": "सामान वापस करे",

        "View Bill": "बिल देखें",

        "Order Items:": "ऑर्डर आइटम:",

        "You have Returned Few Items": "आपने कुछ वस्तुएँ लौटा दी हैं",

        // Check Order 

        "Accept": "स्वीकार",

        "Cancel": "रद्द",

        // Return Order Cart 

        "Check Out Return Bag": "चेक आउट रिटर्न बेग",

        // Return Shipping 

        "Return Shipping Details": "रिटर्न ऑर्डर शिपिंग की जानकारियां",

        // My Return Order 

        "Today's Return Orders": "आज के रिटर्न ऑर्डर",

        "This Month's Return Orders": "इस महीने के रिटर्न ऑर्डर",

        "All Return Orders": "सभी रिटर्न ऑर्डर",

        "The Recalled and Rejected: When shopping goes wrong, but at least you get a second chance.": "वापस बुलाए गए और अस्वीकृत ऑर्डर: जब खरीदारी गलत हो जाती है, लेकिन कम से कम आपको दूसरा मौका मिलता है।",

        //Return Order Details 

        "Return Order Summary": "रिटर्न ऑर्डर सारांश",

        "Return Order ID:": "रिटर्न ऑर्डर आईडी:",

        "Return order is cancelled": "रिटर्न ऑर्डर रद्द कर दिया गया है",

        "Return order is collected": "वापसी ऑर्ड जमा किया जाता है। ",

        "Return order is placed": "रिटर्न ऑर्डर दे दिया गया है",

        "Our delivery agent is on the way and will collect your return order soon!": "हमारा डिलीवरी एजेंट रास्ते में है और जल्द ही आपका रिटर्न ऑर्डर ले लेगा!",

        "Cancel return order before it is collected": "रिटर्न ऑर्डर जमा होने से पहले रद्द करें",

        "Cancel Return Order": "रिटर्न ऑर्डर रद्द करें",

        "Return Order Items:": "रिटर्न ऑर्डर आइटम:",

        "Return Delivery Fees-": "रिटर्न डिलीवरी शुल्क-",

        // Earnings Dashboard 

        "Your Earnings Summary": "आपकी कमाई का सारांश",

        "Request Earnings": "आय राशि का अनुरोध करें",

        "Total Earnings": "कुल कमाई",

        "Today's Earnings": "आज की कमाई",

        "This Month's Earnings": "इस महीने की कमाई",

        // Earning Details 

        "All Earnings": "सभी कमाई",

        "Rewards for the devoted: Your purchases are making a difference, and we're giving back for being an amazing customer.": "समर्पित लोगों के लिए पुरस्कार: आपकी खरीदारी से फर्क पड़ रहा है और, हम एक अद्भुत ग्राहक होने का पुरस्कार दे रहे हैं।",

        // Cart 

        "No Product in Your Bag": "आपके बैग में कोई उत्पाद नहीं",

        "View Products": "उत्पाद देखें",

        "Product": "उत्पाद",

        "Quantity": "मात्रा",

        "Subtotal": "उप-कुल ",

        "Gross Total": "कुल योग",

        "Check Out": "चेक आउट",

        "View Quotation": "कोटेशन देखें",

        "Keep calm and click that 'Checkout' button. We'll handle the rest.": "उस 'चेकआउट' बटन पर क्लिक करें। बाकी हम संभाल लेंगे",

        //Bottom Nav Bar 

        "Home": "घर",

        "Products": "उत्पादों",

        "Orders": "ऑर्डर",

        "Bag": "बेग",

        "Login": "लॉग इन",

        // Shipping 

        "Your order will be delivered on": "डिलीवरी की तारीख",

        "Deliver Now": "अभी डिलीवरी करें",

        "Schedule Your Delivery": "अपने डिलीवरी को अनुसूचित करें",

        "Shipping Details": "शिपिंग की जानकारियां",

        "Write a note for reference": "संदर्भ के लिए एक नोट लिखें",

        // Confirm Return Order 

        "Confirm Return Order Details": "रिटर्न ऑर्डर विवरण की पुष्टि करें",

        "Amount to be paid by plumbpigen": "प्लंबपिजेन द्वारा भुगतान की जाने वाली राशि",

        "Shipping Charges to be given to plumbpigen": "शिपिंग शुल्क प्लंबपिजेन को दिया जाएगा",

        "Please Note": "कृपया ध्यान दीजिये",

        "Total amount to be paid by plumbpigen is (Sub Total + GST)-Shipping Charges": "प्लंबपिजेन द्वारा भुगतान की जाने वाली कुल राशि: (उप कुल + जीएसटी)-शिपिंग शुल्क",

        "Delivery Fees-": "डिलिवरी शुल्क-",

        "Your Return Bag Items:": "रिटर्न ऑर्डर आइटम बैग ",

        "Place Return Order": "रिटर्न ऑर्डर प्लेस करें",

        // Confirm Order 

        "Confirm Order Details ": "ऑर्डर विवरण की पुष्टि करें",

        "Shipping Info": "शिपिंग जानकारी",

        "Delivery on": "डिलीवरी की तारीख",

        "Bill Details": "बिल विवरण",

        "Delivery Fees-": "डिलिवरी शुल्क-",

        "Your Bag Items:": "बैग आइटम:",

        "Proceed To Payment": "भुगतान करने के लिए आगे बढ़ें",

        // Payment 

        "Payment Mode": "भुगतान का प्रकार",

        "Cash On Delivery": "डिलवरी पर नकदी",

        "Scan And Pay On Delivery": "डिलीवरी पर स्कैन करें के भुगतान करें",

        "Place Order": "ऑर्डर प्लेस करें",

        // Order Success 

        "Your Order has been Placed successfully": "आपका ऑर्डर सफलतापूर्वक प्लेस कर दिया गया है",

        "View Orders": "ऑर्डर देखें",

        // Return Order Success 

        "Your Return Order has been Placed successfully": "आपका रिटर्न ऑर्डर सफलतापूर्वक प्लेस कर दिया गया है",

        "View Return Orders": "रिटर्न ऑर्डर देखें",

        // Checkout Return Steps 

        "Confirm Return Order": "रिटर्न ऑर्डर की पुष्टि",

        "Return Shipping Details": "रिटर्न ऑर्डर शिपिंग जानकारी",

        // Checkout Return Order Steps 

        "Confirm Order": "ऑर्डर की पुष्टि",

        "Payment": "भुगतान",

        // User Options 

        "About": "हमारे बारे में",

        "Support": "सहायता",

        "Profile": "प्रोफ़ाइल",

        "Logout": "लॉग आउट",

        "Return Orders": "रिटर्न ऑर्डर",

        "Dashboard": "डैशबोर्ड",

        "Switch User": "उपयोगकर्ता बदलें",

        "Earnings": "कमाई",

        // Genreal 

        "Phone Number": "फ़ोन नंबर",

        "Address": "पता",

        "City": "शहर",

        "Pin Code": "पिन कोड",

        "Country": "देश",

        "State": "राज्य",

        "Continue": "जारी रखे",

        "Fetch Current Location": "वर्तमान स्थान प्राप्त करें",

        "Search a Product ...": "कोई उत्पाद खोजें...",

        "Choose Photo": "तस्विर चुनें",

        "Choose File": "फाइलें चुनें",
        "Your order is being packed": "आपका आर्डर पैक हो रहा है",
        "Your order is on the way and will be delivered soon": "आपका आर्डर रास्ते में है और जल्द ही डिलीवर होगा",

    },

    marathi: {

        // Home 

        "When it comes to plumbing, we're the experts - let us help you keep your cool (and water flowing)!": "प्लम्बिंगसाठी जो आपल्याला लागलं तो, आम्ही विशेषज्ञ आहोत - आम्हाला आपल्याला आपल्या शांतीपूर्ण ठेवण्यात (आणि पाण्याची प्रवाहिती) मदत करण्याची अनुमती द्या! ",

        "Shop By Categories": "श्रेणीद्वारे खरेदी करा",

        "Highlights": "उच्चारणे",

        "We care For You": "आम्हाला आपल्याला काळजी आहे",

        "Featured Products": "विशेषत: उल्लिखित उत्पादन",

        "Lost in the sauce? Check out our guide page for all the answers!": "ससर्व उत्तरे पाहण्यासाठी कृपया आमच्या मार्गदर्शिका पृष्ठाचं तपशील चेक करा! ",

        "Go to Guide": "मार्गदर्शक",

        // cart reminder 

        "Whoa there, shopping superstar!": " खरेदीदार सुपरस्टार!",

        "Looks like you have": "आपल्याला असल्याचं दिसतंय",

        "items in your bag": "आपल्याच्या थैल्यात",

        "buy now!": "आता खरेदी करा!",

        // Feature Card 

        "Upload Your Order": "आपले ऑर्डर अपलोड करा",

        "Don't have time? upload your order in just two clicks and we will place order for you": "वेळ नसल्यास? फक्त दोन क्लिकमध्ये आपले ऑर्डर अपलोड करा आणि आम्ही आपल्यासाठी ऑर्डर प्लेस  करतो ",

        // Quotation Highlight Card 

        "Get Instant Quotation": "तात्पुरत्या कोटेशन मिळवा. ",

        "Get instant, itemized quotes with all the tax trimmings on our website. No funny business - our quotes and bills are a match made in e-commerce heaven!": "आमच्या वेबसाइटवर आत्ताच वस्तूसूची कोटेशन आणि सर्व कर कराच्या साजर्यांसह मिळवा.",



        // Highligh Card 

        "Get discount up to 35%": "35% पर्यंत सवलत मिळवा",

        "Return unused items, no questions asked!": "अवप्रयुक्त आयटम लिन्यांतर केलेल्या नाहीत! कोणतेही प्रश्न विचारले जात नाहीत!",

        "Shop from popular brands like Finolex, Supreme, Watertec": "Finolex, Supreme, Watertec इत्यादी लोकप्रिय ब्रँडमधून खरेदी करा",

        "Cancel order anytime before it is shipped": "तो शिप होऊन आणइताना कोणत्याही वेळी ऑर्डर रद्द करा",

        "Delivery at your doorstep within one day.": "एक दिवसात आपल्या दारवाज्यावर डिलिव्हरी.",

        "Hassle-free shopping.": "तंत्राटमुक्त खरेदी",

        // Topbar Card 

        "Guide": "मार्गदर्शक",

        "Return": "परत करा",

        "Earnings": "कमाई",

        // Category Carousel 

        "APVC": "एपीवीसी",

        "PVC": "पीवीसी",

        "CPVC": "सीपीवीसी",

        "SWR": "एसडब्ल्यूआर",

        "GI": "जी.आई",

        "TAPS & ACCESSORIES": "नल आणि सहायक उपकरण",

        "S.S SINK": "एस.एस सिंक",

        "WATER STORAGE TANK": "पाण्याच टॅंक ",

        // Footer 

        "Plumbing products, delivered to your door.": "नलसाजी उत्पादन, आपल्या दरवाज्यावर पोहोचवलेले.",

        "Copyrights 2023": "कॉपीराइट 2023",

        "Crafted With": "सहकार्य",

        "In": "मध्ये",

        // Products 

        "Flush your plumbing problems down the drain with our products and services!": "आमच्या उत्पादनांसह आपल्या नलसाजी समस्यांना खाली घाला!",

        "Categories": "श्रेणी",

        "Types": "प्रकार",

        "Brands": "ब्रँड्स",

        "Sizes": "साईझ",

        // Product Details 

        "Light up your plumbing solutions! Click 'Add to Bag' and brighten your DIY game.": "आपल्या नलसाजी उपायांमध्ये प्रकाश द्या! 'बॅगमध्ये जोडा' व कर्मचारीची तुमची खेळता निर्माण करा.",

        "Product": "उत्पाद",

        "Add to Bag": "बॅगमध्ये जोडा",

        "In Stock": "स्टॉकमध्ये आहे",

        "Out Of Stock": "स्टॉकमधून बाहेर",

        // Upload Order Image 

        "Upload Your Order's Image": "आपल्या ऑर्डरच्या चित्र अपलोड करा",

        "Hold on, we are uploading you image.": "थांबा, आम्ही आपल्या चित्र अपलोड करीत आहोत.",

        "Upload": "अपलोड",

        // My Orders 

        "Today's Orders": "आजच्या ऑर्डर्स",

        "This Month's Orders": "हा महिन्याचा ऑर्डर",

        "All Orders": "सर्व ऑर्डर्स",

        "Today": "आज",

        "Month": "महिना",

        "All": "सर्व",

        "Sort by date": "तारीखानुसार क्रमवार",

        "Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing)?": "आपल्या खरेदीदारीचा वेळ कॅप्सूल: आपल्याला चांगल्या जुन्या दिवसांची आठवण आहे (जसे की पिछल्या आपल्याला त्या गोष्टी खरेदी केल्या आहे कि)?",

        "Check you Image Order Status": "आपल्या चित्र ऑर्डरची स्थिती तपासा",

        "Qty": "मात्रा",

        "View Details": "विवरण पहा",

        // My Orders Conversion Status 

        "Today's": "आजच्या",

        "This Month's Orders": "हा महिन्याचा",

        "All Orders": "सर्व",

        // Order Details 

        "Order Summary": "ऑर्डर संक्षेप",

        "Order ID:": "ऑर्डर क्रमांक:",

        "Status:": "स्थिती:",

        "Shipping Info:": "शिपिंग माहिती:",

        "Your Note": "आपला नोट",

        "Sub Total:": "उप कुल:",

        "GST:": "जीएसटी:",

        "Payment Status:": "भुगतानाची स्थिती:",

        "Order is returned": "ऑर्डर परत केला गेला आहे",

        "You have requested to Process Commission on this Order and hence it cannot be Returned": "आपण हे आदेश प्रक्रिया करण्याची विनंती केली आहे आणि म्हणजे तो परत केला जाऊ शकत नाही",

        "Order is placed": "ऑर्डर ठरवला आहे",

        "Order is shipped and will be delivered soon!": "ऑर्डर शिप केला आहे आणि लवकरच पोहोचविला जाईल!",

        "Order is Cancelled": "ऑर्डर रद्द केला गेला आहे",

        "Order is delivered": "ऑर्डर पोहोचला आहे",

        "You have accepted the order": "आपण आदेश स्वीकारला आहे",

        "Cancel order before it is shipped!": "शिप होऊन आणइताना ऑर्डर रद्द करा!",

        "Cancel Order": "ऑर्डर रद्द करा",

        "Check Items": "आयटम्स तपासा",

        "Return Items": "वस्तू वापस करा",

        "View Bill": "बिल पहा",

        "Order Items:": "ऑर्डर आयटम्स:",

        "You have Returned Few Items": "आपण काही आयटम्स परत केलेले आहेत",

        // Check Order 

        "Accept": "स्वीकारा",

        "Cancel": "रद्द करा",

        // Return Order Cart 

        "Check Out Return Bag": "रिटर्न बॅगचा चेक आउट करा",

        // Return Shipping 

        "Return Shipping Details": "रिटर्न शिपिंगची माहिती",

        // My Return Order 

        "Today's Return Orders": "आजच्या रिटर्न ऑर्डर्स",

        "This Month's Return Orders": "हा महिन्याचा रिटर्न ऑर्डर",

        "All Return Orders": "सर्व रिटर्न ऑर्डर",

        "The Recalled and Rejected: When shopping goes wrong, but at least you get a second chance.": "स्मरण आणि नकारलेला: खरेदीदारीत कुठल्या गोष्टीत चूक जाताना, परंतु कमीत कमी आपल्याला दुसरा मौका मिळतो.",

        //Return Order Details 

        "Return Order Summary": "रिटर्न ऑर्डर सारांश",

        "Return Order ID:": "रिटर्न ऑर्डर क्रमांक:",

        "Return order is cancelled": "रिटर्न ऑर्डर रद्द केला गेला आहे",

        "Return order is collected": "रिटर्न ऑर्डर संकलित केला गेला आहे",

        "Return order is placed": "रिटर्न ऑर्डर ठरवला गेला आहे",

        "Our delivery agent is on the way and will collect your return order soon!": "आमच्या डिलिव्हरी एजेंट मार्गावर आहे आणि लवकरच आपल्याचा रिटर्न ऑर्डर संकलन करेल!",

        "Cancel return order before it is collected": "एकत्र केल्यास आपल्याला रिटर्न ऑर्डर रद्द करावा",

        "Cancel Return Order": "रिटर्न ऑर्डर रद्द करा",

        "Return Order Items:": "रिटर्न ऑर्डर आयटम:",

        "Return Delivery Fees-": "रिटर्न डिलिव्हरी शुल्क-",

        // Earnings Dashboard 

        "Your Earnings Summary": "आपल्या कमाईचे सारांश",

        "Request Earnings": "कमाईची विनंती करा",

        "Total Earnings": "एकूण कमाई",

        "Today's Earnings": "आजची कमाई",

        "This Month's Earnings": "हा महिन्याची कमाई",

        // Earning Details 

        "All Earnings": "सर्व कमाई",

        "Rewards for the devoted: Your purchases are making a difference, and we're giving back for being an amazing customer.": "दृढभावना उपहार: आपल्या खरेदीत फरक पडतो, आणि आपल्याला अद्भुत ग्राहक होण्याचा पुरस्कार देतो.",

        // Cart 

        "No Product in Your Bag": "आपल्याच्या बॅगमध्ये कोणता उत्पादन नाही",

        "View Products": "उत्पाद पहा",

        "Product": "उत्पाद",

        "Quantity": "मात्रा",

        "Subtotal": "उपयोग क्षेत्र",

        "Gross Total": "एकूण कुल",

        "Check Out": "चेक आउट",

        "View Quotation": "दरपत्रिका पहा",

        "Keep calm and click that 'Checkout' button. We'll handle the rest.": "शांत राहा आणि त्याचा 'चेकआउट' बटण क्लिक करा. आम्ही उर्वरणाची तयारी करू.",

        // Bottom Nav Bar 

        "Home": "घर",

        "Products": "उत्पाद",

        "Orders": "ऑर्डर",

        "Bag": "बॅग",

        "Login": "लॉग इन",

        // Shipping 

        "Your order will be delivered on": "आपला ऑर्डर दिल्याची जाईल",

        "Deliver Now": "आता दिला",

        "Schedule Your Delivery": "आपली डिलीवरी अनुसूचीत करा",

        "Shipping Details": "शिपिंगची माहिती",

        "Write a note for reference": "संदर्भसाठी एक नोट लिहा",

        // Confirm Return Order 

        "Confirm Return Order Details": "रिटर्न ऑर्डरच्या विवरणी पुष्टी करा",

        "Amount to be paid by plumbpigen": "प्लंबपिजेन द्वारा भरण्यात येणारी रक्कम",

        "Shipping Charges to be given to plumbpigen": "प्लंबपिजेनला द्यायला लागणारे शिपिंग शुल्क",

        "Please Note": "कृपया लक्षात घ्या",

        "Total amount to be paid by plumbpigen is (Sub Total + GST) - Shipping Charges": "प्लंबपिजेन द्वारा द्यायला लागणारी एकूण रक्कम: (उपयोगक्षेत्र + जीएसटी) - शिपिंग शुल्क",

        "Delivery Fees-": "डिलीवरी शुल्क-",

        "Your Return Bag Items:": "आपली रिटर्न बॅग आयटम्स:",

        "Place Return Order": "रिटर्न ऑर्डर द्या",

        // Confirm Order 

        "Confirm Order Details": "ऑर्डरच्या विवरणी पुष्टी करा",

        "Shipping Info": "शिपिंगची माहिती",

        "Delivery on": "डिलीवरी दिली जाईल",

        "Bill Details": "बिलची विवरण",

        "Delivery Fees-": "डिलीवरी शुल्क-",

        "Your Bag Items:": "आपली बॅग आयटम्स:",

        "Proceed To Payment": "भरण्याच्या क्रियेस पुढे जा",

        // Payment 

        "Payment Mode": "भरण्याची प्रक्रिया",

        "Cash On Delivery": "डिलिव्हरीवर रोख",

        "Scan And Pay On Delivery": "डिलिव्हरीवर स्कॅन करा आणि भरा",

        "Place Order": "ऑर्डर द्या",

        // Order Success 

        "Your Order has been Placed successfully": "आपला ऑर्डर सफळतेच ठरवला आहे",

        "View Orders": "ऑर्डर पहा",

        // Return Order Success 

        "Your Return Order has been Placed successfully": "आपला रिटर्न ऑर्डर सफळतेच ठरवला आहे",

        "View Return Orders": "रिटर्न ऑर्डर पहा",

        // Checkout Return Steps 

        "Confirm Return Order": "रिटर्न ऑर्डरची पुष्टी करा",

        "Return Shipping Details": "रिटर्न शिपिंगची माहिती",

        // Checkout Return Order Steps 

        "Confirm Order": "ऑर्डरची पुष्टी",

        "Payment": "भरण्याची प्रक्रिया",

        // User Options 

        "About": "आमच्याबद्दल",

        "Support": "समर्थन",

        "Profile": "प्रोफाइल",

        "Logout": "लॉगआउट",

        "Return Orders": "रिटर्न ऑर्डर",

        "Dashboard": "डॅशबोर्ड",

        "Switch User": "वापरकर्ता बदला",

        "Earnings": "कमाई",

        // General 

        "Phone Number": "फोन नंबर",

        "Address": "पत्ता",

        "City": "शहर",

        "Pin Code": "पिन कोड",

        "Country": "देश",

        "State": "राज्य",

        "Continue": "सुरू ठेवा",

        "Fetch Current Location": "वर्तमान स्थान तपासा",

        "Search a Product ...": "कोणत्याही उत्पाद शोधा ...",

        "Choose Photo": "फोटो निवडा",

        "Choose File": "फाइल निवडा",
        "Your order is being packed": "तुमचे ऑर्डर पॅक केले जात आहे",
        "Your order is on the way and will be delivered soon": "तुमचे ऑर्डर मार्गावर आहे आणि लवकरच डिलिव्हर होईल",


    },

    kannada: {

        // Home 

        "When it comes to plumbing, we're the experts - let us help you keep your cool (and water flowing)!": "ನಪ್ಲಂಬಿಂಗ್ ಬಗ್ಗೆ ಹೇಗೆ ಹೇಳಬೇಕಾದರೂ, ನಾವು ವಿಶೇಷಜ್ಞರು - ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ನಮಗೆ ಅವಕಾಶ ಕೊಡಿ ",

        "Shop By Categories": "ವರ್ಗಗಳನ್ನು ಆಧಾರಿಸಿ ಖರೀದಿಸಿ",

        "Highlights": "ಮುಖ್ಯ ಅಂಶಗಳು",

        "We care For You": "ನಾವು ನಿಮಗೆ ಗಮನಿಸುತ್ತೇವೆ",

        "Featured Products": "ವೈಶಿಷ್ಟ್ಯಪೂರ್ಣ ಉತ್ಪನ್ನಗಳು",

        "Lost in the sauce? Check out our guide page for all the answers!": "ಎಲ್ಲ ಉತ್ತರಗಳ ಬಗ್ಗೆ ನೋಡಲು ನಮ್ಮ ಮಾರ್ಗದರ್ಶನ ಪುಟವನ್ನು ಪರಿಶೀಲಿಸಿ!",

        "Go to Guide": "ಮಾರ್ಗದರ್ಶನಕ್ಕೆ ಹೋಗಿ",

        // Cart Reminder 

        "Whoa there, shopping superstar!": "ಖರೀದಿಕೆಯ ಸೂಪರ್‌ಸ್ಟಾರ್!",

        "Looks like you have": "ನೀವು ಹೊಂದಿದ್ದೀರಿ ಎಂದು ತೋರುತ್ತದೆ",

        "items in your bag": "ನಿಮ್ಮ 'ಬ್ಯಾಗ್' ನಲ್ಲಿನ ವಸ್ತುಗಳು",

        "buy now!": "ಈಗ ಖರೀದಿಸಿ!",

        // Feature Card 

        "Upload Your Order": "ನಿಮ್ಮ 'ಆರ್ಡರ್' ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",

        "Don't have time? upload your order in just two clicks and we will place order for you": "ಸಮಯವಿಲ್ಲವೆ? 'ಆರ್ಡರ್' ಮಾತ್ರ ಎರಡು ಕ್ಲಿಕ್‌ಗಳಲ್ಲಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ ೆ",

        // Quotation Highlight Card 

        "Get Instant Quotation": "ತಕ್ಷಣ 'ಕೋಟೇಶನ್' ಪಡೆಯಿರಿ",

        "Get instant, itemized quotes with all the tax trimmings on our website. No funny business - our quotes and bills are a match made in e-commerce heaven!": "ನಮ್ಮ ವೆಬ್ಸೈಟ್‌ನಲ್ಲಿ ಸಟ್ಟಳ ವಿವರಗಳನ್ನು ಹೊಂದಿಸಿ ತಕ್ಷಣದಲ್ಲಿ ಸ್ವಯಂಸಿದ್ಧವಾದ ಬೆಲೆಗಳನ್ನು ಪಡೆಯಿರಿ. ನಮ್ಮ 'ಕೋಟೇಶನ್ ನಿಮ್ಮ ಕೊನೆಯ ಬಿಲ್ಲಿನಿಂದ ಸರಿಸಮನ್ವಯ ಮಾಡುತ್ತವೆ, ಪೂರ್ಣ ಪಾರದರ್ಶಕತೆಗಾಗಿ",

        // Highlight Card 

        "Get discount up to 35%": "35% ವರೆಗಿನ ಕೊಡುಗೆ ಪಡೆಯಿರಿ",

        "Return unused items, no questions asked!": "ಅನವಶ್ಯಕವಾಗಿ ಬಳಸಲಾಗದ ಐಟಂಗಳನ್ನು ಮರುಹೊಂದಿಸಿ, ಯಾವ ಪ್ರಶ್ನೆಗಳಿಲ್ಲ!",

        "Shop from popular brands like Finolex, Supreme, Watertec": "Finolex, Supreme, Watertec ಮೊದಲಾದ ಜನಪ್ರಿಯ ಬ್ರಾಂಡ್‌ಗಳಿಂದ ಖರೀದಿಸಿ",

        "Cancel order anytime before it is shipped": "ಯಾವಾಗಲೂ ಪ್ರೊಡಕ್ಟ್ ಅಪ್ಲೋಡ್ ಮಾಡಿದ ನಂತರ ಆದೇಶವನ್ನು ರದ್ದುಮಾಡಬಹುದು",

        "Delivery at your doorstep within one day.": "ಒಂದು ದಿನದ ಒಳಗೆ ನಿಮ್ಮ ಮನೆಗೆ ಡೆಲಿವರಿ",

        "Hassle-free shopping.": "ಹಾಸಲ್-ಫ್ರೀ ಖರೀದಿ",

        // Topbar Card 

        "Guide": "ಮಾರ್ಗದರ್ಶಕ",

        "Return": "ಮರುಹೊಂದಿಸಿ",

        "Earnings": "ಗಳಿಕೆ",

        // Category Carousel 

        "APVC": "APVC",

        "PVC": "PVC",

        "CPVC": "CPVC",

        "SWR": "SWR",

        "GI": "GI",

        "TAPS & ACCESSORIES": "ನೀರಾಪಾದನ ಮತ್ತು ಆಕ್ಸೆಸರಿಗಳು",

        "S.S SINK": "ಎಸ್.ಎಸ್ ಸಿಂಕ್",

        "WATER STORAGE TANK": "ನೀರಿನ ಸಂಗ್ರಹಣ ಟ್ಯಾಂಕ್",

        // Footer 

        "Plumbing products, delivered to your door.": "ಪ್ಲಂಬಿಂಗ್ ಉತ್ಪನ್ನಗಳು, ನಿಮ್ಮ ಮನೆಗೆ ಡೆಲಿವರಿಸಲಾಗುತ್ತವೆ.",

        "Copyrights 2023": "ಕಾಪಿರೈಟ್ 2023",

        "Crafted With": "ನಿರ್ಮಿತವಾಗಿ",

        "In": "ಇನ್",

        "Copyrights 2023": "ಕಾಪಿರೈಟ್ 2023",

        "Crafted With": "ನಿರ್ಮಿತವಾಗಿ",

        "In": "ಇನ್",

        // Products 

        "Flush your plumbing problems down the drain with our products and services!": "ನಮ್ಮ ಉತ್ಪನ್ನಗಳು ಮತ್ತು ಸೇವೆಗಳೊಂದಿಗೆ ನಿಮ್ಮ ನೀರಸರಣಿ ಸಮಸ್ಯೆಗಳನ್ನು ಕೆಳಗೆ ಹಾಕಿ!",

        "Categories": "ವರ್ಗಗಳು",

        "Types": "ಪ್ರಕಾರಗಳು",

        "Brands": "ಬ್ರಾಂಡುಗಳು",

        "Sizes": "ಗಾತ್ರಗಳು",

        // Product Details 

        "Light up your plumbing solutions! Click 'Add to Bag' and brighten your DIY game.": "ನಿಮ್ಮ ನೀರಸರಣಿ ಪರಿಹಾರಗಳನ್ನು ಬೆಳಗಿಸಿ! 'Add to Bag' ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ DIY ಆಟವನ್ನು ಬೆಳಗಿಸಿ.",

        "Product": "ಉತ್ಪನ್ನ",

        "Add to Bag": "ಬ್ಯಾಗ್‌ಗೆ ಸೇರಿಸಿ",

        "In Stock": "ಸ್ಟಾಕ್‌ನಲ್ಲಿದೆ",

        "Out Of Stock": "ಸ್ಟಾಕ್‌ಇಲ್ಲ",

        // Upload Order Image 

        "Upload Your Order's Image": "ನಿಮ್ಮ ಆರ್ಡರ್ ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ",

        "Hold on, we are uploading your image.": " ನಾವು ನಿಮ್ಮ ಆರ್ಡರ್  ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡುತ್ತಿದ್ದೇವೆ.",

        "Upload": "ಅಪ್ಲೋಡ್",

        // My Orders 

        "Today's Orders": "ಇಂದಿನ ಆರ್ಡರ್ ",

        "This Month's Orders": "ಈ ತಿಂಗಳ ಆರ್ಡರ್",

        "All Orders": "ಎಲ್ಲಾ ಆರ್ಡರ್ ",

        "Today": "ಇಂದು",

        "Month": "ತಿಂಗಳು",

        "All": "ಎಲ್ಲಾ",

        "Sort by date": "ದಿನಾಂಕದ ಆಧಾರದಲ್ಲಿ ವಿಂಗಡಿಸು",

        "Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing)?": " Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing) ",

        "Check your Image Order Status": "ನಿಮ್ಮ ಚಿತ್ರ ಆರ್ಡರ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ",

        "Qty": "ಪ್ರಮಾಣ",

        "View Details": "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",

        // My Orders Conversion Status 

        "Today's": "ಇಂದಿನ",

        "This Month's Orders": "ಈ ತಿಂಗಳ ಆರ್ಡರ್ ",

        "All Orders": "ಎಲ್ಲಾ",

        // Order Details 

        "Order Summary": "ಆದೇಶದ ಸಂಕ್ಷೇಪ",

        "Order ID:": "ಆರ್ಡರ್ ಐಡಿ:",

        "Status:": "ಸ್ಥಿತಿ:",

        "Shipping Info:": "ಶಿಪಿಂಗ್ ಮಾಹಿತಿ: ",

        "Your Note": "ನಿಮ್ಮ ಗಮನಪತ್ರ",

        "Sub Total:": "ಉಪ ಒಟ್ಟು:",

        "GST:": "ಜಿಎಸ್ಟಿ:",

        "Payment Status:": "ಪಾವತಿ ಸ್ಥಿತಿ:",

        "Order is returned": "ಆರ್ಡರ್  ಪರಿಹರಿಸಲಾಗಿದೆ",

        "You have requested to Process Commission on this Order and hence it cannot be Returned": "ನೀವು ಈ ಆರ್ಡರ್ ಕಾಮಿಷನ್‌ನನ್ನು ಪ್ರಕ್ರಿಯಿಸಲು ವಿನಂತಿಸಿದ್ದೀರಿ ಮತ್ತು ಆದಾಯಿತವನ್ನು ಪರಿಹರಿಸಲಾಗುವುದಿಲ್ಲ",

        "Order is placed": "ಆರ್ಡರ್  ರವಾನಾಗಿದೆ ",

        "Order is shipped and will be delivered soon!": "ಆರ್ಡರ್ ರವಾನಾಗಿದೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಡೆಲಿವರಿ ಆಗುತ್ತದೆ!",

        "Order is Cancelled": "ಆರ್ಡರ್  ರದ್ದುಗೊಂಡಿದೆ",

        "Order is delivered": "ಆರ್ಡರ ಡೆಲಿವರಿಯಾಗಿದೆ",

        "You have accepted the order": "ನೀವು ಆರ್ಡರ್   ಒಪ್ಪಿದ್ದೀರಿ",

        "Cancel order before it is shipped!": "ಶಿಪ್‌ಮಡ್‌ಗೆ ಹೋಗುವ ಮೊದಲು ಆರ್ಡರ್   ರದ್ದುಮಾಡಿ!",

        "Cancel Order": "ಆರ್ಡರ್  ರದ್ದುಮಾಡಿ",

        "Check Items": "ಐಟಂಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",

        "Return Items": "ಮರಳಿಸುವ ಐಟಂ",

        "View Bill": "ಬಿಲ್ಲು ವೀಕ್ಷಿಸಿ",

        "Order Items:": "ಆರ್ಡರ್   ಐಟಂಗಳು:",

        "You have Returned Few Items": "ನೀವು ಕೆಲವು ಐಟಂಗಳನ್ನು ಪರಿಹರಿಸಿದ್ದೀರಿ",

        // Check Order 

        "Accept": "ಒಪ್ಪಿಗೆ",

        "Cancel": "ರದ್ದು",

        // Return Order Cart 

        "Check Out Return Bag": "ಹಿಂತಿರುಗುವ ಪರ್ಯಾಯ ಸಾಗಣೆ",

        // Return Shipping 

        "Return Shipping Details": "ಹಿಂತಿರುಗುವ ಶಿಪಿಂಗ್ ವಿವರಗಳು",

        // My Return Order 

        "Today's Return Orders": "ಇಂದಿನ ಹಿಂತಿರುಗುವ ಆರ್ಡರ್  ",

        "This Month's Return Orders": "ಈ ತಿಂಗಳ ಹಿಂತಿರುಗುವ ಆರ್ಡರ್",

        "All Return Orders": "ಎಲ್ಲಾ ಹಿಂತಿರುಗುವ ಆರ್ಡರ್",

        "The Recalled and Rejected: When shopping goes wrong, but at least you get a second chance.": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ವಿವರಗಳು",

        // Return Order Details 

        "Return Order Summary": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ಸಂಕ್ಷೇಪ",

        "Return Order ID:": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ಐಡಿ:",

        "Return order is cancelled": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ರದ್ದಾಗಿದೆ",

        "Return order is collected": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ಸೇರಿಸಲಾಗಿದೆ",

        "Return order is placed": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ಸ್ಥಾಪಿಸಲಾಗಿದೆ",

        "Our delivery agent is on the way and will collect your return order soon!": "ನಮ್ಮ ಡೆಲಿವರಿ ಏಜೆಂಟ್ ಮಾರ್ಗದಲ್ಲಿದ್ದು, ನಿಮ್ಮ ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ಬೇಗನೆ ಸಂಗ್ರಹಿಸುತ್ತಾನೆ!",

        "Cancel return order before it is collected": "ಸಂಗ್ರಹಿಸಲಾಗುವ ಮುಂಚೆ ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ರದ್ದುಗೊಳಿಸಿ",

        "Cancel Return Order": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ರದ್ದು",

        "Return Order Items:": "ಹಿಂತಿರುಗುವ ಆರ್ಡರ್ ಐಟಮ್‌ಗಳು",

        "Return Delivery Fees-": "ಹಿಂತಿರುಗುವ ಸಾಗಣೆ ಶುಲ್ಕ-",

        // Earnings Dashboard 

        "Your Earnings Summary": "ನಿಮ್ಮ ಆದಾಯ ಸಂಗ್ರಹ",

        "Request Earnings": "ಆದಾಯ ವಿನಂತಿಸಿ",

        "Total Earnings": "ಒಟ್ಟು ಆದಾಯ",

        "Today's Earnings": "ಇಂದಿನ ಆದಾಯ",

        "This Month's Earnings": "ಈ ತಿಂಗಳ ಆದಾಯ",

        // Earning Details 

        "All Earnings": "ಎಲ್ಲಾ ಆದಾಯಗಳು",

        "Rewards for the devoted: Your purchases are making a difference, and we're giving back for being an amazing customer.": "ನಿಷ್ಠಾವಂತರಿಗೆ ಪ್ರತಿಫಲ: ನಿಮ್ಮ ಖರೀದಿಗಳು ವ್ಯತ್ಯಾಸ ಮಾಡುತ್ತವೆ, ಮಹಾಶಾಲಿ ಗ್ರಾಹಕನಾಗಿ ನಾವು ಪ್ರತಿಸ್ಪರ್ಧಿಸುತ್ತಿದ್ದೇವೆ",

        // Cart 

        "No Product in Your Bag": "ನಿಮ್ಮ ಬ್ಯಾಗಿನಲ್ಲಿ ಯಾವ ಉತ್ಪಾದನೆ ಇಲ್ಲ",

        "View Products": "ಉತ್ಪಾದನೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ",

        "Product": "ಉತ್ಪಾದನೆ",

        "Quantity": "ಪ್ರಮಾಣ",

        "Subtotal": "ಉಪಮೊತ್ತ",

        "Gross Total": "ಒಟ್ಟು ಮೊತ್ತ",

        "Check Out": "ಚೆಕ್ ಔಟ್ ",

        "View Quotation": "ಉದ್ಧರಣೆ ವೀಕ್ಷಿಸಿ",

        "Keep calm and click that 'Checkout' button. We'll handle the rest.": "ಶಾಂತಿಯನ್ನು ಇರಿಸಿ ಮತ್ತು 'ಚೆಕ್‌ಔಟ್' ಬಟನ್‌ಗೆ ಕ್ಲಿಕ್ ಮಾಡಿ..",

        //Bottom Nav Bar 

        "Home": "ಹೊಮ್",

        "Products": "ಉತ್ಪಾದನೆಗಳು",

        "Orders": "ಆರ್ಡರ್ಗಳು",

        "Bag": "ಬ್ಯಾಗ್",

        "Login": "ಲಾಗಿನ್",

        // Shipping 

        "Your order will be delivered on": "ನಿಮ್ಮ ಆದೇಶವು ಡೆಲಿವರಿ ಆಗುತ್ತದೆ",

        "Deliver Now": "ಈಗ ಡೆಲಿವರಿ ಆಗಲಿ",

        "Schedule Your Delivery": "ನಿಮ್ಮ ಡೆಲಿವರಿಯನ್ನು ಷೆಡ್ಯೂಲ್ ಮಾಡಿ",

        "Shipping Details": "ಶಿಪಿಂಗ್ ವಿವರಗಳು",

        "Write a note for reference": "ಸಂದರ್ಭದ ಪ್ರಮಾಣಕ್ಕಾಗಿ ಒಂದು ಕಟಕ್ಷ ಬರೆಯಿರಿ",

        // Confirm Return Order 

        "Confirm Return Order Details": "ರಿಟರ್ನ್ ಆರ್ಡರ್ ವಿವರಗಳನ್ನು ದೃಢೀಕರಿಸಿ",

        "Amount to be paid by plumbpigen": " plumbpigen   ಪಾವತಿಸಲಾಗಬೇಕಾದ ಮೊತ್ತ",

        "Shipping Charges to be given to plumbpigen": "plumbpigen  ಗೆ ನೀಡಬೇಕಾದ ಶಿಪಿಂಗ್ ಶುಲ್ಕ",

        "Please Note": "ದಯವಿಟ್ಟು ಗಮನಿಸಿ",

        "Total amount to be paid by plumbpigen is (Sub Total + GST)-Shipping Charges": " plumbpigen ಗೆ ಪಾವತಿಸಲಾಗಬೇಕಾದ ಒಟ್ಟು ಮೊತ್ತ: (ಉಪ ಒಟ್ಟು + ಜಿಎಸ್ಟಿ)-ಶಿಪಿಂಗ್ ಶುಲ್ಕ",

        "Delivery Fees-": "ಡೆಲಿವರಿ ಶುಲ್ಕ-",

        "Your Return Bag Items:": "ನಿಮ್ಮ ರಿಟರ್ನ್ ಬ್ಯಾಗ್ ಐಟಮ್ಗಳು:",

        "Place Return Order": "ರಿಟರ್ನ್ ಆರ್ಡರ್ ಪ್ರಾರಂಭಿಸಿ",

        // Confirm Order 

        "Confirm Order Details ": "ಆರ್ಡರ್ ವಿವರಗಳನ್ನು ದೃಢೀಕರಿಸಿ",

        "Shipping Info": "ಶಿಪಿಂಗ್ ಮಾಹಿತಿ",

        "Delivery on": "ವಿತರಣೆ ",

        "Bill Details": "ಬಿಲ್ ವಿವರಗಳು",

        "Delivery Fees-": "ಡೆಲಿವರಿ ಶುಲ್ಕ-",

        "Your Bag Items:": "ನಿಮ್ಮ ಬ್ಯಾಗ್ ಐಟಮ್ಗಳು:",

        "Proceed To Payment": "ಪಾವತಿಗೆ ಮುಂದುವರಿಯಿರಿ",

        // Payment 

        "Payment Mode": "ಪಾವತಿ ರೀತಿ",

        "Cash On Delivery": "ಡೆಲಿವರಿಗೆ ನಗದು",

        "Scan And Pay On Delivery": "ಡೆಲಿವರಿಗೆ ಸ್ಕ್ಯಾನ್ ಮಾಡಿ ಮತ್ತು ಪಾವತಿಸಿ",

        "Place Order": "ಆರ್ಡರ್ ಪ್ರಾರಂಭಿಸಿ",

        // Order Success 

        "Your Order has been Placed successfully": "ನಿಮ್ಮ ಆರ್ಡರ್ ಯಶಸ್ವಿಯಾಗಿ ಪ್ರಾರಂಭಿಸಿದೆ",

        "View Orders": "ಆರ್ಡರ್ ವೀಕ್ಷಿಸಿ",

        // Return Order Success 

        "Your Return Order has been Placed successfully": "ನಿಮ್ಮ ರಿಟರ್ನ್ ಆರ್ಡರ್ ಯಶಸ್ವಿಯಾಗಿ ಪ್ರಾರಂಭಿಸಿದೆ",

        "View Return Orders": "ರಿಟರ್ನ್ ಆರ್ಡರ್ ವೀಕ್ಷಿಸಿ",

        // Checkout Return Steps 

        "Confirm Return Order": "ರಿಟರ್ನ್ ಆರ್ಡರ್ ದೃಢೀಕರಿಸಿ",

        "Return Shipping Details": "ರಿಟರ್ನ್ ಶಿಪಿಂಗ್ ವಿವರಗಳು",

        // Checkout Return Order Steps 

        "Confirm Order": "ಆರ್ಡರ್ ದೃಢೀಕರಿಸಿ",

        "Payment": "ಪಾವತಿ",

        // User Options 

        "About": "ನಮ್ಮ ಬಗ್ಗೆ",

        "Support": "ಬೆಂಬಲ",

        "Profile": "ಪ್ರೊಫೈಲ್",

        "Logout": "ಲಾಗೌಟ್",

        "Return Orders": "ರಿಟರ್ನ್ ಆರ್ಡರ್ ",

        "Dashboard": "ಡ್ಯಾಶ್ಬೋರ್ಡ್",

        "Switch User": "ಬಳಸುವ ಬಳಕೆದಾರನು ಬದಲಾಯಿಸಿ",

        "Earnings": "ಆದಾಯ",

        // General 

        "Phone Number": "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",

        "Address": "ವಿಳಾಸ",

        "City": "ನಗರ",

        "Pin Code": "ಪಿನ್ ಕೋಡ್",

        "Country": "ದೇಶ",

        "State": "ರಾಜ್ಯ",

        "Continue": "ಮುಂದುವರಿಸಿ",

        "Fetch Current Location": "ಪ್ರಸ್ತುತ ಸ್ಥಾನವನ್ನು ದೊರಕಿಸಿ",

        "Search a Product ...": "ಒಂದು ಉತ್ಪದನೆಯನ್ನು ಹುಡುಕಿ...",

        "Choose Photo": "ಫೋಟೋ ಆಯ್ಕೆಮಾಡಿ",

        "Choose File": "ಫೈಲ್ ಆರಿಸಿ",
        "Your order is being packed": "ನಿಮ್ಮ ಆರ್ಡರ್ ಪ್ಯಾಕ್ ಆಗುತ್ತಿದೆ",
        "Your order is on the way and will be delivered soon": "ನಿಮ್ಮ ಆರ್ಡರ್ ಬಾಣಗೆಯಲ್ಲಿದೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲಿ ವಹಿಸಲಾಗುತ್ತದೆ",

    },

    english: {

        // Home 

        "When it comes to plumbing, we're the experts - let us help you keep your cool (and water flowing)!": "When it comes to plumbing, we're the experts - let us help you keep your cool (and water flowing)!",

        "Shop By Categories": "Shop By Categories",

        "Highlights": "Highlights",

        "We care For You": "We care For You",

        "Featured Products": "Featured Products",

        "Lost in the sauce? Check out our guide page for all the answers!": "Lost in the sauce? Check out our guide page for all the answers!",

        "Go to Guide": "Go to Guide",

        // Cart Reminder 

        "Whoa there, shopping superstar!": "Whoa there, shopping superstar!",

        "Looks like you have": "Looks like you have",

        "items in your bag": "items in your bag",

        "buy now!": "buy now!",

        // Feature Card 

        "Upload Your Order": "Upload Your Order",

        "Don't have time? upload your order in just two clicks and we will place order for you": "Don't have time? upload your order in just two clicks and we will place the order for you",
        // Quotation Highlight Card
        "Get Instant Quotation": "Get Instant Quotation",
        "Get instant, itemized quotes with all the tax trimmings on our website. No funny business - our quotes and bills are a match made in e-commerce heaven!": "Get instant quotation with all the tax on our website. No funny business - our quotes and bills are a match made in e-commerce heaven!",
        // Highlight Card 

        "Get discount up to 35%": "Get discount up to 35%",

        "Return unused items, no questions asked!": "Return unused items, no questions asked!",

        "Shop from popular brands like Finolex, Supreme, Watertec": "Shop from popular brands like Finolex, Supreme, Watertec",

        "Cancel order anytime before it is shipped": "Cancel order anytime before it is shipped",

        "Delivery at your doorstep within one day.": "Delivery at your doorstep within one day.",

        "Hassle-free shopping.": "Hassle-free shopping.",

        // Topbar Card 

        "Guide": "Guide",

        "Return": "Return",

        "Earnings": "Earnings",

        // Category Carousel 

        "APVC": "APVC",

        "PVC": "PVC",

        "CPVC": "CPVC",

        "SWR": "SWR",

        "GI": "GI",

        "TAPS & ACCESSORIES": "TAPS & ACCESSORIES",

        "S.S SINK": "S.S SINK",

        "WATER STORAGE TANK": "WATER STORAGE TANK",

        // Footer 

        "Plumbing products, delivered to your door.": "Plumbing products, delivered to your door.",

        "Copyrights 2023": "Copyrights 2023",

        "Copyrights 2023": "Copyrights 2023",

        "Crafted With": "Crafted With",

        "In": "In",

        "Products": "Products",

        "Flush your plumbing problems down the drain with our products and services!": "Flush your plumbing problems down the drain with our products and services!",

        "Categories": "Categories",

        "Types": "Types",

        "Brands": "Brands",

        "Sizes": "Sizes",

        // Product Details 

        "Light up your plumbing solutions! Click 'Add to Bag' and brighten your DIY game.": "Light up your plumbing solutions! Click 'Add to Bag' and brighten your DIY game.",

        "Product": "Product",

        "Add to Bag": "Add to Bag",

        "In Stock": "In Stock",

        "Out Of Stock": "Out Of Stock",

        // Upload Order Image 

        "Upload Your Order's Image": "Upload Your Order's Image",

        "Hold on, we are uploading your image.": "Hold on, we are uploading your image.",

        "Upload": "Upload",

        // My Orders 

        "Today's Orders": "Today's Orders",

        "This Month's Orders": "This Month's Orders",

        "All Orders": "All Orders",

        "Today": "Today",

        "Month": "Month",

        "All": "All",

        "Sort by date": "Sort by date",

        "Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing)?": "Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing)?",

        "Check your Image Order Status": "Check your Image Order Status",

        "Qty": "Qty",

        "View Details": "View Details",

        // My Orders Conversion Status 

        "Today's": "Today's",

        "This Month's Orders": "This Month's Orders",

        "All Orders": "All Orders",

        // Order Details 

        "Order Summary": "Order Summary",

        "Order ID:": "Order ID:",

        "Status:": "Status:",

        "Shipping Info:": "Shipping Info:",

        "Your Note": "Your Note",

        "Sub Total:": "Sub Total:",

        "GST:": "GST:",

        "Payment Status:": "Payment Status:",

        "Order is returned": "Order is returned",

        "You have requested to Process Commission on this Order and hence it cannot be Returned": "You have requested to Process Commission on this Order and hence it cannot be Returned",

        "Order is placed": "Order is placed",

        "Order is shipped and will be delivered soon!": "Order is shipped and will be delivered soon!",

        "Order is Cancelled": "Order is Cancelled",

        "Order is delivered": "Order is delivered",

        "You have accepted the order": "You have accepted the order",

        "Cancel order before it is shipped!": "Cancel order before it is shipped!",

        "Cancel Order": "Cancel Order",

        "Check Items": "Check Items",

        "Return Items": "Return Items",

        "View Bill": "View Bill",

        "Order Items:": "Order Items:",

        "You have Returned Few Items": "You have Returned Few Items",

        // Check Order 

        "Accept": "Accept",

        "Cancel": "Cancel",

        // Return Order Cart 

        "Check Out Return Bag": "Check Out Return Bag",

        // Return Shipping 

        "Return Shipping Details": "Return Shipping Details",

        // My Return Order 

        "Today's Return Orders": "Today's Return Orders",

        "This Month's Return Orders": "This Month's Return Orders",

        "All Return Orders": "All Return Orders",

        "The Recalled and Rejected: When shopping goes wrong, but at least you get a second chance.": "The Recalled and Rejected: When shopping goes wrong, but at least you get a second chance.",

        // Return Order Details 

        "Return Order Summary": "Return Order Summary",

        "Return Order ID:": "Return Order ID:",

        "Return order is cancelled": "Return order is cancelled",

        "Return order is collected": "Return order is collected",

        "Return order is placed": "Return order is placed",

        "Our delivery agent is on the way and will collect your return order soon!": "Our delivery agent is on the way and will collect your return order soon!",

        "Cancel return order before it is collected": "Cancel return order before it is collected",

        "Cancel Return Order": "Cancel Return Order",

        "Return Order Items:": "Return Order Items:",

        "Return Delivery Fees-": "Return Delivery Fees-",

        // Earnings Dashboard 

        "Your Earnings Summary": "Your Earnings Summary",

        "Request Earnings": "Request Earnings",

        "Total Earnings": "Total Earnings",

        "Today's Earnings": "Today's Earnings",

        "This Month's Earnings": "This Month's Earnings",

        // Earning Details 

        "All Earnings": "All Earnings",

        "Rewards for the devoted: Your purchases are making a difference, and we're giving back for being an amazing customer.": "Rewards for the devoted: Your purchases are making a difference, and we're giving back for being an amazing customer.",

        // Cart 

        "No Product in Your Bag": "No Product in Your Bag",

        "View Products": "View Products",

        "Product": "Product",

        "Quantity": "Quantity",

        "Subtotal": "Subtotal",

        "Gross Total": "Gross Total",

        "Check Out": "Check Out",

        "View Quotation": "View Quotation",

        "Keep calm and click that 'Checkout' button. We'll handle the rest.": "Keep calm and click that 'Checkout' button. We'll handle the rest.",

        //Bottom Nav Bar 

        "Home": "Home",

        "Products": "Products",

        "Orders": "Orders",

        "Bag": "Bag",

        "Login": "Login",

        // Shipping 

        "Your order will be delivered on": "Delivery Date",

        "Deliver Now": "Deliver Now",

        "Schedule Your Delivery": "Schedule Your Delivery",

        "Shipping Details": "Shipping Details",

        "Write a note for reference": "Write a note for reference",

        // Confirm Return Order 

        "Confirm Return Order Details": "Confirm Return Order Details",

        "Amount to be paid by plumbpigen": "Amount to be paid by plumbpigen",

        "Shipping Charges to be given to plumbpigen": "Shipping Charges to be given to plumbpigen",

        "Please Note": "Please Note",

        "Total amount to be paid by plumbpigen is (Sub Total + GST)-Shipping Charges": "Total amount to be paid by plumbpigen is (Sub Total + GST)-Shipping Charges",

        "Delivery Fees-": "Delivery Fees-",

        "Your Return Bag Items:": "Your Return Bag Items:",

        "Place Return Order": "Place Return Order",

        // Confirm Order 

        "Confirm Order Details ": "Confirm Order Details",

        "Shipping Info": "Shipping Info",

        "Delivery on": "Delivery Date",

        "Bill Details": "Bill Details",

        "Delivery Fees-": "Delivery Fees-",

        "Your Bag Items:": "Your Bag Items:",

        "Proceed To Payment": "Proceed To Payment",

        // Payment 

        "Payment Mode": "Payment Mode",

        "Cash On Delivery": "Cash On Delivery",

        "Scan And Pay On Delivery": "Scan And Pay On Delivery",

        "Place Order": "Place Order",

        // Order Success 

        "Your Order has been Placed successfully": "Your Order has been Placed successfully",

        "View Orders": "View Orders",

        // Return Order Success 

        "Your Return Order has been Placed successfully": "Your Return Order has been Placed successfully",

        "View Return Orders": "View Return Orders",

        // Checkout Return Steps 

        "Confirm Return Order": "Confirm Return Order",

        "Return Shipping Details": "Return Shipping Details",

        // Checkout Return Order Steps 

        "Confirm Order": "Confirm Order",

        "Payment": "Payment",

        // User Options 

        "About": "About",

        "Support": "Support",

        "Profile": "Profile",

        "Logout": "Logout",

        "Return Orders": "Return Orders",

        "Dashboard": "Dashboard",

        "Switch User": "Switch User",

        "Earnings": "Earnings",

        // General 

        "Phone Number": "Phone Number",

        "Address": "Address",

        "City": "City",

        "Pin Code": "Pin Code",

        "Country": "Country",

        "State": "State",

        "Continue": "Continue",

        "Fetch Current Location": "Fetch Current Location",

        "Search a Product ...": "Search a Product ...",

        "Choose Photo": "Choose Photo",

        "Choose File": "Choose File",
        "Your order is being packed": "Your order is being packed",
        "Your order is on the way and will be delivered soon": "Your order is on the way and will be delivered soon",

    },

} 