import React, { Fragment, useState } from "react";
import "./Header.css";
import SpeedDial from '@mui/material/SpeedDial';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Backdrop from '@mui/material/Backdrop';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import PersonIcon from '@mui/icons-material/Person';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ListAltIcon from '@mui/icons-material/ListAlt';
// import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
// import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
// import SearchIcon from '@mui/icons-material/Search';
// import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { logout } from "../../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import MenuIcon from '@mui/icons-material/Menu'; // Import the MenuIcon

const UserOptions = ({ user }) => {
  const { cartItems } = useSelector((state) => state.cart);

  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const options = [
    // { icon: <SearchIcon />, name: "Search", func: search },

    { icon: <BusinessIcon />, name: "About", func: about },
    { icon: <SupportAgentOutlinedIcon />, name: "Support", func: contact },
    { icon: <AccountCircleOutlinedIcon />, name: "Profile", func: account },
    { icon: <ExitToAppIcon />, name: "Logout", func: logoutUser },
  ];

  // if (user.role === "user" || user.role === "admin" || user.role === "developer" || user.role === "generalUser") {
  //   options.unshift({ icon: <HomeIcon />, name: "Home", func: home });
  // }
  // if (user.role === "user" || user.role === "admin" || user.role === "developer" || user.role === "generalUser") {
  //   options.unshift({ icon: <PlumbingIcon />, name: "Products", func: products });
  // }
  // if (user.role === "user" || user.role === "admin" || user.role === "developer" || user.role === "generalUser") {
  //   options.unshift({ icon: <ListAltIcon />, name: "Orders", func: orders });
  // }
  options.unshift({ icon: <KeyboardReturnIcon />, name: "Return Orders", func: returnOrders });
  options.unshift({ icon: (<ShoppingBagOutlinedIcon style={{ color: cartItems.length > 0 ? "#01a2f5" : "unset" }} />), name: `Bag(${cartItems.length})`, func: cart, });

  if (user && user.role && (user.role === "storeEmployee" || user.role === "admin" || user.role === "developer")) {
    options.unshift({ icon: <DashboardOutlinedIcon />, name: "Dashboard", func: dashboard, });
  }
  if (user && user.role && (user.role === "admin" || user.role === "developer")) {
    options.unshift({ icon: <TuneOutlinedIcon />, name: "Switch User", func: switchUser });
  }
  if (user && user.role && (user.role === "user" || user.role === "admin" || user.role === "developer")) {
    options.unshift({ icon: <CurrencyRupeeIcon />, name: "Earnings", func: commissionDashboard });
  }

  // if (user.role === "storeEmployee" || user.role === "admin" | user.role === "developer") {
  //   options.unshift({ icon: <StorefrontOutlinedIcon />, name: "Store", func: storeDashboard });
  // }

  function dashboard() {
    window.location.href = "https://nest.plumbpigen.com";
    // navigate("/admin/dashboard");
  }
  function commissionDashboard() {
    navigate("/commission/dashboard");
  }
  // function storeDashboard() {
  //   navigate("/store/dashboard");
  // }

  function orders() {
    navigate("/orders");
  }
  // function search() {
  //   navigate("/search");
  // }
  function returnOrders() {
    navigate("/returnorders");
  }
  function account() {
    navigate("/account");
  }
  function cart() {
    navigate("/bag");
  }
  function home() {
    navigate("/");
  }
  function products() {
    navigate("/products");
  }
  function about() {
    navigate("/about");
  }
  function contact() {
    navigate("/contact");
  }
  function switchUser() {
    navigate("/switchuser");
  }
  function logoutUser() {
    dispatch(logout());
    navigate("/login");
    alert.success("Logout Successfully");
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Fragment>
      <Backdrop open={open} style={{ zIndex: 9 }} />
      <SpeedDial
        sx={{ color: 'red' }}
        ariaLabel="SpeedDial tooltip example"
        FabProps={{
          size: 'small', style: {
            backgroundColor: 'transparent', boxShadow: 'none'// Set the background color to transparent
          },
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        style={{ zIndex: 10, elevation: 0 }}
        open={open}
        direction="down"
        className="speedDial flat"
        icon={<MenuIcon style={{ color: 'black' }} />} // Use the MenuIcon component
      >
        {options.map((item) => (
          <SpeedDialAction
            key={item.name}
            icon={item.icon}
            tooltipTitle={item.name}
            onClick={item.func}
            tooltipOpen={matches} // Use the matches value to determine tooltipOpen
          />
        ))}
      </SpeedDial>
    </Fragment>
  );
};

export default UserOptions;
