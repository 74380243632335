import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from 'react-redux';
import { translator } from "../../translator"

const CurrentLocation = ({ shippingDetails }) => {
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [showLocation, setShowLocation] = useState(false);

    const fetchCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        const response = await axios.get(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD5TI1nB37wzae1KTYLOFDPB38nfiGV0wk`
                        );

                        const addressComponents = response.data.results[0]?.address_components || [];
                        setAddress(formatAddress(addressComponents));
                        setPincode(getAddressComponent(addressComponents, "postal_code"));
                        setCity(getAddressComponent(addressComponents, "locality"));
                        setState(getAddressComponent(addressComponents, "administrative_area_level_1"));
                        setCountry(getAddressComponent(addressComponents, "country"));

                        // Show the fetched location
                        // setShowLocation(true);
                        handleSubmit()
                    } catch (error) {
                        console.error("Error fetching current location: ", error.message);
                    }
                },
                (error) => {
                    console.error("Error fetching current location: ", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    const formatAddress = (addressComponents) => {
        const streetNumber = getAddressComponent(addressComponents, "street_number");
        const route = getAddressComponent(addressComponents, "route");
        const political = getAddressComponent(addressComponents, "political");
        const sublocality = getAddressComponent(addressComponents, "sublocality");
        const sublocalityLevel2 = getAddressComponent(addressComponents, "sublocality_level_2");
        return `${streetNumber} ${route} ${political} ${sublocality} ${sublocalityLevel2}`;
    };

    const getAddressComponent = (addressComponents, type) => {
        const component = addressComponents.find((comp) =>
            comp.types.includes(type)
        );
        return component ? component.long_name : "";
    };

    const handleSubmit = () => {
        let rawData = { address, pincode, city, state, country };
        shippingDetails(rawData);
    };

    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);

    return (
        <div className="button-container">
            {!showLocation && (
                <button className="button2" onClick={fetchCurrentLocation}>{translator[language]["Fetch Current Location"]}</button>
            )}
            {showLocation && (
                <>
                    <p>Address: {address}</p>
                    <p>Pincode: {pincode}</p>
                    <p>City: {city}</p>
                    <p>State: {state}</p>
                    <p>Country: {country}</p>
                    <button className="button2" onClick={handleSubmit}>Submit</button>
                </>
            )}
        </div>
    );
};

export default CurrentLocation;



// import React, { useState } from "react";
// import axios from "axios";

// const CurrentLocation = ({ shippingDetails }) => {
//     const [address, setAddress] = useState("");
//     const [pincode, setPincode] = useState("");
//     const [city, setCity] = useState("");
//     const [state, setState] = useState("");
//     const [country, setCountry] = useState("");
//     const [showLocation, setShowLocation] = useState(false);

//     const fetchCurrentLocation = () => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 async (position) => {
//                     const { latitude, longitude } = position.coords;
//                     try {
//                         const response = await axios.get(
//                             `https://apis.mapmyindia.com/advancedmaps/v1/${'466e75842c0788bf635a524ba15e4a8f'}/rev_geocode?lat=${latitude}&lng=${longitude}`
//                         );

//                         const responseData = response.data.results;
//                         setAddress(responseData[0].houseNumber + ' ' + responseData[0].houseName + ' ' + responseData[0].formatted_address);
//                         setPincode(responseData[0].pincode);
//                         setCity(responseData[0].city);
//                         setState(responseData[0].state);
//                         setCountry(responseData[0].country);
//                         handleSubmit()
//                         // setShowLocation(true);
//                     } catch (error) {
//                         console.error("Error fetching current location: ", error.message);
//                     }
//                 },
//                 (error) => {
//                     console.error("Error fetching current location: ", error);
//                 }
//             );
//         } else {
//             console.error("Geolocation is not supported by this browser.");
//         }
//     };

//     const handleSubmit = () => {
//         let rawData = { address, pincode, city, state, country };
//         shippingDetails(rawData);
//     };

//     return (
//         <div className="button-container">
//             {!showLocation && (
//                 <button className="button2" onClick={fetchCurrentLocation}>Fetch Current Location</button>
//             )}
//             {showLocation && (
//                 <>
//                     <p>Address: {address}</p>
//                     <p>Pincode: {pincode}</p>
//                     <p>City: {city}</p>
//                     <p>State: {state}</p>
//                     <p>Country: {country}</p>
//                     <button className="button2" onClick={handleSubmit}>Submit</button>
//                 </>
//             )}
//         </div>
//     );
// };

// export default CurrentLocation;
