import React, { Fragment, useEffect, useState } from "react";
import "./myOrders.css";
import { useSelector } from "react-redux";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import MetaData from "../layout/metaData";
import moment from "moment"
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orderBy } from 'lodash';
import Stack from '@mui/material/Stack';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import axios from 'axios';
import { translator } from "../../translator"
const apiHelper = require("../../helpers")

const MyOrderConversionStatus = () => {
    const alert = useAlert();
    const theme = createTheme();
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const { token } = useSelector((state) => state.token);
    const [rows, setRows] = useState([])
    const [show, setShow] = useState("today")
    const [sortOrder, setSortOrder] = useState('asc');
    const sortedRows = orderBy(rows, 'createdAt', sortOrder);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    let loading = false
    let showValue = "today"
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    }
    const handleSortClick = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };
    function yyyymmdd() {
        var x = new Date();
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }
    function Dates(date) {
        var x = new Date(date);
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let Todaydate = yyyymmdd()

    const fetchData = async (fromDate, toDate, timeframe) => {
        let payload = {}
        let rows_temp = [];
        payload['fromDate'] = fromDate
        payload['toDate'] = toDate
        payload['user_id'] = user._id
        payload['timeframe'] = timeframe
        axios.post(apiHelper.api + 'order/images', payload, config)
            .then(response => {
                if (response.data.success) {
                    rows_temp = response.data.data
                    setShow(timeframe)
                    setRows(rows_temp)
                }
            }).catch(error => {
                alert.error(error);
            });
    };

    const showOrderDateWise = (e, value) => {
        showValue = value
        switch (showValue) {
            case "all":
                fetchData(null, null, "all")
                break
            case "month":
                fetchData(Dates(firstDay), Dates(lastDay), "month")
                break
            case "today":
                fetchData(Todaydate, Todaydate, "today")
                break
            default:
        }
    }
    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    useEffect(() => {
        showOrderDateWise("", "today")
    }, []);


    return (
        <Fragment>
            <MetaData title={`${user.name} - Orders`} />

            {loading ? (
                <Loader />
            ) : (
                <div className="productListContainer" id="list">
                    <div className="awesome-phrase-container7">
                        {show === "today" &&
                            <p >{translator[language]["Today's"]}</p>
                        }
                        {show === "month" &&
                            <p >{translator[language]["This Month's"]}</p>
                        }
                        {show === "all" &&
                            <p>{translator[language]["All"]}</p>
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'today')} variant="outlined" size="small">
                                {/* TODAY */}
                                <TodayIcon />
                                {translator[language]["Today"]}
                            </Button>
                            <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'month')} variant="outlined" size="small">
                                <CalendarMonthIcon />
                                {translator[language]["Month"]}
                            </Button>
                            <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'all')} variant="outlined" size="small" >
                                <CalendarTodayIcon />
                                {translator[language]["All"]}
                            </Button>
                        </Stack>
                    </div>
                    <Button onClick={handleSortClick}>{translator[language]["Sort by date"]} ({sortOrder === 'asc' ? 'latest' : 'oldest'})</Button>
                    <div className="awesome-phrase-container4">
                        <p className="awesome-phrase">
                            "Giggle Plumbing Mart: Where Poses Fix and Orders Click! 📸🎉🛁"
                        </p>
                    </div>
                    <ThemeProvider theme={theme}>
                        <main style={{ overflowY: 'auto', height: '100%' }}>
                            <Container sx={{ py: 3 }}>
                                {/* End hero unit */}
                                <Grid container spacing={4}>
                                    {sortedRows.map((row) => (
                                        // <Grid item key={row.id}>
                                        <Grid item key={row.id} xs={12} sm={6} md={6}>
                                            <Card variant="outlined"
                                                sx={{
                                                    height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', transition: 'transform 0.2s ease-in-out'
                                                }}
                                            >
                                                {row.convertedToOrder === "Placed" ? (
                                                    <Typography style={{ color: '#69f0ae', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">Order Placed</Typography>
                                                ) : (
                                                    <Typography style={{ color: '#ff8a80', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">Pending</Typography>
                                                )
                                                }
                                                {row.orderImages && row.orderImages.length > 0 && (
                                                    <div style={{ display: 'flex', height: '100px' }}>
                                                        {row.orderImages.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image.url}
                                                                alt={`Order Image ${index + 1}`}
                                                                style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                                {/* {row.orderImages && row.orderImages.length > 0 && (
                                                    <div style={{ display: 'flex', overflowX: 'auto', maxHeight: '100px' }}>
                                                        {row.orderImages.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image.url}
                                                                alt={`Order Image ${index + 1}`}
                                                                style={{ minWidth: '100%', height: 'auto', objectFit: 'cover', marginRight: '10px' }}
                                                            />
                                                        ))}
                                                    </div>
                                                )} */}
                                                <CardContent sx={{ flexGrow: 1 }}>
                                                    <Typography style={{ color: '#002244', fontWeight: 'bold', marginTop: '5%' }} gutterBottom variant="h5" component="h2">
                                                        {row._id}
                                                    </Typography>
                                                    <Typography style={{ color: '#002244', marginTop: '5%' }}>
                                                        {row.createdAt}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </main>
                    </ThemeProvider>
                </div>
            )
            }
        </Fragment >
    );
};

export default MyOrderConversionStatus;