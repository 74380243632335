import React, { Fragment, useEffect, useState } from "react";
// import { DataGrid } from '@mui/x-data-grid';
import "./myOrders.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, myOrders } from "../../actions/orderAction";
import Loader from "../layout/loader/Loader";
// import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/metaData";
// import LaunchIcon from '@mui/icons-material/Launch';
import moment from "moment"
// import ordersTemplate from "../Templates/order"
import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import { orderBy } from 'lodash';
import Stack from '@mui/material/Stack';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { Box } from "@mui/material";
import uploadOrder from '../../images/assets/uploadOrder.png'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import { translator } from "../../translator"

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.8),
    borderRadius: theme.spacing(1),
    // backgroundColor: '#F7F7F7',
    // border: '1px solid',
    margin: '3px',
    flex: 1,
  },
  icon: {
    fontSize: 30,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 16,
    textAlign: 'center',
  },
}));


const MyOrders = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let showValue = "today"
  const dispatch = useDispatch();

  const alert = useAlert();

  const { loading, error, orders } = useSelector((state) => state.myOrders);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [rows, setRows] = useState([])
  const [show, setShow] = useState("today")
  // const [currentPage, setCurrentPage] = useState(1)
  const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending order

  const sortedRows = orderBy(rows, 'createdAt', sortOrder);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };

  const handleSortClick = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  if (user && user.role === 'storeEmployee') {
    navigate(`/store/dashboard`);
  }
  // const setCurrentPageNo = (e) => {
  //   setCurrentPage(e)
  // }

  const handleOrderImageCardClick = () => {
    navigate('/order/images/status');
  };


  function yyyymmdd() {
    var x = new Date();
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
  }
  function Dates(date) {
    var x = new Date(date);
    var y = x.getFullYear().toString();
    var m = (x.getMonth() + 1).toString();
    var d = x.getDate().toString();
    (d.length === 1) && (d = '0' + d);
    (m.length === 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
  }
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  let Todaydate = yyyymmdd()

  // const columns = [
  //   { field: "id", headerName: "ID", minWidth: 100, flex: 0.4 },

  //   {
  //     field: "status",
  //     headerName: "Status",
  //     minWidth: 100,
  //     flex: 0.4,
  //     cellClassName: (params) => {
  //       return params.getValue(params.id, "status") === "Delivered"
  //         ? "greenColor"
  //         : "redColor";
  //     },
  //   },
  //   {
  //     field: "createdAt",
  //     headerName: "Date",
  //     minWidth: 170,
  //     flex: 0.4,
  //   },
  //   {
  //     field: "itemsQty",
  //     headerName: "Qty",
  //     type: "number",
  //     minWidth: 100,
  //     flex: 0.4,
  //   },

  //   {
  //     field: "amount",
  //     headerName: "Rs",
  //     type: "number",
  //     minWidth: 100,
  //     flex: 0.4,
  //   },

  //   {
  //     field: "actions",
  //     flex: 0.4,
  //     headerName: "Details",
  //     minWidth: 100,
  //     type: "number",
  //     sortable: false,
  //     renderCell: (params) => {
  //       return (
  //         <Link to={`/order/${params.getValue(params.id, "id")}`}>
  //           <LaunchIcon />
  //         </Link>
  //       );
  //     },
  //   },
  // ];


  const showOrderDateWise = (e, value) => {
    showValue = value
    if (orders && orders.length > 0) {

      if (showValue === 'month') {
        // const sortedRows = orderBy(rows, 'createdAt', sortOrder);

        // const handleSortClick = () => {
        //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        // };
        let rows_temp = [];
        orders &&
          orders.forEach((item, index) => {
            if (Dates(item.createdAt) >= Dates(firstDay) && Dates(item.createdAt) <= Dates(lastDay)) {
              rows_temp.push({
                itemsQty: item.orderItems.length,
                id: item._id,
                status: item.orderStatus,
                amount: item.totalPrice,
                user_note: item.user_note,
                createdAt: moment(item.createdAt).format("DD/MM/YY hh:mm:ss")
              });
            }
          });
        setShow('month')
        setRows(rows_temp)
      }
      else if (showValue === 'today') {
        // const sortedRows = orderBy(rows, 'createdAt', sortOrder);

        // const handleSortClick = () => {
        //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        // };
        let rows_temp = [];
        orders &&
          orders.forEach((item, index) => {
            if (Todaydate === Dates(item.createdAt)) {
              rows_temp.push({
                itemsQty: item.orderItems.length,
                id: item._id,
                status: item.orderStatus,
                amount: item.totalPrice,
                user_note: item.user_note,
                createdAt: moment(item.createdAt).format("DD/MM/YY hh:mm:ss")
              });
            }
          });
        setShow('today')
        setRows(rows_temp)
      }
      else if (showValue === 'all') {
        // const sortedRows = orderBy(rows, 'createdAt', sortOrder);

        // const handleSortClick = () => {
        //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        // };
        let rows_temp = [];
        orders &&
          orders.forEach((item, index) => {
            rows_temp.push({
              itemsQty: item.orderItems.length,
              id: item._id,
              status: item.orderStatus,
              amount: item.totalPrice,
              user_note: item.user_note,
              createdAt: moment(item.createdAt).format("DD/MM/YY hh:mm:ss")
            });
          });
        setShow('all')
        setRows(rows_temp)


      }
    }
  }
  const theme = createTheme();
  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);
  useEffect(() => {
    dispatch(myOrders());
    showOrderDateWise("", "today")
  }, []);
  useEffect(() => {
    showOrderDateWise("", show)
  }, [orders]);

  // orders &&
  //   orders.forEach((item, index) => {
  //     rows.push({
  //       itemsQty: item.orderItems.length,
  //       id: item._id,
  //       status: item.orderStatus,
  //       amount: item.totalPrice,
  //       createdAt: moment(item.createdAt).format("DD/MM/YY hh:mm:ss")
  //     });
  //   });
  const goToOrderDetails = (e, id) => {
    navigate(`/order/${id}`);
  }
  useEffect(() => {
    // showValue = 'all'
    showOrderDateWise("", "today")
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(myOrders());
  }, [dispatch, alert, error]);

  return (
    <Fragment>
      <MetaData title={`${user.name} - Orders`} />

      {loading ? (
        <Loader />
      ) : (
        <div className="productListContainer" id="list">
          <div className="awesome-phrase-container7">
            {show === "today" &&
              <p >{translator[language]["Today's Orders"]}</p>
            }
            {show === "month" &&
              <p >{translator[language]["This Month's Orders"]}</p>
            }
            {show === "all" &&
              <p>{translator[language]["All Orders"]}</p>
            }
          </div>
          {/* {resultPerPage < ordersCount && (
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={ordersCount}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />

            </div>
          )} */}
          {/* <div class="dateToggleContainer" style={{ padding: '2px', display: 'block' }}>
            <button className="dateToggle" onClick={(e) => showOrderDateWise(e, 'all')} style={{ cursor: 'pointer', fontWeight: 'bolder' }}>ALL ORDERS</button>
            <button className="dateToggle" onClick={(e) => showOrderDateWise(e, 'today')} style={{ cursor: 'pointer', fontWeight: 'bolder' }}>TODAY</button>
            <button className="dateToggle" onClick={(e) => showOrderDateWise(e, 'month')} style={{ cursor: 'pointer', fontWeight: 'bolder' }}>THIS MONTH</button>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'today')} variant="outlined" size="small">
                {/* TODAY */}
                <TodayIcon />
                {translator[language]["Today"]}
              </Button>
              <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'month')} variant="outlined" size="small">
                <CalendarMonthIcon />
                {translator[language]["Month"]}
              </Button>
              <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'all')} variant="outlined" size="small" >
                <CalendarTodayIcon />
                {translator[language]["All"]}
              </Button>
            </Stack>
          </div>
          {/* <div>
            {rows &&
              rows.map((row) => (
                <ordersTemplate key={row.id} row={row} />
              ))} */}
          {/* <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="myOrdersTable"
              autoHeight
              sx={{
                margin: '2%',
                bgcolor: '#F0F8FF',
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
              }}
            /> */}

          <Button onClick={handleSortClick}>{translator[language]["Sort by date"]} ({sortOrder === 'asc' ? 'latest' : 'oldest'})</Button>
          <div className="awesome-phrase-container8">
            <p className="awesome-phrase2">
              {translator[language]["Your Shopping Time Capsule: Remember the good old days (like last week when you bought that thing)?"]}  🛍️
            </p>
          </div>
          <Box display="flex" p={2}>
            <Box flex={1} display="flex">
              <Card onClick={handleOrderImageCardClick} variant="outlined" className={classes.card}>
                <img src={uploadOrder} alt="Guide Icon" />
                <h2 className={classes.title}>
                  {translator[language]["Check your Image Order Status"]}
                </h2>
              </Card>
            </Box>
          </Box>
          <ThemeProvider theme={theme}>
            <main style={{ overflowY: 'auto', height: '100%' }}>
              <Container sx={{ py: 3 }}>
                {/* End hero unit */}
                <Grid container spacing={4}>
                  {sortedRows.map((row) => (
                    // <Grid item key={row.id}>
                    < Grid item key={row.id} xs={12} sm={6} md={6} >
                      <Card variant="outlined"
                        sx={{
                          height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', transition: 'transform 0.2s ease-in-out'
                        }}
                      >
                        {row.status === 'Delivered' ? (
                          <Typography style={{ color: '#69f0ae', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">{row.status}</Typography>
                        ) : (
                          <Typography style={{ color: '#ff8a80', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">{row.status}</Typography>
                        )
                        }
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography style={{ display: 'flex', alignItems: 'center', color: '#01a2f5', fontWeight: 'bold', marginTop: '5%' }} gutterBottom variant="h5" component="h2">
                            <EventNoteOutlinedIcon sx={{ display: 'inline', mr: 1 }} /> {row.user_note ? row.user_note : ""}
                          </Typography>
                          <Typography style={{ display: 'flex', alignItems: 'center', color: '#002244', fontWeight: 'bold', marginTop: '5%' }} gutterBottom variant="h5" component="h2">
                            <Grid3x3OutlinedIcon sx={{ display: 'inline', mr: 1 }} />{row.id}
                          </Typography>
                          <Typography style={{ display: 'flex', alignItems: 'center', color: '#002244', marginTop: '5%' }}>
                            <AccessTimeIcon sx={{ display: 'inline', mr: 1 }} />{row.createdAt}
                          </Typography>
                          <Typography style={{ display: 'flex', alignItems: 'center', color: '#002244', marginTop: '5%' }}>
                            <FormatListNumberedOutlinedIcon sx={{ display: 'inline', mr: 1 }} />{translator[language]["Qty"]} {row.itemsQty}
                          </Typography>
                          <Typography style={{ marginTop: '10px', color: '#002244', fontWeight: 'bolder', marginTop: '5%' }}>
                            <span style={{ backgroundColor: '#e8eaf6', padding: '10px', borderRadius: '5px' }}>Rs {row.amount.toFixed(2)}</span>
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="small" onClick={(e) => goToOrderDetails(e, row.id)}>{translator[language]["View Details"]}</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </main>
          </ThemeProvider>


          {/* </div> */}


          {/* <div className="orderDetailsContainerBox">{user.name}'s Orders</div> */}
        </div>
      )
      }
    </Fragment >
  );
};

export default MyOrders;