import React from "react";
import "./Loader.css";

const Loader = () => {
  const messages = [
    "🍞 Just fetching some breadcrumbs...",
    "🕊️ Hold on, the pigeon is on its way!",
    // "🪶 Loading feathers...",
    // "🕊️ Getting ready to take flight...",
    "🌿 Finding what you need in our Nest...",
  ];
  const randomMessage = messages[Math.floor(Math.random() * messages.length)];
  return (
    <div className="loading-container">
      <img
        src="../../../../pigeon.png" // Replace with the actual path to your pigeon image
        alt="Cute Pigeon"
        className="pigeon"
      />
      <br></br>
      <br></br>
      <div className="message">
        <p className="emoji">{randomMessage}</p>
      </div>
    </div>
  );
};

export default Loader;