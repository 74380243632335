import React, { Fragment, useEffect, useState } from "react";
// import "./returnShipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveReturnShippingInfo } from "../../actions/returnCartActions";
import MetaData from "../layout/metaData";
import PinDropIcon from '@mui/icons-material/PinDrop';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import PhoneIcon from '@mui/icons-material/Phone';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { Country, State } from "country-state-city";
import { useAlert } from "react-alert";
import CheckoutSteps from "../Cart/CheckoutSteps.js";
import { useNavigate } from 'react-router-dom';
import { translator } from "../../translator"

const Shipping = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const alert = useAlert();
    const { returnShippingInfo } = useSelector((state) => state.returnCart);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    if (!returnShippingInfo.country) {

        returnShippingInfo.country = "IN"
    }
    if (!returnShippingInfo.pinCode) {

        returnShippingInfo.pinCode = 0
    }
    if (!returnShippingInfo.state) {

        returnShippingInfo.state = "State"
    }
    const [address, setAddress] = useState(returnShippingInfo.address);
    const [city, setCity] = useState(returnShippingInfo.city);
    const [state, setState] = useState(returnShippingInfo.state);
    const [country, setCountry] = useState(returnShippingInfo.country);
    const [pinCode, setPinCode] = useState(returnShippingInfo.pinCode);
    const [phoneNo, setPhoneNo] = useState(returnShippingInfo.phoneNo);

    const handlePincodeChange = (e) => {
        const newPincode = e.target.value;
        setPinCode(newPincode);
        fetchStateFromPincode(newPincode);
    };
    const fetchStateFromPincode = (pincode) => {
        fetch(`https://api.postalpincode.in/pincode/${pincode}`)
            .then((response) => response.json())
            .then((data) => {
                if (data[0].Status === "Success") {
                    setState(data[0].PostOffice[0].State);
                } else {
                    setState("");
                }
            })
            .catch((error) => {
                console.error("Error fetching state from pincode:", error);
            });
    };

    const shippingSubmit = (e) => {
        e.preventDefault();

        if (phoneNo.length < 10 || phoneNo.length > 10) {
            alert.error("Phone Number should be 10 digits Long");
            return;
        }
        dispatch(
            saveReturnShippingInfo({ address, city, state, country, pinCode, phoneNo })
        );
        navigate("/returnorder/confirm");
    };

    return (
        <Fragment>
            <MetaData title="Shipping Details" />

            <CheckoutSteps activeStep={0} />

            <div className="shippingContainer">
                <div className="shippingBox">
                    <h2 className="shippingHeading">{translator[language]["Return Shipping Details"]}</h2>

                    <form
                        className="shippingForm"
                        encType="multipart/form-data"
                        onSubmit={shippingSubmit}
                    >
                        <div>
                            <HomeIcon />
                            <input
                                type="text"
                                placeholder={translator[language]["Address"]}
                                required
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>

                        <div>
                            <LocationCityIcon />
                            <input
                                type="text"
                                placeholder={translator[language]["City"]}
                                required
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>

                        <div>
                            <PinDropIcon />
                            <input
                                type="number"
                                placeholder={translator[language]["Pin Code"]}
                                required
                                value={pinCode}
                                onChange={handlePincodeChange}
                            />
                        </div>

                        <div>
                            <PhoneIcon />
                            <input
                                type="number"
                                placeholder={translator[language]["Phone Number"]}
                                required
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                                size="10"
                            />
                        </div>

                        <div>
                            <PublicIcon />

                            <select
                                required
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                <option value="">{translator[language]["Country"]}</option>
                                {Country &&
                                    Country.getAllCountries().map((item) => (
                                        <option key={item.isoCode} value={item.isoCode}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        {country && (
                            <div>
                                <TransferWithinAStationIcon />

                                <select
                                    required
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                >
                                    <option value="">{translator[language]["State"]}</option>
                                    {State &&
                                        State.getStatesOfCountry(country).map((item) => (
                                            <option key={item.isoCode} value={item.isoCode}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}

                        <input
                            type="submit"
                            value={translator[language]["Continue"]}
                            className="shippingBtn"
                            disabled={state ? false : true}
                        />
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default Shipping;