import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  MY_ORDERS_REQUEST,
  MY_ORDERS_SUCCESS,
  MY_ORDERS_FAIL,
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  ALL_ORDERS_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  CLEAR_ERRORS,
} from "../constants/orderConstants";
import axios from "axios";
const apiHelper = require("../helpers")

// Create Order
export const createOrder = (order) => async (dispatch, getState) => {
  const { token } = getState().token
  try {
    dispatch({ type: CREATE_ORDER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      withCredentials: true,
    };
    const { data } = await axios.post(apiHelper.api + "order/new", order, config);

    dispatch({ type: CREATE_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// My Orders
export const myOrders = () => async (dispatch, getState) => {
  const { token } = getState().token
  // console.log(token)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: MY_ORDERS_REQUEST });

    const { data } = await axios.get(apiHelper.api + "orders/me", config);

    dispatch({ type: MY_ORDERS_SUCCESS, payload: data.orders });
  } catch (error) {
    dispatch({
      type: MY_ORDERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Orders (admin)
export const getAllOrders = (store) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  if (store === 'store') {
    try {
      dispatch({ type: ALL_ORDERS_REQUEST });

      const { data } = await axios.get(apiHelper.api + "store/orders", config);

      dispatch({ type: ALL_ORDERS_SUCCESS, payload: data.orders });
    } catch (error) {
      dispatch({
        type: ALL_ORDERS_FAIL,
        payload: error.response.data.message,
      });
    }
  } else {

    try {
      dispatch({ type: ALL_ORDERS_REQUEST });

      const { data } = await axios.get(apiHelper.api + "admin/orders", config);

      dispatch({ type: ALL_ORDERS_SUCCESS, payload: data.orders });
    } catch (error) {
      dispatch({
        type: ALL_ORDERS_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

// Update Order
export const updateOrder = (id, order, type_of_update) => async (dispatch, getState) => {
  const { token } = getState().token
  if (type_of_update === 'transfer_entire_order') {
    try {
      dispatch({ type: UPDATE_ORDER_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        withCredentials: true
      };
      const { data } = await axios.put(
        `${apiHelper.api}transfer/entireorder/${id}`,
        order,
        config
      );
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data.success });

    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        payload: error.response.data.message,
      });
    }
  } else {

    try {
      dispatch({ type: UPDATE_ORDER_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        withCredentials: true
      };
      if (order.status !== "Cancelled") {
        const { data } = await axios.put(
          `${apiHelper.api}admin/order/${id}`,
          order,
          config
        );
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data.success });
      } else {
        const { data } = await axios.put(
          `${apiHelper.api}orders/cancel/${id}`,
          order,
          config
        );
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data.success });
      }


    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        payload: error.response.data.message,
      });
    }
  }
};

// Delete Order
export const deleteOrder = (id) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: DELETE_ORDER_REQUEST });

    const { data } = await axios.delete(`${apiHelper.api}admin/order/${id}`, config);

    dispatch({ type: DELETE_ORDER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Order Details
export const getOrderDetails = (id) => async (dispatch, getState) => {
  const { token } = getState().token
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    },
    withCredentials: true,
  };
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    const { data } = await axios.get(`${apiHelper.api}order/${id}`, config);

    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data.order });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};