import React, { useEffect, useState } from "react";
import '../Product/categoryCarousel.css';
import { useSelector } from 'react-redux';
import { translator } from "../../translator"

export default function HighlightsCarousel() {
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);

    return (
        <div className="parentCarousel">
            <div className="carousel">
                <div style={{ border: 'none', backgroundColor: '#f3e5f5' }}>
                    <div id="highlightsCarouselContainer">
                        <h2 id="highlightsCarouselHeader">🎉 {translator[language]["Get discount up to 35%"]}</h2>
                    </div>
                </div>
                <div style={{ border: 'none', backgroundColor: '#fff3e0' }} >
                    <div id="highlightsCarouselContainer">
                        <h2 id="highlightsCarouselHeader">😊 {translator[language]["Return unused items, no questions asked!"]}</h2>
                    </div>
                </div>
                <div style={{ border: 'none', backgroundColor: '#fce4ec' }} >
                    <div id="highlightsCarouselContainer">
                        <h2 id="highlightsCarouselHeader">🛒 {translator[language]["Shop from popular brands like Finolex, Supreme, Watertec"]}</h2>
                    </div>
                </div>
            </div>
            <div className="carousel">
                <div style={{ border: 'none', backgroundColor: '#ffebee' }} >
                    <div id="highlightsCarouselContainer">
                        <h2 id="highlightsCarouselHeader">👋 {translator[language]["Cancel order anytime before it is shipped"]}</h2>
                    </div>
                </div>
                <div style={{ border: 'none', backgroundColor: '#e8f5e9' }} >
                    <div id="highlightsCarouselContainer">
                        <h2 id="highlightsCarouselHeader">🚚 {translator[language]["Delivery at your doorstep within one day."]}</h2>
                    </div>
                </div>
                <div style={{ border: 'none', backgroundColor: '#e0f7fa' }} >
                    <div id="highlightsCarouselContainer">
                        <h2 id="highlightsCarouselHeader">🤩 {translator[language]["Hassle-free shopping."]}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}
