import React from "react";
import "./sidebar.css";
import logo from "../../images/logo2.png";
import { Link, useLocation } from "react-router-dom";
// import TreeView from '@mui/lab/TreeView';
// import TreeItem from '@mui/lab/TreeItem';
// import { TreeView, TreeItem } from "@material-ui/lab";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddIcon from '@mui/icons-material/Add';
// import ImportExportIcon from '@mui/icons-material/ImportExport';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CategoryIcon from '@mui/icons-material/Category';
import PlumbingIcon from '@mui/icons-material/Plumbing';
// import CategoryIcon from '@mui/icons-material/Category';
// import RateReviewIcon from '@mui/icons-material/RateReview';


const Sidebar = () => {
  const location = useLocation();

  // Function to check if the current link is active
  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  return (
    <div className="sidebar">
      <Link style={{ border: 'none' }} to="/">
        <h1 id="sidebarheading">plumbpigen</h1>
      </Link>
      {/* <h2 className="sidebarstorename">Admin</h2> */}
      <br></br>
      <Link to="/admin/dashboard" className={isActive('/admin/dashboard') ? 'active' : ''}>
        <p>
          <DashboardIcon /> Dashboard
        </p>
      </Link>
      <h3 className="sidebarsubheading">Data</h3>
      <Link to="/admin/products" className={isActive('/admin/products') ? 'active' : ''}>
        <p>
          <PlumbingIcon />Products
        </p>
      </Link>
      <Link to="/admin/orders" className={isActive('/admin/orders') ? 'active' : ''}>
        <p>
          <ListAltIcon />
          Orders
        </p>
      </Link>
      <Link to="/admin/return" className={isActive('/admin/return') ? 'active' : ''}>
        <p>
          <KeyboardReturnIcon />
          Return Orders
        </p>
      </Link>
      <Link to="/admin/users" className={isActive('/admin/users') ? 'active' : ''}>
        <p>
          <PeopleIcon /> Users
        </p>
      </Link>
      <Link to="/admin/stores" className={isActive('/admin/stores') ? 'active' : ''}>
        <p>
          <StoreMallDirectoryIcon /> Stores
        </p>
      </Link>
      <Link to="/admin/commissions" className={isActive('/admin/commissions') ? 'active' : ''}>
        <p>
          <CurrencyRupeeIcon /> Commissions
        </p>
      </Link>
      <Link to="/admin/categories" className={isActive('/admin/categories') ? 'active' : ''}>
        <p>
          <CategoryIcon />Categories
        </p>
      </Link>
      <h3 className="sidebarsubheading">Create</h3>
      <Link to="/admin/product" className={isActive('/admin/product') ? 'active' : ''}>
        <p>
          <AddIcon /><PlumbingIcon />Add Products
        </p>
      </Link>
      <Link to="/admin/newuser" className={isActive('/admin/newuser') ? 'active' : ''}>
        <p>
          <PersonAddIcon /><StoreMallDirectoryIcon /> Add User & Store
        </p>
      </Link>
      <Link to="/admin/category/create" className={isActive('/admin/category/create') ? 'active' : ''}>
        <p>
          <AddIcon /><CategoryIcon />Add Categories
        </p>
      </Link>
      {/* <Link>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Products">
            <Link to="/admin/products">
              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
            </Link>

            <Link to="/admin/product">
              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
            </Link>
          </TreeItem>
        </TreeView>
      </Link> */}
      {/* <Link>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Categories">
            <Link to="/admin/categories">
              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
            </Link>

            <Link to="/admin/category/create">
              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
            </Link>
          </TreeItem>
        </TreeView>
      </Link> */}
      {/* <Link to="/admin/reviews">
        <p>
          <RateReviewIcon />
          Reviews
        </p>
      </Link> */}
    </div>
  );
};


export default Sidebar;