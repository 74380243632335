import React, { Fragment, useEffect, useState } from "react";
import { DataGrid, } from '@mui/x-data-grid';
import "../Admin/productList.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/metaData";
import DeleteIcon from '@mui/icons-material/Delete';
// import Sidebar from "./Sidebar";
import { getAllUsers, clearErrors, deleteUser, switchUser } from "../../actions/userAction";
import { DELETE_USER_RESET } from "../../constants/userConstant";
import { useNavigate } from 'react-router-dom';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { loadUser } from "../../actions/userAction";

const UsersListForSwitch = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const alert = useAlert();

    const { isAuthenticated, error, users } = useSelector((state) => state.allUsers);
    const { user } = useSelector((state) => state.user);

    const {
        error: deleteError,
        isDeleted,
        message,
    } = useSelector((state) => state.profile);

    const deleteUserHandler = (id) => {
        dispatch(deleteUser(id));
    };
    const switchToTargetUser = (id) => {
        dispatch(switchUser(id));
        dispatch(loadUser());
        navigate("/")
    };


    useEffect(() => {
        if (isAuthenticated) {
            navigate("/")
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors());
        }

        if (isDeleted) {
            alert.success(message);
            navigate("/admin/users");
            dispatch({ type: DELETE_USER_RESET });
        }

        dispatch(getAllUsers());
    }, [dispatch, alert, error, deleteError, navigate, isDeleted, message, isAuthenticated]);

    const columns = [
        { field: "id", headerName: "ID", minWidth: 180, flex: 0.8 },

        {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            flex: 1,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            flex: 0.5,
        },

        {
            field: "role",
            headerName: "Role",
            type: "number",
            minWidth: 150,
            flex: 0.3,
            cellClassName: (params) => {
                return params.getValue(params.id, "role") === "admin"
                    ? "greenColor"
                    : "redColor";
            },
        },

        {
            field: "actions",
            flex: 0.3,
            headerName: "Details",
            minWidth: 150,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        {user && (user.role === 'developer' || user.role === 'admin') &&
                            <Button
                                onClick={() =>
                                    switchToTargetUser(params.getValue(params.id, "id"))
                                }
                            >
                                <TuneOutlinedIcon style={{ color: '#69f0ae' }} />
                            </Button>
                        }
                        {user && user.role === 'developer' &&
                            <Button
                                onClick={() =>
                                    deleteUserHandler(params.getValue(params.id, "id"))
                                }
                            >
                                <DeleteIcon style={{ color: '#ff8a80' }} />
                            </Button>
                        }
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    users &&
        users.forEach((item) => {
            rows.push({
                id: item._id,
                role: item.role,
                email: item.email,
                name: item.name,
            });
        });
    return (
        <Fragment>
            <MetaData title={`ALL USERS - Admin`} />
            <div className="dashboard">
                {/* <div className="productListContainer"> */}
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    className="myOrdersTable"
                    autoHeight
                    sx={{
                        margin: '2%',
                        // bgcolor: '#002244',
                        boxShadow: 2,

                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                    }}
                />
                {/* </div> */}
            </div>
        </Fragment>
    );
};

export default UsersListForSwitch;