import React from "react";
// import "./commissionSidebar.css";
import logo from "../../images/logo2.png";
import { Link, useLocation } from "react-router-dom";
// import TreeView from '@mui/lab/TreeView';
// import TreeItem from '@mui/lab/TreeItem';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import RateReviewIcon from '@mui/icons-material/RateReview';

const CommissionSidebar = () => {
  const location = useLocation();

  // Function to check if the current link is active
  const isActive = (pathname) => {
    return location.pathname === pathname;
  };
  return (
    <div className="sidebar">
      <Link style={{ border: 'none' }} to="/">
        <h1 style={{ color: '#01a2f5' }}>plumbpigen</h1>
      </Link>
      <Link to="/commission/dashboard" className={isActive('/commission/dashboard') ? 'active' : ''}>
        <p>
          <DashboardIcon /> Dashboard
        </p>
      </Link>
      <Link to="/commission/details" className={isActive('/commission/details') ? 'active' : ''}>
        <p>
          <CurrencyRupeeIcon /> Earnings
        </p>
      </Link>
      <Link to="/commission/request" className={isActive('/commission/request') ? 'active' : ''}>
        <p>
          <RequestPageIcon /> Request Commissions
        </p>
      </Link>
      {/* <Link>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Products">
            <Link to="/admin/products">
              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
            </Link>

            <Link to="/admin/product">
              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
            </Link>
          </TreeItem>
        </TreeView>
      </Link> */}
      <Link to="/orders/me">
        <p>
          <ListAltIcon />
          Your Orders
        </p>
      </Link>
      {/* <Link to="/admin/users">
        <p>
          <PeopleIcon /> Users
        </p>
      </Link> */}
      {/* <Link to="/admin/reviews">
        <p>
          <RateReviewIcon />
          Reviews
        </p>
      </Link> */}
    </div>
  );
};

export default CommissionSidebar;