import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  newProductReducer,
  newReviewReducer,
  productDetailsReducer,
  productReducer,
  productReviewsReducer,
  productsReducer,
  reviewReducer,
} from "./reducer/productReducer";
import { cartReducer } from "./reducer/cartReducer";
import { returnCartReducer } from "./reducer/returnCartReducer";
import { rejectItemListReducer } from "./reducer/rejectItemListReducer";
import { transferOrderItemWiseCartReducer } from "./reducer/transferOrderItemWiseCartReducer";
import {
  newCommissionReducer,
  allCommissionsReducer,
  myCommissionsReducer,
  commissionReducer,
  commissionDetailsReducer
} from "./reducer/commissionReducer";
import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducer/userReducer";
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderReducer,
} from "./reducer/orderReducer";
import {
  allCategoriesReducer,
  categoryReducer,
  categoryDetailsReducer,
  createCategoryReducer,
} from "./reducer/categoryReducer";
import {
  newStoreReducer,
  storeReducer,
  storeProductsReducer,
  storeProductDetailsReducer,
  storeProductReducer,
  storeDetailsReducer,
  allstoresReducer
} from "./reducer/storeReducer";
import {
  allReturnOrdersReducer,
  myReturnOrdersReducer,
  newReturnOrderReducer,
  returnOrderDetailsReducer,
  returnOrderReducer,
} from "./reducer/returnReducer";
import {
  apiReducer,
  storeTypesReducer
} from "./reducer/utilsReducer.js";
import TokenSlice from "./slices/TokenSlice";

const reducer = combineReducers({
  token: TokenSlice,
  products: productsReducer,
  storeProducts: storeProductsReducer,
  productDetails: productDetailsReducer,
  storeProductDetails: storeProductDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  returnCart: returnCartReducer,
  rejectItemList: rejectItemListReducer,
  transferOrderItemWiseCart: transferOrderItemWiseCartReducer,
  newCommission: newCommissionReducer,
  allCommissions: allCommissionsReducer,
  newOrder: newOrderReducer,
  newCategory: createCategoryReducer,
  newStore: newStoreReducer,
  newReturnOrder: newReturnOrderReducer,
  myOrders: myOrdersReducer,
  myCommissions: myCommissionsReducer,
  myReturnOrders: myReturnOrdersReducer,
  orderDetails: orderDetailsReducer,
  categoryDetails: categoryDetailsReducer,
  commissionDetails: commissionDetailsReducer,
  returnOrderDetails: returnOrderDetailsReducer,
  newReview: newReviewReducer,
  newProduct: newProductReducer,
  product: productReducer,
  storeProduct: storeProductReducer,
  allOrders: allOrdersReducer,
  allCategories: allCategoriesReducer,
  allReturnOrders: allReturnOrdersReducer,
  order: orderReducer,
  category: categoryReducer,
  store: storeReducer,
  commission: commissionReducer,
  return: returnOrderReducer,
  allUsers: allUsersReducer,
  allStores: allstoresReducer,
  userDetails: userDetailsReducer,
  storeDetails: storeDetailsReducer,
  productReviews: productReviewsReducer,
  review: reviewReducer,
  api: apiReducer,
  store_type_array: storeTypesReducer
  // myCart:myCartReducer
});
// console.log(JSON.parse(localStorage.getItem("returnCartItems")), '    console.log(localStorage.getItem("returnCartItems"))')
let initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
  },

  returnCart: {
    returnCartItems: localStorage.getItem("returnCartItems")
      ? JSON.parse(localStorage.getItem("returnCartItems"))
      : [],
    returnShippingInfo: localStorage.getItem("returnShippingInfo")
      ? JSON.parse(localStorage.getItem("returnShippingInfo"))
      : {},
  },

  rejectItemList: {
    rejectCartItems: localStorage.getItem("rejectCartItems")
      ? JSON.parse(localStorage.getItem("rejectCartItems"))
      : [],
    // returnShippingInfo: localStorage.getItem("returnShippingInfo")
    //   ? JSON.parse(localStorage.getItem("returnShippingInfo"))
    //   : {},
  },
  transferOrderItemWiseCart: {
    transferOrderItemWiseCartItems: localStorage.getItem("transferOrderItemWiseCartItems")
      ? JSON.parse(localStorage.getItem("transferOrderItemWiseCartItems"))
      : [],
    transferOrderItemWiseStoreInfo: localStorage.getItem("transferOrderItemWiseStoreInfo")
      ? JSON.parse(localStorage.getItem("transferOrderItemWiseStoreInfo"))
      : {},
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;