import {
    CREATE_RETURN_ORDER_REQUEST,
    CREATE_RETURN_ORDER_SUCCESS,
    CREATE_RETURN_ORDER_FAIL,
    MY_RETURN_ORDERS_REQUEST,
    MY_RETURN_ORDERS_SUCCESS,
    MY_RETURN_ORDERS_FAIL,
    ALL_RETURN_ORDERS_REQUEST,
    ALL_RETURN_ORDERS_SUCCESS,
    ALL_RETURN_ORDERS_FAIL,
    UPDATE_RETURN_ORDER_REQUEST,
    UPDATE_RETURN_ORDER_SUCCESS,
    UPDATE_RETURN_ORDER_FAIL,
    DELETE_RETURN_ORDER_REQUEST,
    DELETE_RETURN_ORDER_SUCCESS,
    DELETE_RETURN_ORDER_FAIL,
    RETURN_ORDER_DETAILS_REQUEST,
    RETURN_ORDER_DETAILS_SUCCESS,
    RETURN_ORDER_DETAILS_FAIL,
    CLEAR_RETURN_ERRORS,
} from "../constants/returnConstants";

import axios from "axios";
const apiHelper = require("../helpers")

// Create Return Order
export const createReturnOrder = (returns) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: CREATE_RETURN_ORDER_REQUEST });
        const { data } = await axios.post(apiHelper.api + "return/new", returns, config);

        dispatch({ type: CREATE_RETURN_ORDER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CREATE_RETURN_ORDER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// My Return Orders
export const myReturnOrders = () => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: MY_RETURN_ORDERS_REQUEST });
        const { data } = await axios.get(apiHelper.api + "returns/me", config);

        dispatch({ type: MY_RETURN_ORDERS_SUCCESS, payload: data.returns });
    } catch (error) {
        dispatch({
            type: MY_RETURN_ORDERS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Return Orders (admin)
export const getAllReturnOrders = (store) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    if (store === 'store') {
        try {
            dispatch({ type: ALL_RETURN_ORDERS_REQUEST });

            const { data } = await axios.get(apiHelper.api + "store/return", config);

            dispatch({ type: ALL_RETURN_ORDERS_SUCCESS, payload: data.returns });
        } catch (error) {
            dispatch({
                type: ALL_RETURN_ORDERS_FAIL,
                payload: error.response.data.message,
            });
        }
    } else {
        try {
            dispatch({ type: ALL_RETURN_ORDERS_REQUEST });

            const { data } = await axios.get(apiHelper.api + "admin/return", config);

            dispatch({ type: ALL_RETURN_ORDERS_SUCCESS, payload: data.returns });
        } catch (error) {
            dispatch({
                type: ALL_RETURN_ORDERS_FAIL,
                payload: error.response.data.message,
            });
        }
    }

};

// Update Return Order
export const updateReturnOrder = (id, returns) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: UPDATE_RETURN_ORDER_REQUEST });

        if (returns.status !== "ReturnCancelled") {
            const { data } = await axios.put(
                `/api/v1/admin/return/${id}`,
                returns,
                config
            );
            dispatch({ type: UPDATE_RETURN_ORDER_SUCCESS, payload: data.success });
        } else {
            const { data } = await axios.put(
                `${apiHelper.api}return/cancel/${id}`,
                returns,
                config
            );
            dispatch({ type: UPDATE_RETURN_ORDER_SUCCESS, payload: data.success });
        }


    } catch (error) {
        dispatch({
            type: UPDATE_RETURN_ORDER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Delete Return Order
export const deleteReturnOrder = (id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: DELETE_RETURN_ORDER_REQUEST });

        const { data } = await axios.delete(`${apiHelper.api}admin/return/${id}`, config);

        dispatch({ type: DELETE_RETURN_ORDER_SUCCESS, payload: data.success });
    } catch (error) {
        dispatch({
            type: DELETE_RETURN_ORDER_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Return Order Details
export const getReturnOrderDetails = (id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: RETURN_ORDER_DETAILS_REQUEST });
        const { data } = await axios.get(`${apiHelper.api}return/${id}`, config);

        dispatch({ type: RETURN_ORDER_DETAILS_SUCCESS, payload: data.returns });
    } catch (error) {
        dispatch({
            type: RETURN_ORDER_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clearing Return Errors
export const clearReturnErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_RETURN_ERRORS });
};