import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from 'react-redux';
import MetaData from "../layout/metaData";
import { useNavigate } from 'react-router-dom';
import "./Search.css";
import InputBase from "@mui/material/InputBase";
import { Box, IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { translator } from "../../translator"
// import SearchIcon from '@mui/icons-material/Search';

const Search = () => {
  const escapeRegex = (string) => {
    const uppercaseString = string.toUpperCase();
    const escapedString = uppercaseString.replace(/[.%*+?^${}()|[\]\\]/g, '\\$&');
    return escapedString;
  }
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };

  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);
  let navigate = useNavigate();
  let [keyword, setKeyword] = useState('');
  keyword = escapeRegex(keyword)
  if (keyword) {
    navigate('/products', { state: { searchKeyword: keyword } });
  }
  const searchSubmitHandler = (e) => {
    navigate('/products', { state: { searchKeyword: keyword } });
    // e.preventDefault();
    // if (keyword.trim()) {
    //   navigate(`/products/${encodeURIComponent(keyword)}`);
    // } else {
    //   navigate("/products");
    // }
  };

  return (
    <Fragment>
      <MetaData title="plumbpigen" />
      <Box display="flex" justifyContent="space-between" p={2}>
        <Box display="flex" backgroundColor="#F7F7F7" borderRadius="3px">
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder={translator[language]["Search a Product ..."]}
            value={keyword} // Set the value to the state variable 'keyword'
            onChange={(e) => setKeyword(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
      {/* <form className="search-form" onSubmit={searchSubmitHandler}>
        <input
          type="text"
          placeholder="Search a Product ..."
          onChange={(e) => setKeyword(e.target.value)}
        />
      </form> */}
    </Fragment>
  );
};

export default Search;