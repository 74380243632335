import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import "../layout/navBar.css";
import { translator } from "../../translator"
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import axios from 'axios';
const apiHelper = require("../../helpers")
// import FavoriteIcon from '@mui/icons-material/Favorite';

const OrderBottomNotification = () => {
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");
    const [order, setOrder] = useState(null);
    const { token } = useSelector((state) => state.token);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(apiHelper.api + 'latestOrder', config);
            if (response.data.success) {
                setOrder(response.data.order_latest);
            }

        } catch (error) {
            // Handle error
            console.log(error);
        }
    };
    useEffect(() => {
        if (user && isAuthenticated) {
            fetchData()
        }
        setUserLanguage();
    }, [isAuthenticated]);
    return (
        user && isAuthenticated && order && order.length > 0 && order[0] && order[0].orderStatus === 'Processing' ? (
            <Link className="carouselLink" to={`/order/${order[0]._id}`}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#ffe0b2', position: 'fixed', bottom: 78, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ShoppingBagOutlinedIcon style={{ color: '#002244', marginRight: '10px' }} />
                    <div>
                        <p style={{ color: '#fb8c00', textAlign: 'center', fontWeight: 'bolder' }}>{translator[language]["Your order is being packed"]}</p>
                    </div>
                </div>
            </Link>
        ) : (
            user && isAuthenticated && order && order.length > 0 && order[0] && order[0].orderStatus === 'Shipped' ? (
                <Link className="carouselLink" to={`/order/${order[0]._id}`}>
                    <div style={{ paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#b9f6ca', position: 'fixed', bottom: 78, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LocalShippingOutlinedIcon style={{ color: '#002244', marginRight: '10px' }} />
                        <div>
                            <p style={{ color: '#00c853', textAlign: 'center', fontWeight: 'bolder' }}>{translator[language]["Your order is on the way and will be delivered soon"]}</p>
                        </div>
                    </div>
                </Link>
            ) : ('')
        )
    );
}
export default OrderBottomNotification;