import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/metaData";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Sidebar from "./Sidebar";
import {
  getOrderDetails,
  clearErrors,
  updateOrder,
} from "../../actions/orderAction";
import { useSelector, useDispatch } from "react-redux";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button } from "@material-ui/core";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import "./processOrder.css";
import { useParams } from 'react-router-dom'
import { createCommission, clearCommissionErrors } from "../../actions/commissionAction";
import { getAllUsers } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { getAllStores, getStoreDetails, clearStoreErrors } from "../../actions/storeAction";
import { addItemsToTransferOrderItemWiseCart, saveTransferOrderItemWiseStoreInfo } from "../../actions/TransferOrderItemWiseCartAction";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Stack from '@mui/material/Stack';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const ProcessOrder = () => {
  let navigate = useNavigate();
  const { id } = useParams()
  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);
  const { users } = useSelector((state) => state.allUsers);
  const { stores } = useSelector((state) => state.allStores);
  const { store } = useSelector((state) => state.storeDetails);
  let userList = []
  let storeId = ""
  if (order) {
    for (let user of users) {
      if (order.store && user.store_id === order.deliveredByStore.store_id) {
        userList.push(user)
      }
    }
  }

  if (order && order.store) {
    storeId = order.store.id
  }

  const transferOrderItemWise = (e) => {
    if (store_id && deliverByStoreId) {

      e.preventDefault();
      dispatch(
        saveTransferOrderItemWiseStoreInfo({ store_id, deliverByStoreId })
      );
      for (let i of order.orderItems) {
        dispatch(addItemsToTransferOrderItemWiseCart(i.product, i.quantity, i._id));
      }
      alert.success("Item Added To Transfer Order Cart");
      navigate(`/transfer/order/itemwise/${id}`);
    } else {
      alert.error("Pleaase select store details ");
    }
  }
  const transferEntireOrder = (e) => {
    if (store_id && deliverByStoreId) {

      e.preventDefault();

      const myForm = new FormData();

      myForm.set("store_id", store_id);
      myForm.set("deliverByStoreId", deliverByStoreId);

      dispatch(updateOrder(id, myForm, "transfer_entire_order"));
    } else {
      alert.error("Please select store details ");
    }
  }

  const updateOrderSubmitHandler = (e) => {

    e.preventDefault();


    const myForm = new FormData();

    myForm.set("status", status);
    myForm.set("deliveryBy", deliveryBy);
    myForm.set("paymentStatus", paymentStatus);
    myForm.set("paymentMode", paymentMode);

    if (status === "Delivered") {
      if (paymentStatus === "PAID") {
        dispatch(updateOrder(id, myForm, 'change_order_status'));

      } else {
        alert.error("Payment should be completed to make status Delivered!");
      }
    }
    if (status === "Shipped") {
      dispatch(updateOrder(id, myForm, 'change_order_status'));
    }


    if (status === 'Delivered') {
      let pipe_price = 0
      let pipe_commission = 0
      if (paymentStatus === "PAID") {
        for (let item of order.orderItems) {
          if (item && item.type && (item.type === "PIPE")) {
            pipe_price = pipe_price + item.price
            pipe_commission = pipe_commission + item.quantity
          }
        }
        const itemTotalPrice = (order.totalPrice) - (order.taxPrice + order.shippingPrice + pipe_price)
        // const itemTotalPrice = (order.totalPrice + pipe_commission) - (order.taxPrice + order.shippingPrice + pipe_price)

        const commission = {
          commissionAmount: ((itemTotalPrice * 0.10) + pipe_commission).toFixed(2),
          // commissionAmount: (itemTotalPrice * 0.10).toFixed(2),
          orderId: order._id,
          user_id: order.user
        };

        dispatch(createCommission(commission));
      }
    }

  };

  const dispatch = useDispatch();
  const alert = useAlert();

  const [status, setStatus] = useState("");
  const [deliveryBy, setDeliveryBy] = useState("");
  const [store_id, setStore_id] = useState("");
  const [deliverByStoreId, setDeliverByStoreId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  useEffect(() => {
    if (order && order.store) {

      dispatch(getStoreDetails(order.store.id, 'for_admin_order_dashboard'))
    }
  }, [order])

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
      dispatch(clearStoreErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
      dispatch(clearStoreErrors());
      dispatch(clearCommissionErrors());
    }
    if (isUpdated) {
      alert.success("Order Updated Successfully");
      dispatch({ type: UPDATE_ORDER_RESET });
    }

    dispatch(getOrderDetails(id));
    dispatch(getAllUsers());
    dispatch(getAllStores());
    // if (order && order.store) {

    //   dispatch(getStoreDetails(order.store.id, 'for_admin_order_dashboard'))
    // }
  }, [dispatch, alert, error, id, isUpdated, updateError]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <Fragment>
      <MetaData title="Process Order" />
      <div className="dashboard" id="processOrder">
        <div className="sidebartoggle">
          <div className={isSidebarOpen ? '' : 'opensidebar'} >
            <Sidebar />
          </div>
          <MenuOutlinedIcon onClick={toggleSidebar} />
        </div>
        <div className="newProductContainer">
          {loading ? (
            <Loader />
          ) : (
            <div
              className="confirmOrderPage"
              style={{
                display: order && order.orderStatus === "Delivered" ? "block" : "grid",
              }}
            >
              <div className="orderDetailsPage">
                <div className="order-summary-container">
                  <header>Order Details</header>
                  <div className="order-summary">
                    <div className="row">
                      <div className="col">
                        <div className="col">
                          <p className="label">Placed At </p><p className="value">{order && order.store && order.store.store_name}</p>
                        </div>
                        <div className="col">
                          <p className="label">Delivery By </p> <p className="value">{order && order.deliveredByStore && order.deliveredByStore.store_name && order.deliveredByStore.store_name}</p>
                        </div>

                        {/* <div>
                      <p></p>
                      <span>
                        {order && order.shippingInfo &&
                          `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                      </span>
                    </div> */}
                      </div>
                    </div>
                    <div className="row">
                      <p className="label">Shipping Info</p>
                      <div className="col">
                        <p className="value">{order && order.user && order.user.name}</p>
                      </div>
                      <div className="col">
                        <p className="value">
                          {order && order.shippingInfo && order.shippingInfo.phoneNo}
                        </p>
                      </div>
                      <div className="col">
                        <p className="value">
                          {order && order.shippingInfo &&
                            `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <p className="label">Payment Status</p>
                      <div className="col">
                        <p className="value">{order && order.paymentInfo && order.paymentInfo.status}</p>
                      </div>
                      <div className="col">
                        <p className="label">Payment Mode</p>
                        <p className="value">{order && order.paymentInfo && order.paymentInfo.paymentMode}</p>
                      </div>
                      <div className="col">
                        <p className="label">Amount</p>
                        <p className="valueMoney"><span>Rs {order && order.totalPrice && order.totalPrice.toFixed(2)}</span></p>
                      </div>
                    </div>
                    <div className="row">
                      <p className="label">Order Status</p>
                      <div className="col">
                        <p className="value">{order && order.orderStatus && order.orderStatus}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="awesome-phrase-container3">
                  <h1 style={{ fontWeight: 'bolder' }}>Order Items:</h1>
                  <div className="orderDetailsCartItemsContainer">
                    {order && order.orderItems &&
                      order.orderItems.map((item) => (
                        <div key={item.product}>
                          <img src={item.image} alt="Product" />
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>{" "}
                          <span>
                            {item.quantity} X ₹{item.price} ={" "}
                            <b>₹{item.price * item.quantity}</b>
                          </span>
                        </div>
                      ))}
                  </div>
                </div> */}
                <div className="awesome-phrase-container3">
                  <div className="orderDetailsCartItemsContainer">
                    <h1 style={{ fontWeight: 'bolder' }}>Order Items</h1>
                    {order && order.orderItems &&
                      order.orderItems.map((item) => {
                        if (Object.keys(store).length > 0) {
                          const product = store.products.find((p) => p.product_id === item.product);
                          return (
                            <div key={item.product}>
                              <img src={item.image} alt="Product" />
                              <Link to={`/product/${item.product}`}>{item.name}</Link>{" "}
                              <span style={{ marginRight: '15px' }}>
                                <b>{product.product_brand}</b>{" "}
                              </span>
                              <span>
                                {item.quantity} X ₹{item.price} = <b>₹{item.price * item.quantity}</b>
                              </span>
                              {order.orderStatus !== "Delivered" &&
                                product.product_stock === 0 ? (

                                <span style={{ color: 'tomato' }}>Stock: {product && product.product_stock}</span>
                              ) : (
                                <span style={{ color: '#32de84' }}>Stock: {product && product.product_stock}</span>
                              )

                              }
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
              {/*  */}
              <div
                style={{
                  display: order && order.orderStatus === "Delivered" ? "none" : "block",
                }}
              >
                <form
                  className="updateOrderForm"
                  onSubmit={updateOrderSubmitHandler}
                >
                  <h1>Process Order</h1>

                  <div>
                    <AccountTreeIcon />
                    <select onChange={(e) => setStatus(e.target.value)}>
                      <option value="">Choose Status</option>
                      {order && order.orderStatus === "Processing" && (
                        <option value="Shipped">Shipped</option>
                      )}

                      {order && order.orderStatus === "Shipped" && (
                        <option value="Delivered">Delivered</option>
                      )}
                    </select>

                  </div>
                  {order && order.orderStatus === "Shipped" &&
                    <h1>Delivered By</h1>
                  }
                  {order && order.orderStatus === "Shipped" &&
                    <div>
                      <LocalShippingIcon />
                      <select onChange={(e) => setDeliveryBy(e.target.value)}>
                        <option value="">Choose Delivery Partner</option>
                        {order && order.orderStatus === "Shipped" &&
                          userList.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  }
                  {order && order.orderStatus === "Shipped" &&
                    <h1>Payment Status</h1>
                  }
                  {order && order.orderStatus === "Shipped" &&
                    <div>
                      <CreditScoreIcon />
                      <select onChange={(e) => setPaymentStatus(e.target.value)}>
                        <option value="">Payment Status</option>
                        {order && order.orderStatus === "Shipped" &&
                          <option value="PAID">PAID</option>
                        }
                        {order && order.orderStatus === "Shipped" &&
                          <option value="NOT PAID">NOT PAID</option>
                        }
                      </select>
                    </div>
                  }
                  {order && order.orderStatus === "Shipped" &&
                    <h1>Payment Mode</h1>
                  }
                  {order && order.orderStatus === "Shipped" &&
                    <div>
                      <CurrencyRupeeIcon />
                      <select onChange={(e) => setPaymentMode(e.target.value)}>
                        <option value="">Payment Mode</option>
                        {order && order.orderStatus === "Shipped" &&
                          <option value="cod">Cash On Delivery</option>
                        }
                        {order && order.orderStatus === "Shipped" &&
                          <option value="spod">Scan And Pay On Delivery</option>
                        }
                      </select>
                    </div>
                  }
                  <div className="card-buttons">
                    <Button
                      className="card-button"
                      type="submit"
                      variant="outlined" size="small" style={{ color: '#002244', backgroundColor: '#69f0ae', padding: '10px' }}
                      disabled={
                        loading ? true : false || status === "" ? true : false
                      }
                    >
                      Process
                    </Button>
                  </div>

                </form>
                {/* {(!order.transfered_from && !order.transfered_to) && */}
                {order && order.orderStatus === "Processing" &&
                  <div className="updateOrderForm">
                    <h1 style={{ marginTop: '-20%' }}>Transfer Order</h1>
                    <div>
                      <StoreMallDirectoryIcon />
                      <select onChange={(e) => setStore_id(e.target.value)}>
                        <option value="">Store Name to which order is to be transfered</option>
                        {order && order.orderStatus === "Processing" &&
                          stores.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <LocalShippingIcon />
                      <select onChange={(e) => setDeliverByStoreId(e.target.value)}>
                        <option value="">Store Name by which the order will be delivered</option>
                        {order && order.orderStatus === "Processing" &&
                          stores.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="card-buttons">
                      <Stack direction="row" spacing={1}>
                        <Button
                          className="card-button"
                          onClick={(e) => transferEntireOrder(e)}
                          variant="outlined" size="small" style={{ color: '#002244', backgroundColor: '#69f0ae', padding: '10px' }}
                        >
                          {/* Transfer Entire Order */}
                          <MoveUpIcon />Entire
                        </Button>
                        <Button
                          className="card-button"
                          onClick={(e) => transferOrderItemWise(e)}
                          variant="outlined" size="small" style={{ color: '#002244', backgroundColor: '#69f0ae', padding: '10px' }}
                        >
                          <MoveUpIcon />Item Wise
                        </Button>
                      </Stack>
                    </div>
                  </div>
                }
                {/* } */}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment >
  );
};

export default ProcessOrder;