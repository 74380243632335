import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
// import DiscountIcon from '@mui/icons-material/Discount';
import guide from '../../images/assets/guideBookOutlined.png'
import earnings from '../../images/assets/earningsOutlined.png'
import returnOrder from '../../images/assets/returnOrderOutlined.png'
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { translator } from "../../translator"

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.8),
        borderRadius: theme.spacing(1),
        // backgroundColor: '#F7F7F7',
        margin: '3px',
        flex: 1,
    },
    icon: {
        fontSize: 30,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: '#002f5e'
    },
    description: {
        fontSize: 16,
        textAlign: 'center',
    },
}));

const TopbarCard = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const handleGuideCardClick = () => {
        navigate('/guide/selectlanguage');
    };
    const handleEarningsCardClick = () => {
        navigate('/commission/dashboard');
    };
    const handleReturnOrderCardClick = () => {
        navigate('/returnorders');
    };
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    return (
        <Box display="flex" p={2}>
            <Box flex={1} display="flex">
                <Card onClick={handleGuideCardClick} variant="outlined" className={classes.card}>
                    {/* <StarIcon className={classes.icon} /> */}
                    {/* <DiscountIcon /> */}
                    <img src={guide} alt="Guide Icon" />
                    <h2 className={classes.title}>
                        {translator[language]["Guide"]}
                    </h2>
                </Card>
            </Box>
            <Box flex={1} display="flex">
                <Card onClick={handleReturnOrderCardClick} variant="outlined" className={classes.card}>
                    {/* <StarIcon className={classes.icon} /> */}
                    {/* <DiscountIcon /> */}
                    <img src={returnOrder} alt="return order Icon" />
                    <h2 className={classes.title}>
                        {translator[language]["Return"]}
                    </h2>
                </Card>
            </Box>
            {user && (user.role === 'user' || user.role === 'developer' || user.role === 'admin') &&
                < Box flex={1} display="flex">
                    <Card onClick={handleEarningsCardClick} variant="outlined" className={classes.card}>
                        {/* <StarIcon className={classes.icon} /> */}
                        {/* <DiscountIcon /> */}
                        <img src={earnings} alt="earnings Icon" />
                        <h2 className={classes.title}>
                            {translator[language]["Earnings"]}
                        </h2>
                    </Card>
                </Box>
            }
        </Box>
    );
};

export default TopbarCard;
