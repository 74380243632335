let store_type = [
    "base",
    "distributor",
    "branch",
    "3p",
    "plumber"
]
let api = "https://plumbpigen.com/api/v1/"

export const apiReducer = (state = { api: api }, action) => {
    return {
        ...state,
        loading: true,
    };
}
export const storeTypesReducer = (state = { store_type_array: store_type }, action) => {

    return {
        ...state,
        loading: true,
    };
}