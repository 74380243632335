import React, { useEffect, Fragment } from "react";
// import CheckoutSteps from "../ReturnOrder/ReturnCheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/metaData";
// import "./confirmtransferOrderItemWise.css";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import { createOrder, clearErrors } from "../../actions/orderAction";

const ConfirmTransferOrderItemWise = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { transferOrderItemWiseStoreInfo, transferOrderItemWiseCartItems } = useSelector((state) => state.transferOrderItemWiseCart);
    // const { user } = useSelector((state) => state.user);
    const { order } = useSelector((state) => state.orderDetails);
    const { error } = useSelector((state) => state.newReturnOrder);
    const subtotal = transferOrderItemWiseCartItems.reduce(
        (acc, item) => acc + item.quantity * item.price,
        0
    );
    const shippingCharges = subtotal < 500 ? 0 : 50;

    const tax = subtotal * 0;

    const totalPrice = subtotal + tax + shippingCharges;

    // const address = `${transferOrderItemWiseStoreInfo.address}, ${transferOrderItemWiseStoreInfo.city}, ${transferOrderItemWiseStoreInfo.state}, ${transferOrderItemWiseStoreInfo.pinCode}, ${transferOrderItemWiseStoreInfo.country}`;
    const proceedToPayment = () => {
        const data = {
            subtotal,
            shippingCharges,
            tax,
            totalPrice,
        };

        sessionStorage.setItem("transferOrderItemWiseInfo", JSON.stringify(data));

        // let order_Ids = null
        // for (let i of transferOrderItemWiseCartItems) {
        //     if (i.order_id) {
        //         order_Ids = i.order_id
        //     }
        // }
        const orders = {
            transferOrderItemWiseStoreInfo,
            orderItems: transferOrderItemWiseCartItems,
            itemsPrice: subtotal,
            taxPrice: tax,
            shippingPrice: shippingCharges,
            totalPrice: totalPrice,
            order_id: order._id,
            user_id: order.user
        }

        dispatch(createOrder(orders));
        localStorage.clear()
        navigate("/admin/orders");

    };
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, error]);

    return (
        <Fragment>
            <MetaData title="Confirm Order" />
            {/* <CheckoutSteps activeStep={1} /> */}
            <div className="confirmOrderPage">
                <div>
                    <div className="confirmshippingArea">
                        {/* <Typography>Return Shipping Info</Typography> */}
                        {/* <div className="confirmshippingAreaBox">
                            <div>
                                <p>Name:</p>
                                <span>{user.name}</span>
                            </div>
                            <div>
                                <p>Phone:</p>
                                <span>{transferOrderItemWiseStoreInfo.phoneNo}</span>
                            </div>
                            <div>
                                <p>Address:</p>
                                <span>{address}</span>
                            </div>
                        </div> */}
                    </div>
                    <div className="confirmCartItems">
                        <Typography>Items To Be Transfered:</Typography>
                        <div className="confirmCartItemsContainer">
                            {transferOrderItemWiseCartItems &&
                                transferOrderItemWiseCartItems.map((item) => (
                                    <div key={item.product}>
                                        <img src={item.image} alt="Product" />
                                        <Link to={`/product/${item.product}`}>
                                            {item.name}
                                        </Link>{" "}
                                        <span>
                                            {item.quantity} X ₹{item.price} ={" "}
                                            <b>₹{item.price * item.quantity}</b>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {/*  */}
                <div>
                    <div className="orderSummary">
                        <Typography>Order Summery</Typography>
                        <div>
                            <div>
                                <p>Subtotal:</p>
                                <span>₹{subtotal}</span>
                            </div>
                            <div>
                                <p>Shipping Charges:</p>
                                <span>₹{shippingCharges}</span>
                            </div>
                        </div>

                        <div className="orderSummaryTotal">
                            <p>
                                <b>Total:</b>
                            </p>
                            <span>₹{totalPrice}</span>
                        </div>

                        <button onClick={proceedToPayment}>Transfer Order</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ConfirmTransferOrderItemWise;