// import React, { Component, useEffect, useState } from "react";
// import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";
// import './categoryCarousel.css'
// import plumbingCPVC from '../../images/assets/plumbingCPVC.png'
// import plumbingAPVC from '../../images/assets/plumbingAPVC.png'
// import plumbingPVC from '../../images/assets/plumbingPVC.png'
// import plumbingSWR from '../../images/assets/plumbingSWR.png'
// import plumbingGI from '../../images/assets/plumbingGI.png'
// import plumbingTaps from '../../images/assets/plumbingTaps.png'
// import plumbingSink from '../../images/assets/plumbingSink.png'
// import plumbingTank from '../../images/assets/plumbingTank.png'
// const translator = require("../../translator");

// export default class MultipleItems extends Component {
//     render() {
//         const settings = {
//             // dots: true,
//             infinite: true,
//             speed: 500,
//             slidesToShow: 1,
//             slidesToScroll: 1,
//             autoplay: true,
//             autoplaySpeed: 2000,
//         };

//         const categoryData = { category: 'UPVC' };

//         const { isAuthenticated, user } = useSelector((state) => state.user);
//         const [language, setLanguage] = useState("english");

//         const setUserLanguage = () => {
//             if (isAuthenticated && user && user.language) {
//                 setLanguage(user.language);
//             }
//         };

//         useEffect(() => {
//             setUserLanguage();
//         }, [isAuthenticated]);

//         return (
//             <div className="carousel">
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/APVC',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingAPVC} alt={"APVC"} />
//                             <h1 className="carouselHeader"> {translator[language]["APVC"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                     {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>1</h3> */}
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/PVC',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingPVC} alt={"PVC"} />
//                             <h1 className="carouselHeader"> {translator[language]["PVC"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     {/* <div style={{ border: '1px solid rgb(112, 112, 112)' }}> */}
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/CPVC',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             {/* <img src={CPVC} alt={"CPVC"} /> */}
//                             <span><img src={plumbingCPVC} alt="CPVC" /><h1 className="carouselHeader"> {translator[language]["CPVC"]}</h1></span>

//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/SWR',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingSWR} alt={"SWR"} />
//                             <h1 className="carouselHeader"> {translator[language]["SWR"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/GI',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingGI} alt={"GI"} />
//                             <h1 className="carouselHeader"> {translator[language]["GI"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     {/* <img style={{ textAlign: "center" }} src={CP}></img> */}
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/TAPS',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingTaps} alt={"Taps"} />
//                             <h1 className="carouselHeader"> {translator[language]["TAPS & ACCESSORIES"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                     {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>5</h3> */}
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     {/* <img style={{ textAlign: "center" }} src={CP}></img> */}
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/SINK',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingSink} alt={"Sink"} />
//                             <h1 className="carouselHeader"> {translator[language]["S.S SINK"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                     {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>5</h3> */}
//                 </div>
//                 <div style={{ border: '1px solid lightgrey' }}>
//                     {/* <img style={{ textAlign: "center" }} src={CP}></img> */}
//                     <Link className="carouselLink" to={{
//                         pathname: '/products/TANK',
//                         state: { data: categoryData }
//                     }}>
//                         <div className="carouselContainer">

//                             <img src={plumbingTank} alt={"Tank"} />
//                             <h1 className="carouselHeader"> {translator[language]["WATER STORAGE TANK"]}</h1>
//                         </div>
//                         {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
//                     </Link>
//                     {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>5</h3> */}
//                 </div>
//             </div>
//         );
//     }
// }


import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import './categoryCarousel.css'
import plumbingCPVC from '../../images/assets/plumbingCPVC.png'
import plumbingAPVC from '../../images/assets/plumbingAPVC.png'
import plumbingPVC from '../../images/assets/plumbingPVC.png'
import plumbingSWR from '../../images/assets/plumbingSWR.png'
import plumbingGI from '../../images/assets/plumbingGI.png'
import plumbingTaps from '../../images/assets/plumbingTaps.png'
import plumbingSink from '../../images/assets/plumbingSink.png'
import plumbingTank from '../../images/assets/plumbingTank.png'
import { translator } from "../../translator"

function MultipleItems() {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const categoryData = { category: 'UPVC' };

    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);

    return (
        <div className="carousel">
            <div style={{ border: '1px solid lightgrey' }}>
                <Link className="carouselLink" to={{
                    pathname: '/products/APVC',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingAPVC} alt={"APVC"} />
                        <h1 className="carouselHeader"> {translator[language]["APVC"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
                {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>1</h3> */}
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                <Link className="carouselLink" to={{
                    pathname: '/products/PVC',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingPVC} alt={"PVC"} />
                        <h1 className="carouselHeader"> {translator[language]["PVC"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                {/* <div style={{ border: '1px solid rgb(112, 112, 112)' }}> */}
                <Link className="carouselLink" to={{
                    pathname: '/products/CPVC',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        {/* <img src={CPVC} alt={"CPVC"} /> */}
                        <span><img src={plumbingCPVC} alt="CPVC" /><h1 className="carouselHeader"> {translator[language]["CPVC"]}</h1></span>

                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                <Link className="carouselLink" to={{
                    pathname: '/products/SWR',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingSWR} alt={"SWR"} />
                        <h1 className="carouselHeader"> {translator[language]["SWR"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                <Link className="carouselLink" to={{
                    pathname: '/products/GI',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingGI} alt={"GI"} />
                        <h1 className="carouselHeader"> {translator[language]["GI"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                {/* <img style={{ textAlign: "center" }} src={CP}></img> */}
                <Link className="carouselLink" to={{
                    pathname: '/products/TAPS',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingTaps} alt={"Taps"} />
                        <h1 className="carouselHeader"> {translator[language]["TAPS & ACCESSORIES"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
                {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>5</h3> */}
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                {/* <img style={{ textAlign: "center" }} src={CP}></img> */}
                <Link className="carouselLink" to={{
                    pathname: '/products/SINK',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingSink} alt={"Sink"} />
                        <h1 className="carouselHeader"> {translator[language]["S.S SINK"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
                {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>5</h3> */}
            </div>
            <div style={{ border: '1px solid lightgrey' }}>
                {/* <img style={{ textAlign: "center" }} src={CP}></img> */}
                <Link className="carouselLink" to={{
                    pathname: '/products/TANK',
                    state: { data: categoryData }
                }}>
                    <div className="carouselContainer">

                        <img src={plumbingTank} alt={"Tank"} />
                        <h1 className="carouselHeader"> {translator[language]["WATER STORAGE TANK"]}</h1>
                    </div>
                    {/* <h3 style={{ color: "#002244", fontSize: 40, textAlign: "center" }}>APVC</h3> */}
                </Link>
                {/* <h3 style={{ backgroundColor: "#E1EBEE", color: "#002244", fontSize: 80, textAlign: "center" }}>5</h3> */}
            </div>
        </div>
    );
}

export default MultipleItems;
