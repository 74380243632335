import React from "react";
import { Link } from "react-router-dom";
// import { Rating } from "@material-ui/lab";

const ProductCard = ({ product }) => {
  let discount = 0
  if (product && product.mrp) {
    discount = ((product.mrp - product.price) / product.mrp) * 100;
  }
  return (
    <Link className="productCard" to={`/product/${product._id}`}>
      <img src={product.images[0].url} alt={product.name} />
      <p>{product.name}</p>
      <div>
        <span className="price">{`₹${product.price}`}</span>
        {product && product.mrp &&
          <span className="mrp">{`₹${product.mrp}`} </span>
        }
        {product && product.mrp &&
          // <span className="discount">({`10% Off`})</span>
          <span className="discount">{`${Math.round(discount)}% Off`}</span>
        }
      </div>
      <span className="brand">{`${product.brand}`}</span>
    </Link>
  );
};

export default ProductCard;