import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/loader/Loader";
import ProductCard from "../Home/ProductCard";
import Pagination from "react-js-pagination";
// import Slider from "@material-ui/core/Slider";
import { useAlert } from "react-alert";
import MetaData from "../layout/metaData";
// import { useParams } from 'react-router-dom'
import debounce from 'lodash/debounce';
import { getAllCategories, clearCategoryErrors } from "../../actions/categoryActions";
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from "react-router-dom";
// import BottomNavBar from "../layout/BottomNavBar.js"
import PlumbingIcon from '@mui/icons-material/Plumbing';
import { useNavigate } from 'react-router-dom';
import BottomNavBar from "../layout/BottomNavBar.js"
import axios from 'axios';
import InputBase from "@mui/material/InputBase";
import { Box, IconButton } from "@mui/material";
import { translator } from "../../translator"
const apiHelper = require("../../helpers")

let types = []

const Products = () => {
    let navigate = useNavigate();
    const escapeRegex = (string) => {
        const uppercaseString = string.toUpperCase();
        const escapedString = uppercaseString.replace(/[.%*+?^${}()|[\]\\]/g, '\\$&');
        return escapedString;
    }
    const dispatch = useDispatch();
    const { categories } = useSelector((state) => state.allCategories);
    const { isAuthenticated, user } = useSelector(state => state.user)
    const { token } = useSelector((state) => state.token);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    const alert = useAlert();
    let user_props = {}
    if (user) {
        user_props = { user_details: user }
    }
    let categoryKeyword = ""
    let searchKeyword = ""
    // let brandState = ""
    // let typeState = ""
    // let sizeState = ""
    const location = useLocation();

    categoryKeyword = location.pathname.substring("/Products/".length);
    searchKeyword = location.state?.searchKeyword

    // let location_state = location.state
    // if (location_state && location_state['sendingCategoriesFilter']) {
    //     brandState = location_state.sendingCategoriesFilter['brand']
    //     typeState = location_state.sendingCategoriesFilter['type']
    //     sizeState = location_state.sendingCategoriesFilter['size']
    //     categoryKeyword = location_state.sendingCategoriesFilter['category']
    // }
    if (user && user.role === 'storeEmployee') {
        navigate(`/store/dashboard`);
    }

    const [currentPage, setCurrentPage] = useState(1)
    const [category, setCategory] = useState(categoryKeyword);
    const [brand, setBrand] = useState("");
    const [type, setType] = useState("");
    const [size, setSize] = useState("");
    const [sizeArray, setSizeArray] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [categoryList, setCategoryList] = useState([])
    let [keyword, setKeyword] = useState(searchKeyword);

    const {
        products,
        loading,
        error,
        productsCount,
        resultPerPage,
        // filteredProductsCount
    } = useSelector(state => state.products)

    if (category) {
        if (categories) {
            for (let i of categories) {
                if (i.name === category) {
                    // brands = i.brands
                    types = i.types
                    // sizes = i.sizes
                    // lengths = i.lengths
                }
            }
        }
    }

    // let user_props = {}
    // if (user) {
    //     user_props = { user_details: user }
    // }
    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiHelper.api + 'getCategoryList', config);
                if (response.data.success) {
                    setCategoryList(response.data.categories);
                }

            } catch (error) {
                // Handle error
                console.log(error);
            }
        };

        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(apiHelper.api + 'getSizeBasedOnSubCategory', {
                    params: {
                        type,
                        brand,
                        category
                    }
                }, config);
                if (response.data.success) {
                    setSizeArray(response.data.sizes);
                }

            } catch (error) {
                // Handle error
                console.log(error);
            }
        };

        fetchData();
    }, [type, brand, category]);

    useEffect(() => {
        setBrand("")
        setSize("")
        const fetchData = async () => {
            try {
                const response = await axios.post(apiHelper.api + 'getBrandBasedOnType', {
                    params: {
                        type,
                        category
                    }
                }, config);
                if (response.data.success) {
                    setBrandArray(response.data.brands);
                }

            } catch (error) {
                // Handle error
                console.log(error);
            }
        };

        fetchData();
    }, [category, type]);

    useEffect(() => {
        setType("")
        setBrand("")
        setSize("")
        dispatch(getProduct(category, type, brand, size))
    }, [category])

    const setCurrentPageNo = (e) => {
        setCurrentPage(e)
    }
    const delayedDispatch = debounce((e, value) => {
        e.preventDefault()
        dispatch(getProduct(escapeRegex(value)));
    }, 300);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
            dispatch(clearCategoryErrors());
        }
        dispatch(getAllCategories());
        dispatch(getProduct(keyword, currentPage, category, type, brand, size))
    }, [dispatch, currentPage, keyword, category, type, brand, size, alert, error])

    return (
        <Fragment>
            {loading ? (<Loader />) : (
                <Fragment>
                    <MetaData title="Products -- Plumbpigen" />
                    <Box display="flex" p={2}>
                        <Box display="flex" backgroundColor="#F7F7F7" borderRadius="5px">
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                placeholder={translator[language]["Search a Product ..."]}
                                value={keyword} // Set the value to the state variable 'keyword'
                                onChange={(e) => {
                                    setKeyword(e.target.value); // Update the 'keyword' state with the new value
                                    delayedDispatch(e, e.target.value); // Call your delayed dispatch function
                                }}
                            />
                            <IconButton type="button" sx={{ p: 1 }}>
                                <SearchIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {/* <form className="search-form" onSubmit={(e) => e.preventDefault()}>
                        <input
                            type="text"
                            placeholder="Search a Product ..."
                            name="search"
                            value={keyword}
                            onChange={(e) => {
                                setKeyword(e.target.value);
                                delayedDispatch(e, e.target.value);
                            }}
                        />
                    </form> */}
                    <div className="awesome-phrase-container8">
                        <p className="awesome-phrase2">
                            {translator[language]["Flush your plumbing problems down the drain with our products and services!"]} &#x1F60E;
                        </p>
                    </div>
                    <div className="carousel">
                        <div style={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }} >
                            {/* <label for="category">Category:</label> */}
                            <select onChange={(e) => setCategory(e.target.value)}>
                                <option value="">{translator[language]["Categories"]}</option>
                                {categoryList.map((category) => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                            {category &&

                                <select onChange={(e) => setType(e.target.value)}>
                                    <option value="">{translator[language]["Types"]}</option>
                                    {types.map((type) => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </select>
                            }
                        </div>
                        <div style={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                            {/* <label for="category">Category:</label> */}
                            {category &&

                                <select onChange={(e) => setBrand(e.target.value)}>
                                    <option value="">{translator[language]["Brands"]}</option>
                                    {brandArray.map((brand) => (
                                        <option key={brand} value={brand}>{brand}</option>
                                    ))}
                                </select>
                            }
                        </div>
                        <div style={{ boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                            {category &&

                                <select onChange={(e) => setSize(e.target.value)}>
                                    <option value="">{translator[language]["Sizes"]}</option>
                                    {sizeArray.map((size) => (
                                        <option key={size} value={size}>{size}</option>
                                    ))}
                                </select>
                            }
                        </div>
                    </div>

                    <h2 className="homeHeading"><PlumbingIcon className="categoryIcon" /> {translator[language]["Products"]}</h2>
                    <h3 className="productsCategory">{category}</h3>
                    <div className="products">
                        {products &&
                            products.map((product) => (
                                <ProductCard key={product._id} product={product} />
                            ))}
                    </div>


                    {resultPerPage < productsCount && (
                        <div className="paginationBox">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={productsCount}
                                onChange={setCurrentPageNo}
                                nextPageText="Next"
                                prevPageText="Prev"
                                firstPageText="1st"
                                lastPageText="Last"
                                itemClass="page-item"
                                linkClass="page-link"
                                activeClass="pageItemActive"
                                activeLinkClass="pageLinkActive"
                            />

                        </div>
                    )}
                    <BottomNavBar myProp={user_props} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default Products;

