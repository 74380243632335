import {
    CREATE_RETURN_ORDER_REQUEST,
    CREATE_RETURN_ORDER_SUCCESS,
    CREATE_RETURN_ORDER_FAIL,
    MY_RETURN_ORDERS_REQUEST,
    MY_RETURN_ORDERS_SUCCESS,
    MY_RETURN_ORDERS_FAIL,
    ALL_RETURN_ORDERS_REQUEST,
    ALL_RETURN_ORDERS_SUCCESS,
    ALL_RETURN_ORDERS_FAIL,
    UPDATE_RETURN_ORDER_REQUEST,
    UPDATE_RETURN_ORDER_SUCCESS,
    UPDATE_RETURN_ORDER_FAIL,
    UPDATE_RETURN_ORDER_RESET,
    DELETE_RETURN_ORDER_REQUEST,
    DELETE_RETURN_ORDER_SUCCESS,
    DELETE_RETURN_ORDER_FAIL,
    DELETE_RETURN_ORDER_RESET,
    RETURN_ORDER_DETAILS_REQUEST,
    RETURN_ORDER_DETAILS_SUCCESS,
    RETURN_ORDER_DETAILS_FAIL,
    CLEAR_RETURN_ERRORS,
} from "../constants/returnConstants";

export const newReturnOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_RETURN_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_RETURN_ORDER_SUCCESS:
            return {
                loading: false,
                order: action.payload,
            };

        case CREATE_RETURN_ORDER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_RETURN_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const myReturnOrdersReducer = (state = { returns: [] }, action) => {
    switch (action.type) {
        case MY_RETURN_ORDERS_REQUEST:
            return {
                loading: true,
            };

        case MY_RETURN_ORDERS_SUCCESS:
            return {
                loading: false,
                returns: action.payload,
            };

        case MY_RETURN_ORDERS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_RETURN_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const allReturnOrdersReducer = (state = { returns: [] }, action) => {
    switch (action.type) {
        case ALL_RETURN_ORDERS_REQUEST:
            return {
                loading: true,
            };

        case ALL_RETURN_ORDERS_SUCCESS:
            return {
                loading: false,
                returns: action.payload,
            };

        case ALL_RETURN_ORDERS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_RETURN_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const returnOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_RETURN_ORDER_REQUEST:
        case DELETE_RETURN_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_RETURN_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };

        case DELETE_RETURN_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
            };

        case UPDATE_RETURN_ORDER_FAIL:
        case DELETE_RETURN_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_RETURN_ORDER_RESET:
            return {
                ...state,
                isUpdated: false,
            };

        case DELETE_RETURN_ORDER_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_RETURN_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const returnOrderDetailsReducer = (state = { returns: {} }, action) => {
    switch (action.type) {
        case RETURN_ORDER_DETAILS_REQUEST:
            return {
                loading: true,
            };

        case RETURN_ORDER_DETAILS_SUCCESS:
            return {
                loading: false,
                returns: action.payload,
            };

        case RETURN_ORDER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_RETURN_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};