import React, { Fragment, useEffect, useState } from "react";
// import "./myReturnOrderDetails.css";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/metaData";
import { Link } from "react-router-dom";
// import { Typography } from "@material-ui/core";
import { getReturnOrderDetails, clearReturnErrors, updateReturnOrder, } from "../../actions/returnAction";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import { useParams } from 'react-router-dom'
import { UPDATE_RETURN_ORDER_RESET } from "../../constants/returnConstants";
import TagIcon from '@mui/icons-material/Tag';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import return_orders from '../../images/assets/return_orders.png'
import { translator } from "../../translator"
// import { useNavigate } from 'react-router-dom';

const ReturnOrderDetails = () => {
    // let navigate = useNavigate();
    const { id } = useParams()
    const { returns, error, loading } = useSelector((state) => state.returnOrderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.return);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    const updateOrderSubmitHandler = () => {
        const myForm = { status: 'ReturnCancelled' }
        dispatch(updateReturnOrder(id, myForm));
    };
    // const [status, setStatus] = useState("");
    const dispatch = useDispatch();
    const alert = useAlert();

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);


    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearReturnErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearReturnErrors());
        }
        if (isUpdated) {
            alert.success("Return Order Cancelled Successfully");
            dispatch({ type: UPDATE_RETURN_ORDER_RESET });
        }

        dispatch(getReturnOrderDetails(id));
    }, [dispatch, alert, error, id, isUpdated, updateError]);
    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title="Order Details" />
                    <div className="orderDetailsPage">
                        <div className="order-summary-container">
                            <img src={return_orders} alt="Orders" />
                            <header>{translator[language]["Return Order Summary"]}</header>
                            <div className="order-summary">
                                <div className="row">
                                    <div className="col">
                                        <p className="label"><TagIcon />{translator[language]["Return Order ID:"]}</p>
                                        <p className="value"><span>{returns && returns._id}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="label"><HourglassBottomIcon />{translator[language]["Status:"]}</p>
                                        <p className="value">{returns && returns.returnStatus}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="label"><LocalShippingIcon />{translator[language]["Shipping Info:"]}</p>
                                        <p className="value">{returns && returns.returnShippingInfo &&
                                            `${returns.returnShippingInfo.address}, ${returns.returnShippingInfo.city}, ${returns.returnShippingInfo.state}, ${returns.returnShippingInfo.pinCode}, ${returns.returnShippingInfo.country}`}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="label"><CurrencyRupeeIcon />{translator[language]["Payment Status:"]}</p>
                                        <p className="value">{returns && returns.returnPaymentInfo && returns.returnPaymentInfo.status}</p>
                                        <p className="value">{returns && returns.returnPaymentInfo && returns.returnPaymentInfo.mode}</p>
                                        <p className="value">{translator[language]["Return Delivery Fees-"]} <span>Rs {returns && returns.returnShippingPrice}</span> </p>
                                        <p className="valueMoney"><span>Rs {returns.returnTotalPrice && returns.returnTotalPrice.toFixed(2)}</span></p>
                                    </div>
                                </div>
                            </div>
                            {returns && returns.returnStatus === 'ReturnCancelled' &&
                                <div className="awesome-phrase-container2">
                                    <p className="awesome-phrase">
                                        {translator[language]["Return order is cancelled"]}
                                    </p>
                                </div>
                            }
                            {returns && returns.returnStatus === 'Collected' &&
                                <div className="awesome-phrase-container2">
                                    <p className="awesome-phrase">
                                        {translator[language]["Return order is collected"]}
                                    </p>
                                </div>
                            }
                            {returns && returns.returnStatus === 'Recieved' &&
                                <div className="awesome-phrase-container2">
                                    <p className="awesome-phrase">
                                        {translator[language]["Return order is placed"]}
                                    </p>
                                </div>
                            }
                            {returns && returns.returnStatus === 'ReturnShipped' &&
                                <div className="awesome-phrase-container2">
                                    <p className="awesome-phrase">
                                        {translator[language]["Our delivery agent is on the way and will collect your return order soon!"]}
                                    </p>
                                </div>
                            }
                            {returns && returns.returnStatus !== 'ReturnCancelled' && returns.returnStatus !== 'Collected' &&
                                <div className="awesome-phrase-container">
                                    <p className="awesome-phrase">
                                        {translator[language]["Cancel return order before it is collected"]}
                                    </p>
                                </div>
                            }
                            {returns && returns.returnStatus !== 'ReturnCancelled' && returns.returnStatus !== 'Collected' &&
                                <div className="button-container">
                                    <button onClick={updateOrderSubmitHandler} className="button">{translator[language]["Cancel Return Order"]}</button>
                                </div>
                            }
                        </div>
                        {/* <div className="orderDetailsContainer">
                            <h1>
                                Return Order #{returns && returns._id}
                            </h1>
                            {returns.returnStatus === 'ReturnCancelled' &&
                                <div className="statu-container">
                                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'tomato', color: 'white' }}>Return order is cancelled</h1>
                                </div>
                            }
                            {returns.returnStatus === 'Collected' &&
                                <div className="statu-container">
                                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'lightgreen', color: 'white' }}>Return order is collected</h1>
                                </div>

                            }
                            {returns.returnStatus === 'Recieved' &&
                                <div className="statu-container">
                                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'lightgreen', color: 'white' }}>Return order is placed</h1>
                                </div>
                            }
                            {returns.returnStatus === 'ReturnShipped' &&
                                <div className="statu-container">
                                    <h1 className="status-title" style={{ marginBottom: '20px', backgroundColor: 'lightgreen', color: 'white' }}>Our delivery agent is on the way and will collect your return order soon!</h1>
                                </div>
                            }
                            {returns.returnStatus !== 'ReturnCancelled' && returns.returnStatus !== 'Collected' &&

                                <div className="statu-container">
                                    <h1 className="status-title" style={{ marginBottom: '20px', color: '#002244' }} >Cancel return order before it is collected</h1>
                                    <button onClick={updateOrderSubmitHandler} className="cancelOrder">Cancel Return Order</button>
                                </div>
                            }

                            <h1 style={{ fontWeight: 'bolder' }}>Return Shipping Info</h1>
                            <div className="orderDetailsContainerBox">
                                <div>
                                    <p></p>
                                    <span>{returns.user && returns.user.name}</span>
                                </div>
                                <div>
                                    <p></p>
                                    <span>
                                        {returns.returnShippingInfo && returns.returnShippingInfo.phoneNo}
                                    </span>
                                </div>
                                <div>
                                    <p></p>
                                    <span>
                                        {returns.returnShippingInfo &&
                                            `${returns.returnShippingInfo.address}, ${returns.returnShippingInfo.city}, ${returns.returnShippingInfo.state}, ${returns.returnShippingInfo.pinCode}, ${returns.returnShippingInfo.country}`}
                                    </span>
                                </div>
                            </div>
                            <h1 style={{ fontWeight: 'bolder' }}>Payment</h1>
                            <div className="orderDetailsContainerBox">
                                <div>
                                    <p style={{ fontWeight: 'bolder' }}
                                        className={
                                            returns.returnPaymentInfo &&
                                                returns.returnPaymentInfo.status === "PAID"
                                                ? "greenColor"
                                                : "redColor"
                                        }
                                    >
                                        {returns.returnPaymentInfo &&
                                            returns.returnPaymentInfo.status === "PAID"
                                            ? "PAID"
                                            : "NOT PAID"}
                                    </p>
                                </div>

                                <div>
                                    <p style={{ fontWeight: 'bolder' }}>Amount:</p>
                                    <span>{returns.returnTotalPrice && returns.returnTotalPrice.toFixed(2)}</span>
                                </div>
                            </div>

                            <h1 style={{ fontWeight: 'bolder' }}>Return Order Status</h1>
                            <div className="orderDetailsContainerBox">
                                <div>
                                    <p style={{ fontWeight: 'bolder' }}
                                        className={
                                            returns.returnStatus && returns.returnStatus === "Collected"
                                                ? "greenColor"
                                                : "redColor"
                                        }
                                    >
                                        {returns.returnStatus && returns.returnStatus}
                                    </p>
                                </div>
                            </div>
                        </div> */}

                        <div className="awesome-phrase-container4">
                            <h1 style={{ fontWeight: 'bolder' }}>{translator[language]["Return Order Items:"]}</h1>
                            <div className="orderDetailsCartItemsContainer">
                                {returns && returns.returnItems &&
                                    returns.returnItems.map((item) => (
                                        <div key={item.product}>
                                            <img src={item.image} alt="Product" />
                                            <Link to={`/product/${item.product}`}>
                                                {item.name}
                                            </Link>{" "}
                                            <span>
                                                {item.quantity} X ₹{item.price} ={" "}
                                                <b>₹{item.price * item.quantity}</b>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ReturnOrderDetails;