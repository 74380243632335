import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/metaData";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SideBar from "./StoreDashboardSidebar";
import {
    getReturnOrderDetails,
    clearReturnErrors,
    updateReturnOrder,
} from "../../actions/returnAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button } from "@material-ui/core";
import { UPDATE_RETURN_ORDER_RESET } from "../../constants/returnConstants";
// import "./processStoreReturnOrder.css";
import { useParams } from 'react-router-dom'
import { getAllUsers } from "../../actions/userAction";
// import { createCommission, clearCommissionErrors } from "../../actions/commissionAction";
import { useLocation } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {
    getStoreDetails,
    clearStoreErrors,
} from "../../actions/storeAction";
import { addItemsToRejectItemList } from "../../actions/rejectItemListActions";
import { useNavigate } from 'react-router-dom';

const ProcessStoreReturnOrder = ({ history, match }) => {
    let navigate = useNavigate();
    const { id } = useParams()
    const location = useLocation()
    const { returns, error, loading } = useSelector((state) => state.returnOrderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.return);
    const { users } = useSelector((state) => state.allUsers);
    const { user } = useSelector(state => state.user)
    const { store } = useSelector((state) => state.storeDetails);

    const store_id = location.state?.store_id || user.store_id;
    const purpose = location.state?.purpose || "swiched_store_by_admin";

    let store_id_temp = ""
    let store_props = {}
    if ((user.role === 'admin' || user.role === 'developer') && purpose === "swiched_store_by_admin") {
        store_id_temp = store_id
    } else {
        store_id_temp = user.store_id
    }
    store_props = { store_id: store_id_temp, store_name: "" }
    const rejectItemSubmitHandler = () => {
        for (let i of returns.returnItems) {
            dispatch(addItemsToRejectItemList(i.product, i.quantity, i._id));
        }
        alert.success("Item Added To Reject Lsit");
        navigate(`/admin/rejectitems/${id}`);
    }

    let userList = []
    if (returns) {
        for (let user of users) {
            if (returns.store && user.store_id === returns.store.id) {
                userList.push(user)
            }
        }
    }

    const updateOrderSubmitHandler = (e) => {

        e.preventDefault();


        const myForm = new FormData();

        myForm.set("status", status);
        myForm.set("collectedBy", collectedBy);
        myForm.set("paymentStatus", paymentStatus)
        myForm.set("paymentMode", paymentMode)

        dispatch(updateReturnOrder(id, myForm));

        // if (status === 'Collected') {
        //     const itemTotalPrice = returns.returnTotalPrice - returns.returnTaxPrice - returns.returnShippingPrice

        //     const commission = {
        //         commissionAmount: ((itemTotalPrice * 15) / 100).toFixed(2),
        //         orderId: returns.OrderId
        //     };
        //     console.log(returns, 'return')
        //     console.log(commission, 'commission')
        //     console.log(returns.orderId, 'orderid')
        //     dispatch(createCommission(commission));
        // }

    };

    const dispatch = useDispatch();
    const alert = useAlert();

    const [status, setStatus] = useState("");
    const [collectedBy, setCollectedBy] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentMode, setPaymentMode] = useState("");

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearReturnErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearReturnErrors());
            dispatch(clearStoreErrors());
            // dispatch(clearCommissionErrors());
        }
        if (isUpdated) {
            alert.success("Order Updated Successfully");
            dispatch({ type: UPDATE_RETURN_ORDER_RESET });
        }

        dispatch(getReturnOrderDetails(id));
        dispatch(getAllUsers());
        dispatch(getStoreDetails(store_id, 'for_store_owner'))
    }, [dispatch, alert, error, id, isUpdated, updateError]);

    return (
        <Fragment>
            <MetaData title="Process Order" />
            <div className="dashboard">
                <SideBar myProp={store_props} />
                <div className="newProductContainer">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div
                            className="confirmOrderPage"
                            style={{
                                display: returns && returns.returnStatus === "Collected" ? "block" : "grid",
                            }}
                        >
                            <div>
                                <div className="orderDetailsPage">
                                    <div className="order-summary-container">
                                        <header>Return Order Details</header>
                                        <div className="order-summary">
                                            <div className="row">
                                                <p className="label">Return Shipping Info</p>
                                                <div className="col">
                                                    <p className="value">{returns && returns.user && returns.user.name}</p>
                                                </div>
                                                <div className="col">
                                                    <p className="value">
                                                        {returns && returns.returnShippingInfo && returns.returnShippingInfo.phoneNo}
                                                    </p>
                                                </div>
                                                <div className="col">
                                                    <p className="value">
                                                        {returns && returns.returnShippingInfo &&
                                                            `${returns.returnShippingInfo.address}, ${returns.returnShippingInfo.city}, ${returns.returnShippingInfo.state}, ${returns.returnShippingInfo.pinCode}, ${returns.returnShippingInfo.country}`}
                                                    </p>
                                                </div>
                                            </div>
                                            {store && store.store_type !== 'distributor' &&
                                                <div className="row">
                                                    <p className="label">Payment Status</p>
                                                    <div className="col">
                                                        <p className="value">{returns && returns.returnPaymentInfo && returns.returnPaymentInfo.status}</p>
                                                    </div>
                                                    <div className="col">
                                                        <p className="label">Payment Mode</p>
                                                        <p className="value">{returns && returns.returnPaymentInfo && returns.returnPaymentInfo.mode}</p>
                                                    </div>
                                                    <div className="col">
                                                        <p className="label">Return Amount</p>
                                                        <p className="valueMoney"><span>Rs {returns && returns.returnTotalPrice && returns.returnTotalPrice.toFixed(2)}</span></p>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <p className="label">Return Order Status</p>
                                                <div className="col">
                                                    <p className="value">{returns && returns.returnStatus && returns.returnStatus}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="confirmCartItemsContainer">
                                {returns && returns.orderItems &&
                                    returns.returnItems.map((item) => {
                                        if (Object.keys(store).length > 0) {
                                            const product = store.products.find((p) => p.product_id === item.product);

                                            return (
                                                <div key={item.product}>
                                                    <img src={item.image} alt="Product" />
                                                    <Link to={`/product/${item.product}`}>{item.name}</Link>{" "}
                                                    <span style={{ marginRight: '15px' }}><b>{product.product_brand}</b></span>{" "}
                                                    <span>
                                                        {item.quantity} X ₹{item.price} = <b>₹{item.price * item.quantity}</b>
                                                    </span>{" "}
                                                    {returns.returnStatus !== "Delivered" &&
                                                        product.product_stock === 0 ? (

                                                        <span style={{ color: 'tomato' }}>Stock: {product && product.product_stock}</span>
                                                    ) : (
                                                        <span style={{ color: '#32de84' }}>Stock: {product && product.product_stock}</span>
                                                    )

                                                    }
                                                    {product.product_location &&
                                                        // <span style={{ color: '#01a2f5' }}>Location: test</span>
                                                        <span tyle={{ color: '#32de84' }}>Location: {product && product.product_location}</span>
                                                    }
                                                </div>
                                            );
                                        }
                                    })}
                                {returns && (returns.returnStatus !== 'Collected' || returns.returnStatus !== 'ReturnCancelled') &&
                                    <div className="button-container">
                                        <button onClick={rejectItemSubmitHandler} className="button">Filter Unhealthy Items</button>
                                    </div>
                                }
                            </div>
                            {/*  */}
                            <div
                                style={{
                                    display: returns && returns.returnStatus === "Collected" ? "none" : "block",
                                }}
                            >
                                <form
                                    className="updateOrderForm"
                                    onSubmit={updateOrderSubmitHandler}
                                >
                                    <h1>Process Return Order</h1>

                                    <div>
                                        <AccountTreeIcon />
                                        <select onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">Choose Category</option>
                                            {returns && returns.returnStatus === "Recieved" && (
                                                <option value="ReturnShipped">Shipped</option>
                                            )}

                                            {returns && returns.returnStatus === "ReturnShipped" && (
                                                <option value="Collected">Collected</option>
                                            )}
                                        </select>
                                    </div>
                                    {returns && returns.returnStatus === "ReturnShipped" &&
                                        <h1>Collected By</h1>
                                    }
                                    {returns && returns.returnStatus === "ReturnShipped" &&
                                        <div>
                                            <LocalShippingIcon />
                                            <select onChange={(e) => setCollectedBy(e.target.value)}>
                                                <option value="">Choose Delivery Partner</option>
                                                {returns && returns.returnStatus === "ReturnShipped" &&
                                                    userList.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    }
                                    {returns && returns.returnStatus === "ReturnShipped" &&
                                        <h1>Payment Status</h1>
                                    }
                                    {returns && returns.returnStatus === "ReturnShipped" &&
                                        <div>
                                            <CreditScoreIcon />
                                            <select onChange={(e) => setPaymentStatus(e.target.value)}>
                                                <option value="">Payment Status</option>
                                                {returns && returns.returnStatus === "ReturnShipped" &&
                                                    <option value="PAID">PAID</option>
                                                }
                                                {returns && returns.returnStatus === "ReturnShipped" &&
                                                    <option value="NOT PAID">NOT PAID</option>
                                                }
                                            </select>
                                        </div>
                                    }
                                    {returns && returns.returnStatus === "ReturnShipped" &&
                                        <h1>Payment Mode</h1>
                                    }
                                    {returns && returns.returnStatus === "ReturnShipped" &&
                                        <div>
                                            <CurrencyRupeeIcon />
                                            <select onChange={(e) => setPaymentMode(e.target.value)}>
                                                <option value="">Payment Mode</option>
                                                {returns && returns.returnStatus === "ReturnShipped" &&
                                                    <option value="coc">Cash On Collection</option>
                                                }
                                                {returns && returns.returnStatus === "ReturnShipped" &&
                                                    <option value="spoc">Scan And Pay On Collection</option>
                                                }
                                            </select>
                                        </div>
                                    }
                                    <Button
                                        id="createProductBtn"
                                        type="submit"
                                        disabled={
                                            loading ? true : false || status === "" ? true : false
                                        }
                                    >
                                        Process
                                    </Button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default ProcessStoreReturnOrder;