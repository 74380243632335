// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name: "token",
    initialState: {
        token: null, // Add a token field to your user state
        // other user-related fields...
    },
    reducers: {
        // your reducers...
        registerToken(state, action) {
            state['token'] = action.payload
        },
    },
});

export default tokenSlice.reducer;
export const { registerToken } = tokenSlice.actions
