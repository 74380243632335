import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
// import DiscountIcon from '@mui/icons-material/Discount';
import shoppingBag from '../../images/assets/shoppingBagOutlined.png'
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { translator } from "../../translator"

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.8),
        borderRadius: theme.spacing(1),
        // backgroundColor: '#F7F7F7   ',
        // backgroundColor: '#eceef7',
        margin: '3px',
        flex: 1,
    },
    icon: {
        fontSize: 30,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: '#002f5e'
    },
    description: {
        fontSize: 16,
        textAlign: 'center',
        color: '#01a2f5'
    },
}));

const CartReminderCard = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const handleCheckoutClick = () => {
        navigate('/bag');
    };
    const { cartItems } = useSelector((state) => state.cart);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");
    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language)
        }
    }
    useEffect(() => {
        setUserLanguage()
    }, [isAuthenticated]);
    return (
        <Box display="flex" p={2}>
            <Box flex={1} display="flex">
                <Card onClick={handleCheckoutClick} variant="outlined" className={classes.card}>
                    {/* <StarIcon className={classes.icon} /> */}
                    {/* <DiscountIcon /> */}
                    <img src={shoppingBag} alt="Shopping bag Icon" />
                    <h2 className={classes.title}>
                        {translator[language]["Whoa there, shopping superstar!"]}
                    </h2>
                    <h3 className={classes.description}>
                        {translator[language]["Looks like you have"]} {cartItems.length} {translator[language]["items in your bag"]}<br></br> {translator[language][" buy now!"]}
                    </h3>
                </Card>
            </Box>
        </Box>
    );
};

export default CartReminderCard;
