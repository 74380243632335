import React, { Fragment, useEffect, useState } from "react";
// import { DataGrid } from '@mui/x-data-grid';
// import "./commissionDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { clearCommissionErrors, myCommissions } from "../../actions/commissionAction";
import Loader from "../layout/loader/Loader";
import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/metaData";
// import { useNavigate } from "react-router-dom";
import CommissionSidebar from "./CommissionSidebar.js";
import moment from "moment";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import { orderBy } from 'lodash';
import Stack from '@mui/material/Stack';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { translator } from "../../translator"

const MyCommissionsDetails = () => {
    const dispatch = useDispatch();

    const alert = useAlert();

    // let navigate = useNavigate();

    let showValue = "all"

    const { loading, error, commissions } = useSelector((state) => state.myCommissions);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };
    const [rows, setRows] = useState([])
    const [show, setShow] = useState("month")

    const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending order
    const sortedRows = orderBy(rows, 'createdAt', sortOrder);
    const handleSortClick = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    function yyyymmdd() {
        var x = new Date();
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }
    function Dates(date) {
        var x = new Date(date);
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let Todaydate = yyyymmdd()

    const showOrderDateWise = (e, value) => {
        showValue = value
        if (commissions && commissions.length > 0) {
            if (showValue === 'month') {

                let rows_temp = [];
                commissions &&
                    commissions.forEach((item, index) => {
                        if (Dates(item.CommissionIssueDate) >= Dates(firstDay) && Dates(item.CommissionIssueDate) <= Dates(lastDay)) {
                            rows_temp.push({
                                orderId: item.orderId.slice(-5),
                                id: item._id,
                                status: item.CommissionStatus,
                                amount: item.CommissionPerOrder,
                                createdAt: moment(item.CommissionIssueDate).format("DD/MM/YY hh:mm:ss")
                            });
                        }
                    });
                setShow('month')
                setRows(rows_temp)
            }
            else if (showValue === 'today') {
                let rows_temp = [];
                commissions &&
                    commissions.forEach((item, index) => {
                        if (Todaydate === Dates(item.CommissionIssueDate)) {
                            rows_temp.push({
                                orderId: item.orderId.slice(-5),
                                id: item._id,
                                status: item.CommissionStatus,
                                amount: item.CommissionPerOrder,
                                createdAt: moment(item.CommissionIssueDate).format("DD/MM/YY hh:mm:ss")
                            });
                        }
                    });
                setShow('today')
                setRows(rows_temp)
            }
            else if (showValue === 'all') {
                let rows_temp = [];
                commissions &&
                    commissions.forEach((item, index) => {
                        rows_temp.push({
                            orderId: item.orderId.slice(-5),
                            id: item._id,
                            status: item.CommissionStatus,
                            amount: item.CommissionPerOrder,
                            createdAt: moment(item.CommissionIssueDate).format("DD/MM/YY hh:mm:ss")
                        });
                    });
                setShow('all')
                setRows(rows_temp)
            }
        }
    }

    // const rows = [];
    const theme = createTheme();

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    useEffect(() => {
        showOrderDateWise("", "month")
        if (error) {
            alert.error(error);
            dispatch(clearCommissionErrors());
        }

        dispatch(myCommissions());
    }, [dispatch, alert, error]);

    useEffect(() => {
        showOrderDateWise("", show)
    }, [commissions]);

    return (
        <Fragment>
            <MetaData title={`${user.name} - Earnings`} />
            {loading ? (
                <Loader />
            ) : (
                <div className="productListContainer" id="list">
                    <div className="awesome-phrase-container7">
                        {show === "today" &&
                            <header>{translator[language]["Today's Earnings"]}</header>
                        }
                        {show === "month" &&
                            <header>{translator[language]["This Month's Earnings"]}</header>
                        }
                        {show === "all" &&
                            <header>{translator[language]["All Earnings"]}</header>
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button variant="outlined" size="small" style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'today')}>
                                {/* TODAY */}
                                <TodayIcon />
                                {translator[language]["Today"]}
                            </Button>
                            <Button variant="outlined" size="small" style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'month')} >
                                <CalendarMonthIcon />
                                {translator[language]["Month"]}
                            </Button>
                            <Button variant="outlined" size="small" style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'all')}>
                                <CalendarTodayIcon />
                                {translator[language]["All"]}
                            </Button>
                        </Stack>
                    </div>
                    {/* <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            className="myOrdersTable"
                            autoHeight
                            sx={{
                                margin: '2%',
                                bgcolor: '#F0F8FF',
                                boxShadow: 2,
                                border: 2,
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                            }}
                        /> */}
                    <Button onClick={handleSortClick}>Sort by date ({sortOrder === 'asc' ? 'latest' : 'oldest'})</Button>
                    <div className="awesome-phrase-container8">
                        <p className="awesome-phrase2">
                            {translator[language]["Rewards for the devoted: Your purchases are making a difference, and we're giving back for being an amazing customer."]} 🏆
                        </p>
                    </div>
                    <ThemeProvider theme={theme}>
                        <main style={{ overflowY: 'auto', height: '100%' }}>
                            <Container sx={{ py: 8 }} maxWidth="md">
                                {/* End hero unit */}
                                <Grid container spacing={4}>
                                    {sortedRows.map((row) => (
                                        <Grid item key={row.id} xs={12} sm={6} md={6}>
                                            <Card variant="outlined"
                                                sx={{
                                                    height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', transition: 'transform 0.2s ease-in-out'
                                                }}
                                            >
                                                {row.status === 'Paid' ? (
                                                    <Typography style={{ color: '#69f0ae', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">{row.status}</Typography>
                                                ) : (
                                                    <Typography style={{ color: '#ff8a80', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">{row.status}</Typography>
                                                )
                                                }
                                                <CardContent sx={{ flexGrow: 1 }}>
                                                    <Typography style={{ color: '#002244', fontWeight: 'bolder', marginTop: '5%' }} gutterBottom variant="h5" component="h2">
                                                        {row.id}
                                                    </Typography>
                                                    <Typography style={{ color: '#002244', marginTop: '5%' }}>
                                                        {row.createdAt}
                                                    </Typography>
                                                    <Typography style={{ color: '#002244', marginTop: '5%' }}>
                                                        {translator[language]["Order ID:"]} {row.orderId}
                                                    </Typography>
                                                    <Typography style={{ marginTop: '10px', color: '#002244', fontWeight: 'bolder', marginTop: '5%' }}>
                                                        <span style={{ backgroundColor: '#e8eaf6', padding: '10px', borderRadius: '5px' }}>Rs {row.amount.toFixed(2)}</span>

                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    {/* <Button size="small" onClick={(e) => goToOrderDetails(e, row.id)}>View Details</Button> */}
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </main>
                    </ThemeProvider>


                    {/* </div> */}


                    {/* <div className="orderDetailsContainerBox">{user.name}'s Commissions</div> */}
                </div>
            )
            }
        </Fragment >
    );
};

export default MyCommissionsDetails;