import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Button,
    Dialog,
    DialogTitle,
} from '@mui/material';
import { useAlert } from "react-alert";
import axios from 'axios';
import "../Cart/payment.css"
import { loadUser } from "../../actions/userAction.js";
const apiHelper = require("../../helpers")

function UserLanguage({ onLanguageChange, open, onClose }) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.token);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    const handleClick = async (event) => {
        let language = 'english'
        language = event.target.getAttribute("data-value");
        try {
            const response = await axios.post(apiHelper.api + 'addUserLanguage', { language }, config);
            if (response.data.success) {
                onLanguageChange(true);
                dispatch(loadUser());
                handleClose()
            } else {
                alert.error(response.data.message)
            }

        } catch (error) {
            alert.error(error.message)
        }
        handleClose()
    };
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>"Welcome! 🌟 Please select your preferred language. 🌍🗣️ Our website will be customized to your language choice. 🌐"</DialogTitle>
            <Box m="20px">
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12}>
                                <div className="paymentContainer">
                                    <div>
                                        <ul className="languageList" onClick={handleClick}>
                                            <li data-value="marathi">मराठी</li>
                                            <li data-value="kannada">ಕನ್ನಡ</li>
                                            <li data-value="hindi">हिंदी</li>
                                            <li data-value="english">English</li>
                                        </ul>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={handleClose} variant="outlined" color="secondary">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );
}

export default UserLanguage;








