import React, { Fragment, useEffect, useState } from "react";
import Product from "./ProductCard.js"
import MetaData from "../layout/metaData.js";
import { getProduct } from "../../actions/productAction"
import { useSelector, useDispatch } from "react-redux"
import "./Home.css";
import Loader from "../layout/loader/Loader.js";
import { useAlert } from "react-alert";
import Footer from "../layout/Footer/Footer.js";
// import { useNavigate } from 'react-router-dom';
import MultipleItems from '../Product/CategoryCarousel.js'
import HighlightsCarousel from './HighlightsCarousel.js'
import Search from "../Product/Search.js"
import {
  Button,
} from '@mui/material';
import BottomNavBar from "../layout/BottomNavBar.js"
import LocalMallIcon from '@mui/icons-material/LocalMall';
import StarIcon from '@mui/icons-material/Star';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import HighlightIcon from '@mui/icons-material/Highlight';
import OfferCard from './OfferCard.js'
import TopbarCard from './TopbarCard.js'
import CartReminderCard from './CartReminderCard.js'
import FeatureCard from "./FeatureCard.js";
import QuotationHighlightCard from "./QuotationHighlightCard.js";
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { loadUser } from "../../actions/userAction.js";
import UserLanguage from "../User/UserLanguage.js";
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import OrderBottomNotification from "./OrderBottomNotification.js";
import { translator } from "../../translator"
const Home = () => {
  let navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products, } = useSelector(state => state.products)
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);
  // const [isFullScreen, setIsFullScreen] = useState(false);

  if (user && user.role === 'storeEmployee') {
    console.log(user)
    navigate(`/store/dashboard`);
  }

  let user_props = {}
  if (user) {
    user_props = { user_details: user }
  }
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language)
    }
  }
  const [isLanguagePickerOpen, setIsLanguagePickerOpen] = useState(false);
  const openLanguagePicker = () => {
    setIsLanguagePickerOpen(true);
  };
  const handleLanguageChange = () => {
    dispatch(loadUser());
    setUserLanguage()
  };
  const closeLanguagePicker = () => {
    setIsLanguagePickerOpen(false);
  };
  useEffect(() => {
    if (error) {
      return alert.error(error)
    }
    dispatch(getProduct());

  }, [dispatch, error, alert]);

  useEffect(() => {
    setUserLanguage()
  }, [isAuthenticated]);

  return (
    <Fragment>
      {loading ? <Loader /> : <Fragment>
        <MetaData title="plumbpigen" />
        <div style={{ display: 'flex' }}>
          <Search />
          {isAuthenticated && user &&
            <div className="button-container">
              <TranslateOutlinedIcon onClick={openLanguagePicker} />
              {/* <Button variant="outlined" color="primary" onClick={openLanguagePicker}><TranslateOutlinedIcon /></Button> */}
            </div>
          }
        </div>
        {isLanguagePickerOpen && (
          <UserLanguage
            open={isLanguagePickerOpen}
            onClose={closeLanguagePicker}
            onLanguageChange={handleLanguageChange}
          />
        )}
        {/* {isFullScreen ? <TopNavBar myProp={user_props} /> : null} */}
        <div className="awesome-phrase-container8">
          <p className="awesome-phrase2">
            {'\u{1F60E}'} {translator[language]["When it comes to plumbing, we're the experts - let us help you keep your cool (and water flowing)!"]}💪
          </p>
        </div>
        {cartItems && cartItems.length > 0 &&
          < div >
            <CartReminderCard />
          </div>
        }
        {/* <br></br>
        <div>
          <OfferCard />
        </div> */}
        <h2 className="homeHeading"><LocalMallIcon className="categoryIcon" /> {translator[language]["Shop By Categories"]}</h2>
        <div>
          <MultipleItems />
        </div>
        <h2 className="homeHeading"><HighlightIcon className="categoryIcon" /> {translator[language]["Highlights"]}</h2>
        <div>
          <FeatureCard />
        </div>
        <div >
          <HighlightsCarousel />
        </div>
        <div >
          <QuotationHighlightCard />
        </div>
        <h2 className="homeHeading"><FavoriteOutlinedIcon className="categoryIcon" /> {translator[language]["We care For You"]}</h2>
        <div>
          <TopbarCard />
        </div>
        <div className="product">
          <h2 className="homeHeading"><StarIcon style={{ fontSize: '25px' }} className="categoryIcon" />{translator[language]["Featured Products"]}</h2>
          <div className="container" id="container">
            {products && products.map((product) => <Product product={product} />)}
          </div>
          <div className="awesome-phrase-container4">
            <p className="awesome-phrase2">
              {translator[language]["Lost in the sauce? Check out our guide page for all the answers!"]} 🗺️
            </p>
            <p style={{ margin: '2%' }} className="awesome-phrase"><Link to="/guide/selectlanguage" style={{ color: '#002244', textDecoration: 'none' }} >{translator[language]["Go to Guide"]}</Link></p>
          </div>
        </div>
        <div>
          <Footer />
        </div>
        <br></br>
        <br></br>
        <OrderBottomNotification />
        <BottomNavBar myProp={user_props} />
      </Fragment>}
    </Fragment>
  );
};

export default Home;
