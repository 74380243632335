import Header from "./component/layout/Header/Header.js";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import WebFont from "webfontloader";
import './App.css';
import { useEffect, useState, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Footer from "./component/layout/Footer/Footer.js";
import Home from "./component/Home/Home.js";
import ProductDetails from "./component/Product/ProductDetails.js";
import Loader from "./component/layout/loader/Loader.js";
import Products from "./component/Product/Products.js"
import Search from "./component/Product/Search.js"
import LoginSignUp from "./component/User/LoginSignUp.js";
// import AddUser from "./component/Admin/AddUser.js";
import store from "./store"
import { loadUser } from "./actions/userAction.js";
import { registerToken } from "./slices/TokenSlice.js";
import UserOptions from "./component/layout/Header/UserOptions.js"
// import Profile from "./component/User/Profile.js"
// import UpdateProfile from "./component/User/UpdateProfile.js"
// import UpdatePassword from "./component/User/UpdatePasword"
// import ForgotPassword from "./component/User/ForgotPasword.js"
// import ResetPassword from "./component/User/ResetPassword.js";
// import Cart from "./component/Cart/Cart.js"
import Shipping from "./component/Cart/Shipping.js"
import ReturnShipping from "./component/ReturnOrder/ReturnShipping.js"
// import TransferOrderItemWiseStoreDetails from "./component/TransferOrders/TransferOrderItemWiseStoreInfo.js"
import ConfirmReturnOrder from "./component/ReturnOrder/ConfirmReturnOrder.js"
import ConfirmTransferOrderItemWise from "./component/TransferOrders/ConfirmTransferOrderItemWise.js"
import ConfirmOrder from "./component/Cart/ConfirmOrder.js"
// import Payment from "./component/Cart/Payment.js"
// import OrderSuccess from "./component/Cart/orderSuccess"
// import ReturnOrderSuccess from "./component/ReturnOrder/ReturnOrderSuccess"
import MyOrders from "./component/Order/MyOrder"
import MyCommissionDetails from "./component/Commission/CommissionDetails.js"
import YourOrderList from "./component/Commission/YourOrderList.js"
import MyReturnOrders from "./component/ReturnOrderDisplay/MyReturnOrder"
import ReturnOrders from "./component/ReturnOrder/ReturnOrder"
// import TransferOrderItemWiseCart from "./component/TransferOrders/transferOrderItemWise"
import OrderDetails from "./component/Order/OrderDetails"
import MyReturnOrderDetails from "./component/ReturnOrderDisplay/MyReturnOrderDetails"
import CheckOrder from "./component/Order/CheckOrder.js";
// import axios from "axios";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import CommissionDashboard from "./component/Commission/CommissionDashboard.js"
import UsersListForSwitch from "./component/User/SwitchUser.js";
// import Dashboard from "./component/Admin/Dashboard.js"
// import StoreDashboard from "./component/StoreOwner/StoreDashboard.js"
// import ProductList from "./component/Admin/ProductList.js"
// import StoreProductList from "./component/StoreOwner/StoreProductList.js"
// import ProtectedRoute from "./component/Route/ProtectedRoute"
// import NewProduct from "./component/Admin/NewProduct.js";
// import NewCategory from "./component/Admin/createCategories.js";
// import UpdateCategory from "./component/Admin/updateCategories.js";
// import CategoryLists from "./component/Admin/categoryLists.js";
// import UpdateProduct from "./component/Admin/UpdateProduct";
// import UpdateStoreProduct from "./component/StoreOwner/UpdateStoreProduct";
// import OrderList from "./component/Admin/OrderList";
// import StoreOrderList from "./component/StoreOwner/StoreOrderList";
// import TodayOrder from "./component/Admin/OrderListDateWise/TodayOrder";
// import TodayStoreOrder from "./component/StoreOwner/StoreOrderDateWise/TodayStoreOrder";
// import MonthOrder from "./component/Admin/OrderListDateWise/MonthOrder";
// import MonthStoreOrder from "./component/StoreOwner/StoreOrderDateWise/MonthStoreOrder";
// import CommissionList from "./component/Admin/CommissionList";
// import TodayCommission from "./component/Admin/CommissionListDateWise/TodayCommission";
// import MonthCommission from "./component/Admin/CommissionListDateWise/MonthCommission";
// import ReturnOrderList from "./component/Admin/ReturnOrderList";
// import StoreReturnOrderList from "./component/StoreOwner/StoreReturnOrderList";
// import ReturnOrderToday from "./component/Admin/ReturnOrderListDateWise/ReturnOrderToday";
// import MonthStoreReturnOrder from "./component/StoreOwner/StoreReturnOrderDateWise/MonthStoreReturnOrder";
// import TodayStoreReturnOrder from "./component/StoreOwner/StoreReturnOrderDateWise/TodayStoreReturnOrder";
// import ReturnOrderMonth from "./component/Admin/ReturnOrderListDateWise/ReturnOrderMonth";
import ProcessOrder from "./component/Admin/ProcessOrder";
import RequestCommissions from "./component/Commission/RequestCommissions.js";
import StoreProcessOrder from "./component/StoreOwner/StoreProcessOrder";
import ProcessReturnOrder from "./component/Admin/ProcessReturnOrder";
import ProcessStoreReturnOrder from "./component/StoreOwner/ProcessStoreReturnOrder";
import ProcessCommission from "./component/Admin/ProcessCommission";
import UploadOrderImage from "./component/Order/UploadOrderImage.js";
import MyOrderConversionStatus from "./component/Order/MyOrderConversionStatus.js";
import BottomNavBar from "./component/layout/BottomNavBar.js"
import UserLanguage from "./component/User/UserLanguage.js";
// import UsersList from "./component/Admin/UsersList";
// import StoreList from "./component/Admin/StoreList";
// import UpdateUser from "./component/Admin/UpdateUser";
// import UpdateStore from "./component/Admin/UpdateStore";
// import Contact from "./component/layout/Contact/Contact";
// import About from "./component/layout/About/About";
// import NotFound from "./component/layout/Not Found/NotFound";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import SelectLanguageForGuide from "./component/guide/SelectLanguageForGuide.js";
// import EnglishGuideMenu from "./component/guide/menu/EnglishGuideMenu.js"
// import KannadaGuideMenu from "./component/guide/menu/KannadaGuideMenu.js"
// import MarathiGuideMenu from "./component/guide/menu/MarathiGuideMenu.js"
// import HindiGuideMenu from "./component/guide/menu/HindiGuideMenu.js"
// import EnglishPlaceOrderGuide from "./component/guide/guideContent/placeOrderGuide/EnglishPlaceOrderGuide.js"
// import MarathiPlaceOrderGuide from "./component/guide/guideContent/placeOrderGuide/MarathiPlaceOrderGuide.js"
// import KannadaPlaceOrderGuide from "./component/guide/guideContent/placeOrderGuide/KannadaPlaceOrderGuide.js"
// import HindiPlaceOrderGuide from "./component/guide/guideContent/placeOrderGuide/HindiPlaceOrderGuide.js"
// import EnglishReturnOrderGuide from "./component/guide/guideContent/returnOrderGuide/EnglishReturnOrderGuide.js"
// import KannadaReturnOrderGuide from "./component/guide/guideContent/returnOrderGuide/KannadaReturnOrderGuide.js"
// import MarathiReturnOrderGuide from "./component/guide/guideContent/returnOrderGuide/MarathiReturnOrderGuide.js"
// import HindiReturnOrderGuide from "./component/guide/guideContent/returnOrderGuide/HindiReturnOrderGuide.js"
// import EnglishCancelOrderGuide from "./component/guide/guideContent/cancelOrderGuide/EnglishCancelOrderGuide.js"
// import KannadaCancelOrderGuide from "./component/guide/guideContent/cancelOrderGuide/KannadaCancelOrderGuide.js"
// import MarathiCancelOrderGuide from "./component/guide/guideContent/cancelOrderGuide/MarathiCancelOrderGuide.js"
// import HindiCancelOrderGuide from "./component/guide/guideContent/cancelOrderGuide/HindiCancelOrderGuide.js"
// import EnglishRequestCommissionGuide from "./component/guide/guideContent/requestCommissionGuide/EnglishRequestCommissionGuide.js"
// import KannadaRequestCommissionGuide from "./component/guide/guideContent/requestCommissionGuide/KannadaRequestCommissionGuide.js"
// import MarathiRequestCommissionGuide from "./component/guide/guideContent/requestCommissionGuide/MarathiRequestCommissionGuide.js"
// import HindiRequestCommissionGuide from "./component/guide/guideContent/requestCommissionGuide/HindiRequestCommissionGuide.js"

const Contact = lazy(() => import("./component/layout/Contact/Contact"))
const About = lazy(() => import("./component/layout/About/About"))
const Profile = lazy(() => import("./component/User/Profile.js"))
const UpdatePassword = lazy(() => import("./component/User/UpdatePasword"))
const UpdateProfile = lazy(() => import("./component/User/UpdateProfile.js"))
const ForgotPassword = lazy(() => import("./component/User/ForgotPasword.js"))
const ResetPassword = lazy(() => import("./component/User/ResetPassword.js"))
const AddUser = lazy(() => import("./component/Admin/AddUser.js"))
const OrderSuccess = lazy(() => import("./component/Cart/orderSuccess"))
const ReturnOrderSuccess = lazy(() => import("./component/ReturnOrder/ReturnOrderSuccess"))
const TransferOrderItemWiseCart = lazy(() => import("./component/TransferOrders/transferOrderItemWise"))
const NewCategory = lazy(() => import("./component/Admin/createCategories.js"))
const UpdateCategory = lazy(() => import("./component/Admin/updateCategories.js"))
const CategoryLists = lazy(() => import("./component/Admin/categoryLists.js"))
const UsersList = lazy(() => import("./component/Admin/UsersList"))
const StoreList = lazy(() => import("./component/Admin/StoreList"))
const UpdateUser = lazy(() => import("./component/Admin/UpdateUser"))
const UpdateStore = lazy(() => import("./component/Admin/UpdateStore"))
const SelectLanguageForGuide = lazy(() => import("./component/guide/SelectLanguageForGuide.js"))
const EnglishGuideMenu = lazy(() => import("./component/guide/menu/EnglishGuideMenu.js"))
const KannadaGuideMenu = lazy(() => import("./component/guide/menu/KannadaGuideMenu.js"))
const MarathiGuideMenu = lazy(() => import("./component/guide/menu/MarathiGuideMenu.js"))
const HindiGuideMenu = lazy(() => import("./component/guide/menu/HindiGuideMenu.js"))
const EnglishPlaceOrderGuide = lazy(() => import("./component/guide/guideContent/placeOrderGuide/EnglishPlaceOrderGuide.js"))
const MarathiPlaceOrderGuide = lazy(() => import("./component/guide/guideContent/placeOrderGuide/MarathiPlaceOrderGuide.js"))
const KannadaPlaceOrderGuide = lazy(() => import("./component/guide/guideContent/placeOrderGuide/KannadaPlaceOrderGuide.js"))
const HindiPlaceOrderGuide = lazy(() => import("./component/guide/guideContent/placeOrderGuide/HindiPlaceOrderGuide.js"))
const EnglishReturnOrderGuide = lazy(() => import("./component/guide/guideContent/returnOrderGuide/EnglishReturnOrderGuide.js"))
const KannadaReturnOrderGuide = lazy(() => import("./component/guide/guideContent/returnOrderGuide/KannadaReturnOrderGuide.js"))
const MarathiReturnOrderGuide = lazy(() => import("./component/guide/guideContent/returnOrderGuide/MarathiReturnOrderGuide.js"))
const HindiReturnOrderGuide = lazy(() => import("./component/guide/guideContent/returnOrderGuide/HindiReturnOrderGuide.js"))
const EnglishCancelOrderGuide = lazy(() => import("./component/guide/guideContent/cancelOrderGuide/EnglishCancelOrderGuide.js"))
const KannadaCancelOrderGuide = lazy(() => import("./component/guide/guideContent/cancelOrderGuide/KannadaCancelOrderGuide.js"))
const MarathiCancelOrderGuide = lazy(() => import("./component/guide/guideContent/cancelOrderGuide/MarathiCancelOrderGuide.js"))
const HindiCancelOrderGuide = lazy(() => import("./component/guide/guideContent/cancelOrderGuide/HindiCancelOrderGuide.js"))
const EnglishRequestCommissionGuide = lazy(() => import("./component/guide/guideContent/requestCommissionGuide/EnglishRequestCommissionGuide.js"))
const KannadaRequestCommissionGuide = lazy(() => import("./component/guide/guideContent/requestCommissionGuide/MarathiRequestCommissionGuide.js"))
const MarathiRequestCommissionGuide = lazy(() => import("./component/guide/guideContent/requestCommissionGuide/MarathiRequestCommissionGuide.js"))
const HindiRequestCommissionGuide = lazy(() => import("./component/guide/guideContent/requestCommissionGuide/HindiRequestCommissionGuide.js"))
const NotFound = lazy(() => import("./component/layout/Not Found/NotFound"))
const Cart = lazy(() => import("./component/Cart/Cart.js"))
const Payment = lazy(() => import("./component/Cart/Payment.js"))
const UpdateProduct = lazy(() => import("./component/Admin/UpdateProduct"))
const NewProduct = lazy(() => import("./component/Admin/NewProduct.js"))
const OrderList = lazy(() => import("./component/Admin/OrderList"))
const UpdateStoreProduct = lazy(() => import("./component/StoreOwner/UpdateStoreProduct"))
const StoreOrderList = lazy(() => import("./component/StoreOwner/StoreOrderList"))
const ReturnOrderList = lazy(() => import("./component/Admin/ReturnOrderList"))
const StoreReturnOrderList = lazy(() => import("./component/StoreOwner/StoreReturnOrderList"))
const ReturnOrderToday = lazy(() => import("./component/Admin/ReturnOrderListDateWise/ReturnOrderToday"))
const ReturnOrderMonth = lazy(() => import("./component/Admin/ReturnOrderListDateWise/ReturnOrderMonth"))
const MonthStoreReturnOrder = lazy(() => import("./component/StoreOwner/StoreReturnOrderDateWise/MonthStoreReturnOrder"))
const TodayStoreReturnOrder = lazy(() => import("./component/StoreOwner/StoreReturnOrderDateWise/TodayStoreReturnOrder"))
const TodayOrder = lazy(() => import("./component/Admin/OrderListDateWise/TodayOrder"))
const TodayStoreOrder = lazy(() => import("./component/StoreOwner/StoreOrderDateWise/TodayStoreOrder"))
const MonthOrder = lazy(() => import("./component/Admin/OrderListDateWise/MonthOrder"))
const MonthStoreOrder = lazy(() => import("./component/StoreOwner/StoreOrderDateWise/MonthStoreOrder"))
const CommissionList = lazy(() => import("./component/Admin/CommissionList"))
const TodayCommission = lazy(() => import("./component/Admin/CommissionListDateWise/TodayCommission"))
const MonthCommission = lazy(() => import("./component/Admin/CommissionListDateWise/MonthCommission"))
const ProductList = lazy(() => import("./component/Admin/ProductList.js"))
const StoreProductList = lazy(() => import("./component/StoreOwner/StoreProductList.js"))
const Dashboard = lazy(() => import("./component/Admin/Dashboard.js"))
const StoreDashboard = lazy(() => import("./component/StoreOwner/StoreDashboard.js"))
const RejectItemList = lazy(() => import("./component/RejectItems/RejectItemList.js"))
const Bill = lazy(() => import("./component/Order/Bill.js"))
const Quotation = lazy(() => import("./component/Cart/Quotation.js"))

function App() {
  const dispatch = useDispatch()
  const { isAuthenticated, user, token } = useSelector(state => state.user)
  const [stripeApiKey, setStripeApiKey] = useState("");
  // async function getStripeApiKey() {
  //   const { data } = await axios.get("/api/v1/stripeapikey");

  //   setStripeApiKey(data.stripeApiKey);
  // }
  let user_props = {}
  if (user) {
    user_props = { user_details: user }
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Droid Sans", "Chilanka"],
      },
    });

    store.dispatch(loadUser());
    // getStripeApiKey();
  }, []);
  const [isLanguagePickerOpen, setIsLanguagePickerOpen] = useState(false);
  // const openLanguagePicker = () => {
  //   setIsLanguagePickerOpen(true);
  // };
  const handleLanguageChange = () => {
    setIsLanguagePickerOpen(false);
    dispatch(loadUser());
  };
  // const closeLanguagePicker = () => {
  //   setIsLanguagePickerOpen(false);
  // };
  useEffect(() => {
    if (isAuthenticated) {
      if (user && !user.language) {
        setIsLanguagePickerOpen(true);
      }
      if (user && user.role && user.role === 'storePartner') {
        window.location.href = "https://nest.plumbpigen.com";
      }
      store.dispatch(registerToken(token))
    }
    // getStripeApiKey();
  }, [isAuthenticated]);
  return (
    <Router>
      <Header />
      {isLanguagePickerOpen && (
        <UserLanguage
          open={isLanguagePickerOpen}
          // onClose={closeLanguagePicker}
          onLanguageChange={handleLanguageChange}
        />
      )}
      {isAuthenticated && <UserOptions user={user} />}
      {/* {stripeApiKey && (
          <Elements stripe={loadStripe(stripeApiKey)}>
              <Route  exact path="/process/payment" element={<Payment />} />
          </Elements>
        )} */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/process/payment" element={<Payment />} />
          {/* <Route exact path="/process/payment" element={stripeApiKey && (
            <Elements stripe={loadStripe(stripeApiKey)}> <Payment /></Elements>)} /> */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/product/:id" element={<ProductDetails />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/products/:keyword" element={<Products />} />
          <Route path="/search" element={<Search />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/account" element={isAuthenticated ? <Profile user={user} /> : <LoginSignUp />} />
          <Route exact path="/me/update" element={isAuthenticated ? <UpdateProfile user={user} /> : <LoginSignUp />} />
          <Route exact path="/password/update" element={isAuthenticated ? <UpdatePassword user={user} /> : <LoginSignUp />} />
          <Route exact path="/password/forgot" element={<ForgotPassword />} />
          <Route exact path="/password/reset/:token" element={<ResetPassword />} />
          <Route exact path="/login" element={<LoginSignUp />} />
          <Route exact path="/bag" element={isAuthenticated ? <Cart user={user} /> : <LoginSignUp />} />
          <Route exact path="/login/shipping" element={isAuthenticated ? <Shipping user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/newuser" element={isAuthenticated ? <AddUser user={user} /> : <LoginSignUp />} />
          <Route exact path="/login/returnshipping" element={isAuthenticated ? <ReturnShipping user={user} /> : <LoginSignUp />} />
          <Route exact path="/uploadorder" element={isAuthenticated ? <UploadOrderImage user={user} /> : <LoginSignUp />} />
          <Route exact path="/order/images/status" element={isAuthenticated ? <MyOrderConversionStatus user={user} /> : <LoginSignUp />} />
          {/* <Route exact path="/login/transfer/order/itemwise/storedetails/:id" element={isAuthenticated ? <TransferOrderItemWiseStoreDetails user={user} /> : <LoginSignUp />} /> */}
          <Route exact path="/process/payment" element={isAuthenticated ? <Payment user={user} /> : <LoginSignUp />} />
          <Route exact path="/switchuser" element={isAuthenticated ? <UsersListForSwitch user={user} /> : <LoginSignUp />} />
          <Route exact path="/success" element={isAuthenticated ? <OrderSuccess user={user} /> : <LoginSignUp />} />
          <Route exact path="/checkorder/:id" element={isAuthenticated ? <CheckOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/returnsuccess" element={isAuthenticated ? <ReturnOrderSuccess user={user} /> : <LoginSignUp />} />
          <Route exact path="/commission/details" element={isAuthenticated ? <MyCommissionDetails user={user} /> : <LoginSignUp />} />
          <Route exact path="/orders/me" element={isAuthenticated ? <YourOrderList user={user} /> : <LoginSignUp />} />
          <Route exact path="/orders" element={isAuthenticated ? <MyOrders user={user} /> : <LoginSignUp />} />
          <Route exact path="/returnorders" element={isAuthenticated ? <MyReturnOrders user={user} /> : <LoginSignUp />} />
          <Route exact path="/return/:id" element={isAuthenticated ? <ReturnOrders user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/rejectitems/:id" element={isAuthenticated ? <RejectItemList user={user} /> : <LoginSignUp />} />
          <Route exact path="/transfer/order/itemwise/:id" element={isAuthenticated ? <TransferOrderItemWiseCart user={user} /> : <LoginSignUp />} />
          <Route exact path="/order/confirm" element={isAuthenticated ? <ConfirmOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/returnorder/confirm" element={isAuthenticated ? <ConfirmReturnOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/transfer/order/itemwise/confirm/" element={isAuthenticated ? <ConfirmTransferOrderItemWise user={user} /> : <LoginSignUp />} />
          <Route exact path="/order/:id" element={isAuthenticated ? <OrderDetails user={user} /> : <LoginSignUp />} />
          <Route exact path="/order/bill" element={isAuthenticated ? <Bill user={user} /> : <LoginSignUp />} />
          <Route exact path="/order/quotation" element={isAuthenticated ? <Quotation user={user} /> : <LoginSignUp />} />
          <Route exact path="/returns/details/:id" element={isAuthenticated ? <MyReturnOrderDetails user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/dashboard" element={isAuthenticated ? <Dashboard user={user} /> : <LoginSignUp />} />
          <Route exact path="/commission/dashboard" element={isAuthenticated ? <CommissionDashboard user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/dashboard" element={isAuthenticated ? <StoreDashboard user={user} /> : <LoginSignUp />} />
          {/* <Route element={<ProtectedRoute exact path="/admin/dashboard"  element={<Dashboard />}/>}/> */}
          <Route exact path="/admin/products" element={isAuthenticated ? <ProductList user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/products" element={isAuthenticated ? <StoreProductList user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/product" element={isAuthenticated ? <NewProduct user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/category/create" element={isAuthenticated ? <NewCategory user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/category/update/:id" element={isAuthenticated ? <UpdateCategory user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/categories" element={isAuthenticated ? <CategoryLists user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/product/:id" element={isAuthenticated ? <UpdateProduct user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/product/:id" element={isAuthenticated ? <UpdateStoreProduct user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/orders" element={isAuthenticated ? <OrderList user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/orders" element={isAuthenticated ? <StoreOrderList user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/orders/today" element={isAuthenticated ? <TodayOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/orders/today" element={isAuthenticated ? <TodayStoreOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/orders/month" element={isAuthenticated ? <MonthOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/orders/month" element={isAuthenticated ? <MonthStoreOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/commissions" element={isAuthenticated ? <CommissionList user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/commissions/today" element={isAuthenticated ? <TodayCommission user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/commissions/month" element={isAuthenticated ? <MonthCommission user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/return" element={isAuthenticated ? <ReturnOrderList user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/return" element={isAuthenticated ? <StoreReturnOrderList user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/return/today" element={isAuthenticated ? <ReturnOrderToday user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/return/today" element={isAuthenticated ? <TodayStoreReturnOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/return/month" element={isAuthenticated ? <ReturnOrderMonth user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/return/month" element={isAuthenticated ? <MonthStoreReturnOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/order/:id" element={isAuthenticated ? <ProcessOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/commission/request" element={isAuthenticated ? <RequestCommissions user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/order/:id" element={isAuthenticated ? <StoreProcessOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/return/:id" element={isAuthenticated ? <ProcessReturnOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/store/return/:id" element={isAuthenticated ? <ProcessStoreReturnOrder user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/commissions/process" element={isAuthenticated ? <ProcessCommission user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/users" element={isAuthenticated ? <UsersList user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/stores" element={isAuthenticated ? <StoreList user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/user/:id" element={isAuthenticated ? <UpdateUser user={user} /> : <LoginSignUp />} />
          <Route exact path="/admin/store/:id" element={isAuthenticated ? <UpdateStore user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/selectlanguage" element={isAuthenticated ? <SelectLanguageForGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/english/menu" element={isAuthenticated ? <EnglishGuideMenu user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/kannada/menu" element={isAuthenticated ? <KannadaGuideMenu user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/marathi/menu" element={isAuthenticated ? <MarathiGuideMenu user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/hindi/menu" element={isAuthenticated ? <HindiGuideMenu user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/english/placeorderguide" element={isAuthenticated ? <EnglishPlaceOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/marathi/placeorderguide" element={isAuthenticated ? <MarathiPlaceOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/kannada/placeorderguide" element={isAuthenticated ? <KannadaPlaceOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/hindi/placeorderguide" element={isAuthenticated ? <HindiPlaceOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/english/returnorderguide" element={isAuthenticated ? <EnglishReturnOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/kannada/returnorderguide" element={isAuthenticated ? <KannadaReturnOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/marathi/returnorderguide" element={isAuthenticated ? <MarathiReturnOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/hindi/returnorderguide" element={isAuthenticated ? <HindiReturnOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/english/cancelorderguide" element={isAuthenticated ? <EnglishCancelOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/kannada/cancelorderguide" element={isAuthenticated ? <KannadaCancelOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/marathi/cancelorderguide" element={isAuthenticated ? <MarathiCancelOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/hindi/cancelorderguide" element={isAuthenticated ? <HindiCancelOrderGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/english/requestcommissionguide" element={isAuthenticated ? <EnglishRequestCommissionGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/kannada/requestcommissionguide" element={isAuthenticated ? <KannadaRequestCommissionGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/marathi/requestcommissionguide" element={isAuthenticated ? <MarathiRequestCommissionGuide user={user} /> : <LoginSignUp />} />
          <Route exact path="/guide/hindi/requestcommissionguide" element={isAuthenticated ? <HindiRequestCommissionGuide user={user} /> : <LoginSignUp />} />
          <Route element={window.location.pathname === "/process/payment" ? null : <NotFound />} />

        </Routes>
      </Suspense>
      <br></br>
      <br></br>
      <br></br>
      {user && isAuthenticated &&
        < BottomNavBar myProp={user_props} />
      }
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
