import React, { Fragment, useEffect, useState } from "react";
import "./returnOrder.css";
import ReturnCartItemCard from "./ReturnCartItemCard";
import { useSelector, useDispatch } from "react-redux";
import { addItemsToReturnCart, removeItemsFromReturnCart } from "../../actions/returnCartActions";
import { useNavigate } from 'react-router-dom';
import { useAlert } from "react-alert";
import { getOrderDetails, clearErrors } from "../../actions/orderAction";
import { UPDATE_RETURN_ORDER_RESET } from "../../constants/returnConstants";
import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card';
import { translator } from "../../translator"

const Cart = () => {
    const { id } = useParams()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const alert = useAlert();
    const { returnCartItems } = useSelector((state) => state.returnCart);
    const { order, error } = useSelector((state) => state.orderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.order);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    // const { product } = useSelector(
    //     (state) => state.productDetails
    // );

    // let qty = 0
    // let count = 0
    let qtyObject = []
    if (order) {
        for (let i of order.orderItems) {
            qtyObject.push({ 'id': i.product, 'stock': i.quantity })
        }
    }


    const increaseQuantity = (id, quantity, stock) => {

        for (let i of qtyObject) {
            if (i.id === id) {

                stock = i.stock
            }
        }

        const newQty = quantity + 1;
        if (stock <= quantity) {

            return;
        }
        dispatch(addItemsToReturnCart(id, newQty, order._id));
    };

    const decreaseQuantity = (id, quantity) => {
        const newQty = quantity - 1;
        if (0 >= quantity) {
            return;
        }
        dispatch(addItemsToReturnCart(id, newQty));
    };

    const deleteCartItems = (id) => {
        dispatch(removeItemsFromReturnCart(id));
    };

    const checkoutHandler = () => {
        navigate("/login?navigate=returnshipping");
    };

    window.addEventListener("popstate", deleteCart);

    function deleteCart() {

        for (let i of order.orderItems) {
            dispatch(removeItemsFromReturnCart(i.product))
        }

    }

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            alert.success("Return Order Updated Successfully");
            dispatch({ type: UPDATE_RETURN_ORDER_RESET });
        }

        dispatch(getOrderDetails(id));
    }, [dispatch, alert, error, id, isUpdated, updateError]);

    return (
        <Fragment>
            <Fragment>
                <div className="cartPage">
                    <div className="cartHeader">
                        <p>{translator[language]["Product"]}</p>
                        <p>{translator[language]["Quantity"]}</p>
                        <p>{translator[language]["Subtotal"]}</p>
                    </div>

                    {returnCartItems &&
                        returnCartItems.map((item) => (
                            <Card sx={{ my: '6px', py: '5px' }} variant="outlined" className="cartCard">
                                <div className="cartContainer" key={item.product}>
                                    <ReturnCartItemCard item={item} deleteCartItems={deleteCartItems} />
                                    <div className="cartInput">
                                        <button
                                            onClick={() =>
                                                decreaseQuantity(item.product, item.quantity)
                                            }
                                        >
                                            -
                                        </button>
                                        <input type="number" value={item.quantity} />
                                        <button
                                            onClick={() =>
                                                increaseQuantity(
                                                    item.product,
                                                    item.quantity,
                                                    item.quantity
                                                )
                                            }
                                        >
                                            +
                                        </button>
                                    </div>
                                    <p className="cartSubtotal">{`₹${item.price * item.quantity
                                        }`}</p>
                                </div>
                            </Card>
                        ))}
                    <div className="cartGrossProfit">
                        <div></div>
                        <div className="cartGrossProfitBox">
                            {/* <p>{translator[language]["Gross Total"]}</p> */}
                            {/* <p>{`₹${order.orderItems.reduce(
                                (acc, item) => acc + item.quantity * item.price,
                                0
                            )}`}</p> */}
                        </div>
                        <div></div>
                        <div className="checkOutBtn">
                            <button onClick={checkoutHandler}>{translator[language]["Check Out Return Bag"]}</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    );
};

export default Cart;