import React, { Fragment, useEffect, useState } from "react";
import "../Cart/Cart.css";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    TextField,
} from '@mui/material';
import BottomNavBar from "../layout/BottomNavBar.js"
import { useParams } from 'react-router-dom'
import { getOrderDetails, clearErrors, updateOrder, } from "../../actions/orderAction";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import "../Cart/CartItemCard.css";
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import axios from 'axios';
import { useAlert } from "react-alert";
import Loader from "../layout/loader/Loader";
import { translator } from "../../translator"
const apiHelper = require("../../helpers")

const CheckOrder = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const alert = useAlert();
    const { id } = useParams()
    let { order, error, loading } = useSelector((state) => state.orderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.order);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };

    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    let [acceptedOrderItems, setAcceptedOrderItems] = useState([])
    let [pendingOrderItems, setPendingOrderItems] = useState([])
    const [confirmation, setConfirmation] = useState({ msg: "", confirm: false });
    const { token } = useSelector((state) => state.token);
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    if (user && user.role === 'storeEmployee') {
        navigate(`/store/dashboard`);
    }
    let user_props = {}
    if (user) {
        user_props = { user_details: user }
    }
    const handleClose = () => {
        setConfirmation({ msg: "", confirm: false })
        loading = false
    };
    const handleOpen = () => {
        setConfirmation({ msg: "", confirm: true })
    };
    const handleConfirmation = (value, actionType) => {
        loading = true
        if (value === "Accept") {
            if (actionType === 'accept') {
                AcceptHandler()
            } else if (actionType === 'decline') {
                handleClose();
            }
        } else if (value === "Cancel") {
            if (actionType === 'accept') {
                DeclineHandler()
            } else if (actionType === 'decline') {
                handleClose();
            }
        } else {
            handleClose();
        }
        handleClose();
    };

    const increaseQuantity = (id) => {
        let acceptedItemIndex = acceptedOrderItems.findIndex(item => item.product === id);
        let pendingItemIndex = pendingOrderItems.findIndex(item => item.product === id);
        let originalItem = []
        originalItem = JSON.parse(JSON.stringify(order.orderItems)).find(item => item.product === id)
        if (acceptedItemIndex !== -1) {
            // Check if there is enough stock to increase the quantity
            if (acceptedOrderItems[acceptedItemIndex].quantity < originalItem['quantity']) {
                acceptedOrderItems[acceptedItemIndex].quantity += 1;

                if (pendingItemIndex !== -1) {
                    pendingOrderItems[pendingItemIndex].quantity -= 1;
                    if (pendingOrderItems[pendingItemIndex].quantity === 0) {
                        pendingOrderItems = pendingOrderItems.filter(x => x.product !== id)
                    }
                }
                // Update the state with the modified items
                setAcceptedOrderItems([...acceptedOrderItems]);
                setPendingOrderItems([...pendingOrderItems]);
            }
        }
    };

    const decreaseQuantity = (id) => {
        let acceptedItemIndex = acceptedOrderItems.findIndex(item => item.product === id);
        let pendingItemIndex = pendingOrderItems.findIndex(item => item.product === id);

        if (acceptedItemIndex !== -1) {
            if (acceptedOrderItems[acceptedItemIndex].quantity > 1) {
                acceptedOrderItems[acceptedItemIndex].quantity -= 1;

                if (pendingItemIndex !== -1) {
                    pendingOrderItems[pendingItemIndex].quantity += 1;
                } else {
                    let originalItem = JSON.parse(JSON.stringify(order.orderItems)).find(item => item.product === id)
                    originalItem['quantity'] = 1
                    pendingOrderItems.push(originalItem)
                }

                // Update the state with the modified items
                setAcceptedOrderItems([...acceptedOrderItems]);
                setPendingOrderItems([...pendingOrderItems]);
            }
        }
    };
    const deleteCartItems = (id) => {
        // dispatch(removeItemsFromCart(id));
        acceptedOrderItems = acceptedOrderItems.filter(x => x.product !== id)
        pendingOrderItems = pendingOrderItems.filter(x => x.product !== id)
        let originalItem = JSON.parse(JSON.stringify(order.orderItems)).find(item => item.product === id)
        pendingOrderItems.push(originalItem)
        setAcceptedOrderItems([...acceptedOrderItems]);
        setPendingOrderItems([...pendingOrderItems]);
    };

    const AcceptedOrderDetails = () => {
        let itemPrice = 0
        for (let i of acceptedOrderItems) {
            itemPrice = itemPrice + i.price
        }
        const subtotal = acceptedOrderItems.reduce(
            (acc, item) => acc + item.quantity * item.price,
            0
        );
        let shippingCharges = subtotal > 1500 ? 0 : 100;
        for (let item of acceptedOrderItems) {
            if (item && item.type && (item.type === "PIPE" || item.type === " SWR PIPE")) {
                if (item.quantity > 5 && item.quantity <= 50) {
                    shippingCharges = shippingCharges + 50;
                } else if (item.quantity > 50) {
                    shippingCharges = shippingCharges + 100;
                }
                break
            }
        }
        const tax = subtotal * 0.18;
        const totalPrice = subtotal + tax + shippingCharges;
        return { itemPrice: itemPrice, subtotal: subtotal, shippingCharges: shippingCharges, tax: tax, totalPrice: totalPrice }
    }

    const PendingOrderDetails = () => {
        let itemPrice = 0
        for (let i of pendingOrderItems) {
            itemPrice = itemPrice + i.price
        }
        const subtotal = pendingOrderItems.reduce(
            (acc, item) => acc + item.quantity * item.price,
            0
        );
        let shippingCharges = subtotal > 1500 ? 0 : 100;
        for (let item of pendingOrderItems) {
            if (item && item.type && (item.type === "PIPE" || item.type === " SWR PIPE")) {
                if (item.quantity > 5 && item.quantity <= 50) {
                    shippingCharges = shippingCharges + 50;
                } else if (item.quantity > 50) {
                    shippingCharges = shippingCharges + 100;
                }
                break
            }
        }
        const tax = subtotal * 0.18;
        const totalPrice = subtotal + tax;
        // const totalPrice = subtotal + tax + shippingCharges;
        return { itemPrice: itemPrice, subtotal: subtotal, shippingCharges: shippingCharges, tax: tax, totalPrice: totalPrice }
    }

    const AcceptHandler = async () => {
        let acceptedOrderDetails = AcceptedOrderDetails()
        let pendingOrderDetails = PendingOrderDetails()
        let acceptedOrder = {
            acceptedOrderItems: acceptedOrderItems,
            itemPrice: acceptedOrderDetails.subtotal,
            subtotal: acceptedOrderDetails.subtotal,
            shippingCharges: order.shippingCharges,
            tax: acceptedOrderDetails.tax,
            totalPrice: acceptedOrderDetails.totalPrice

        }
        let pendingOrder = {
            pendingOrderItems: pendingOrderItems,
            itemPrice: pendingOrderDetails.subtotal,
            subtotal: pendingOrderDetails.subtotal,
            shippingCharges: 0,
            // shippingCharges: pendingOrderDetails.shippingCharges,
            tax: pendingOrderDetails.tax,
            totalPrice: pendingOrderDetails.totalPrice
        }
        let payload = {
            order_id: id,
            acceptOrderStatus: true,
            shippingInfo: order.shippingInfo,
            user_id: order.user._id,
            user_name: order.user.name,
            deliveredByStore: order.deliveredByStore,
            store: order.store,
            acceptedOrder,
            pendingOrder
        }
        try {
            loading = true
            const response = await axios.post(apiHelper.api + 'acceptOrder', payload, config);
            if (response.data.success) {
                loading = false
                navigate('/orders');
            }

        } catch (error) {
            loading = false
            alert.error(error.response.data.message);
        }
    };

    const DeclineHandler = async () => {
        loading = true
        try {
            const response = await axios.put(apiHelper.api + `orders/cancel/${id}`, { status: 'Cancelled', reason: 'Declined By User During Or Before Delivery' }, config);
            if (response.data.success) {
                loading = false
                navigate('/orders');
            }

        } catch (error) {
            loading = false
            alert.error(error.response.data.message);
        }
    };
    useEffect(() => {
        if (order && order.orderItems) {
            const deepCopy = JSON.parse(JSON.stringify(order.orderItems));
            setAcceptedOrderItems(deepCopy);
        }
    }, [order])
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            alert.success("Order Accepted Successfully");
            dispatch({ type: UPDATE_ORDER_RESET });
        }
        dispatch(getOrderDetails(id));
    }, [dispatch, alert, error, id, isUpdated, updateError]);
    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="cartPage">
                        <div className="cartHeader">
                            <p>{translator[language]["Product"]}</p>
                            <p>{translator[language]["Quantity"]}</p>
                            <p>{translator[language]["Subtotal"]}</p>
                        </div>

                        {acceptedOrderItems &&
                            acceptedOrderItems.map((item) => (
                                <Card sx={{ my: '6px', py: '5px' }} variant="outlined" className="cartCard">
                                    <div className="cartContainer" key={item.product}>
                                        <div className="CartItemCard">
                                            <img src={item.image} alt="ssa" />
                                            <div>
                                                <Link style={{ textDecoration: 'none' }} to={`/product/${item.product}`}>{item.name}</Link>
                                                <span><h3>{`₹${item.price}`}</h3></span>
                                                <span><BrandingWatermarkIcon />  {`${item.brand}`}</span>
                                                <p onClick={() => deleteCartItems(item.product)}>Remove</p>
                                            </div>
                                        </div>
                                        <div className="cartInput">
                                            <button
                                                onClick={() =>
                                                    decreaseQuantity(item.product, item.quantity)
                                                }
                                            >
                                                -
                                            </button>
                                            <input type="number" value={item.quantity} />
                                            <button
                                                onClick={() =>
                                                    increaseQuantity(
                                                        item.product,
                                                        item.quantity,
                                                        item.stock
                                                    )
                                                }
                                            >
                                                +
                                            </button>
                                        </div>
                                        <p className="cartSubtotal">{`₹${item.price * item.quantity}`}</p>
                                    </div>
                                </Card>
                            ))}

                        {/* <div className="cartGrossProfitBox">
        <p>Gross Total</p>
        <p>{`₹${order.orderItems.reduce(
            (acc, item) => acc + item.quantity * item.price,
            0
        )}`}</p>
    </div> */}          {confirmation.confirm === false &&
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button style={{ color: 'white', backgroundColor: '#ff7580', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => setConfirmation({ msg: "Cancel", confirm: true })}>
                                        {/* TODAY */}
                                        {/* <TodayIcon /> */}
                                        <ThumbDownAltOutlinedIcon style={{ marginRight: '4px' }} />
                                        {translator[language]["Cancel"]}
                                    </Button>
                                    <Button style={{ color: 'white', backgroundColor: '#98FB98', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => setConfirmation({ msg: "Accept", confirm: true })} >
                                        {/* <CalendarMonthIcon /> */}
                                        <ThumbUpAltOutlinedIcon style={{ marginRight: '4px' }} />
                                        {translator[language]["Accept"]}
                                    </Button>
                                </Stack>
                            </div>
                        }
                        {confirmation.confirm === true &&
                            <Dialog onClose={handleClose} open={handleOpen}>
                                <DialogTitle>Are you sure you want to {confirmation.msg}?</DialogTitle>
                                <Box m="20px">
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={6}>
                                            <Button onClick={() => handleConfirmation(confirmation.msg, 'accept')} variant="outlined" color="primary">
                                                Yes
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button onClick={() => handleConfirmation(confirmation.msg, 'decline')} variant="outlined" color="secondary">
                                                No
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Dialog>

                            // <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            //     <p>Are you sure you want to {confirmation.msg}?</p>
                            //     <Stack direction="row" spacing={2} alignItems="center">
                            //         {/* <Button style={{ color: 'white', backgroundColor: '#ff7580', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => DeclineHandler(e, 'today')}>
                            //         <ThumbDownAltOutlinedIcon style={{ marginRight: '4px' }} />
                            //         Decline
                            //     </Button> */}
                            //         <Button style={{ color: 'white', backgroundColor: '#ff7580', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => handleConfirmation(e, false, 'decline')} >
                            //             {/* <CalendarMonthIcon /> */}
                            //             {/* <ThumbUpAltOutlinedIcon style={{ marginRight: '4px' }} /> */}
                            //             No
                            //         </Button>
                            //         <Button style={{ color: 'white', backgroundColor: '#98FB98', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => handleConfirmation(e, true, 'accept')} >
                            //             {/* <CalendarMonthIcon /> */}
                            //             {/* <ThumbUpAltOutlinedIcon style={{ marginRight: '4px' }} /> */}
                            //             Yes
                            //         </Button>
                            //     </Stack>
                            // </div>
                        }
                    </div>
                    <br></br>
                    <br></br>
                    <BottomNavBar myProp={user_props} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default CheckOrder;