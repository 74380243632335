import React, { Fragment, useRef, useState, useEffect } from "react";
import "../Admin/addUser.css";
import Loader from "../layout/loader/Loader";
import { Link, useLocation } from "react-router-dom";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import FaceIcon from '@mui/icons-material/Face';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login, register } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { registerToken } from "../../slices/TokenSlice";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

const LoginSignUp = ({ history }) => {
  const genders = [
    "male",
    "female"
  ]
  const languages = [
    "kannada",
    "marathi",
    "hindi",
    "english",
  ]
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();

  const { token, error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const switcherTab = useRef(null);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPhone, setLoginPhone] = useState("");
  const [phone, setRegisterPhone] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  let [loginWithPhone, setLoginWithPhone] = useState(true)
  // let [login_type, setLogin_type] = useState('phone')
  let login_type = 'phone'
  const [user, setUser] = useState({
    name: "",
    email: "",
    gender: "male",
    password: "",
    language: "english",
  });

  const { name, email, password, gender, language } = user;

  const [avatar, setAvatar] = useState("/Profile.png");
  // const [avatarPreview, setAvatarPreview] = useState("/Profile.png");

  const loginSubmit = (e) => {
    e.preventDefault();
    if (loginWithPhone === false) {
      login_type = 'email'
      dispatch(login(loginEmail, loginPassword, login_type));
    } else {
      login_type = 'phone'
      dispatch(login(loginPhone, loginPassword, login_type));
    }
    // storeToken()
  };
  // const storeToken = () => {
  //   if (isAuthenticated) {
  //     console.log(token)
  //     dispatch(registerToken(token))
  //   }
  // };

  const registerSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("password", password);
    myForm.set("avatar", avatar);
    myForm.set("gender", gender);
    myForm.set("language", language);
    // console.log(phone)
    dispatch(register(myForm));
  };

  const registerDataChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          // setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      dispatch(registerToken(token))
      navigate(redirect);
    }
  }, [dispatch, error, alert, navigate, isAuthenticated, redirect,]);

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");

      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
    }
    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");

      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="LoginSignUpContainer">
            <div className="LoginSignUpBox">
              <div>
                <div className="login_signUp_toggle">
                  <p onClick={(e) => switchTabs(e, "login")}>LOGIN</p>
                  <p onClick={(e) => switchTabs(e, "register")}>REGISTER</p>
                </div>
                <button ref={switcherTab}></button>
              </div>
              <form className="loginForm" ref={loginTab} onSubmit={loginSubmit}
              >
                Login with
                {loginWithPhone === true &&
                  <Button style={{ color: 'black', backgroundColor: '#98FB98', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => setLoginWithPhone(false)} >
                    {/* <CalendarMonthIcon /> */}
                    <MailOutlineOutlinedIcon style={{ marginRight: '4px' }} />
                    Email
                  </Button>
                }
                {loginWithPhone === false &&
                  <Button style={{ color: 'black', backgroundColor: '#98FB98', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => setLoginWithPhone(true)} >
                    {/* <CalendarMonthIcon /> */}
                    <LocalPhoneOutlinedIcon style={{ marginRight: '4px' }} />
                    Phone
                  </Button>
                }
                {loginWithPhone === false &&
                  <div >
                    <MailOutlineOutlinedIcon />
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                    />
                  </div>
                }
                {loginWithPhone === true &&
                  <div >
                    <LocalPhoneOutlinedIcon />
                    <input
                      type="number"
                      placeholder="Phone"
                      required
                      value={loginPhone}
                      onChange={(e) => setLoginPhone(e.target.value)}
                    />
                  </div>
                }
                {loginWithPhone !== true &&
                  <div >
                    <LockOpenOutlinedIcon />
                    <input
                      type="password"
                      placeholder="Password"
                      required
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                  </div>
                }
                <Link to="/password/forgot">Forget Password ?</Link>
                <input type="submit" value="Login" className="loginBtn" />
              </form>
              <form
                className="signUpForm"
                ref={registerTab}
                encType="multipart/form-data"
                onSubmit={registerSubmit}
              >
                <div className="signUpName">
                  <FaceIcon />
                  <input
                    type="text"
                    placeholder="Name"
                    required
                    name="name"
                    value={name}
                    onChange={registerDataChange}
                  />
                </div>
                <div className="signUpEmail" >
                  <LocalPhoneOutlinedIcon />
                  <input
                    type="number"
                    placeholder="Phone"
                    required
                    value={phone}
                    onChange={(e) => setRegisterPhone(e.target.value)}
                  />
                </div>
                <div className="signUpEmail">
                  <MailOutlineOutlinedIcon />
                  <input
                    type="email"
                    placeholder="Email"
                    // required
                    name="email"
                    value={email}
                    onChange={registerDataChange}
                  />
                </div>

                <div className="signUpEmail">
                  <select
                    required
                    name="language"
                    value={language}
                    onChange={registerDataChange}
                  >
                    <option value="">Language</option>
                    {languages &&
                      languages.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="signUpEmail">
                  <select
                    required
                    name="gender"
                    value={gender}
                    onChange={registerDataChange}
                  >
                    <option value="">Gender</option>
                    {genders &&
                      genders.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="signUpPassword">
                  <LockOpenOutlinedIcon />
                  <input
                    type="password"
                    placeholder="Password"
                    required
                    name="password"
                    value={password}
                    onChange={registerDataChange}
                  />
                </div>

                {/* <div id="registerImage">
                  <img src={avatarPreview} alt="Avatar Preview" />
                  <input
                    type="file"
                    name="avatar"
                    accept="image/*"
                    onChange={registerDataChange}
                  />
                </div> */}
                <input type="submit" value="Register" className="signUpBtn" />
              </form>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LoginSignUp;