import {
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_RESET,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_RESET,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CLEAR_CATEGORY_ERRORS,
} from "../constants/categoryConstant";

export const createCategoryReducer = (state = { newCategory: {} }, action) => {
    switch (action.type) {
        case CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CREATE_CATEGORY_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                newCategory: action.payload.category,
            };
        case CREATE_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CREATE_CATEGORY_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_CATEGORY_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const categoryReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CATEGORY_REQUEST:
            // case DELETE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
            };

            // case DELETE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload,
            };

        case UPDATE_CATEGORY_FAIL:
            // case DELETE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_CATEGORY_RESET:
            return {
                ...state,
                isUpdated: false,
            };

            // case DELETE_ORDER_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_CATEGORY_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const categoryDetailsReducer = (state = { category: {} }, action) => {
    switch (action.type) {
        case CATEGORY_DETAILS_REQUEST:
            return {
                loading: true,
            };

        case CATEGORY_DETAILS_SUCCESS:

            return {
                loading: false,
                category: action.payload,
            };

        case CATEGORY_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_CATEGORY_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const allCategoriesReducer = (state = { categories: [] }, action) => {
    switch (action.type) {
        case ALL_CATEGORY_REQUEST:
            return {
                loading: true,
            };

        case ALL_CATEGORY_SUCCESS:
            return {
                loading: false,
                categories: action.payload,
            };

        case ALL_CATEGORY_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case CLEAR_CATEGORY_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};