export const ADD_TO_CART = "ADD_TO_CART";

export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

export const SAVE_SHIPPING_INFO = "SAVE_SHIPPING_INFO";

// export const MY_CART_REQUEST = "MY_CART_REQUEST";
// export const MY_CART_SUCCESS = "MY_CART_SUCCESS";
// export const MY_CART_FAIL = "MY_CART_FAIL";

// export const CREATE_CART_REQUEST = "CREATE_CART_REQUEST";
// export const CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS";
// export const CREATE_CART_FAIL = "CREATE_CART_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";