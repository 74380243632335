import React, { Fragment, useEffect, useState } from "react";
// import { DataGrid } from '@mui/x-data-grid';
import "./myReturnOrder.css";
import { useSelector, useDispatch } from "react-redux";
import { clearReturnErrors, myReturnOrders } from "../../actions/returnAction";
import Loader from "../layout/loader/Loader";
// import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/metaData";
// import LaunchIcon from '@mui/icons-material/Launch';
import moment from "moment"
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import { orderBy } from 'lodash';
import Stack from '@mui/material/Stack';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { translator } from "../../translator"

const MyReturnOrders = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();


    const alert = useAlert();

    const { loading, error, returns } = useSelector((state) => state.myReturnOrders);
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [language, setLanguage] = useState("english");

    const setUserLanguage = () => {
        if (isAuthenticated && user && user.language) {
            setLanguage(user.language);
        }
    };
    useEffect(() => {
        setUserLanguage();
    }, [isAuthenticated]);
    let showValue = "all"
    const [rows, setRows] = useState([])
    const [show, setShow] = useState("all")

    const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending order

    const sortedRows = orderBy(rows, 'createdAt', sortOrder);

    if (user && user.role === 'storeEmployee') {
        console.log(user)
        navigate(`/store/dashboard`);
    }

    const handleSortClick = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };
    function yyyymmdd() {
        var x = new Date();
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }
    function Dates(date) {
        var x = new Date(date);
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    }
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let Todaydate = yyyymmdd()

    // const columns = [
    //     { field: "id", headerName: "ID", minWidth: 100, flex: 0.4 },

    //     {
    //         field: "status",
    //         headerName: "Status",
    //         minWidth: 100,
    //         flex: 0.4,
    //         cellClassName: (params) => {
    //             return params.getValue(params.id, "status") === "Collected"
    //                 ? "greenColor"
    //                 : "redColor";
    //         },
    //     },
    //     {
    //         field: "itemsQty",
    //         headerName: "Qty",
    //         type: "number",
    //         minWidth: 100,
    //         flex: 0.4,
    //     },

    //     {
    //         field: "createdAt",
    //         headerName: "Date",
    //         minWidth: 170,
    //         flex: 0.4,
    //     },

    //     {
    //         field: "amount",
    //         headerName: "Rs",
    //         type: "number",
    //         minWidth: 100,
    //         flex: 0.4,
    //     },

    //     {
    //         field: "actions",
    //         flex: 0.4,
    //         headerName: "Details",
    //         minWidth: 100,
    //         type: "number",
    //         sortable: false,
    //         renderCell: (params) => {
    //             return (
    //                 <Link to={`/returns/details/${params.getValue(params.id, "id")}`}>
    //                     <LaunchIcon />
    //                 </Link>
    //             );
    //         },
    //     },
    // ];
    // const rows = [];

    const showOrderDateWise = (e, value) => {
        showValue = value
        if (returns && returns.length > 0) {

            if (showValue === 'month') {
                // const sortedRows = orderBy(rows, 'createdAt', sortOrder);

                // const handleSortClick = () => {
                //     setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                // };
                let rows_temp = [];
                returns &&
                    returns.forEach((item, index) => {
                        if (Dates(item.returnCreatedAt) >= Dates(firstDay) && Dates(item.returnCreatedAt) <= Dates(lastDay)) {
                            rows_temp.push({
                                itemsQty: item.returnItems.length,
                                id: item._id,
                                status: item.returnStatus,
                                amount: item.returnTotalPrice,
                                createdAt: moment(item.returnCreatedAt).format("DD/MM/YY HH:MM:SS")
                            });
                        }
                    });
                setShow('month')
                setRows(rows_temp)
            }
            else if (showValue === 'today') {
                // const sortedRows = orderBy(rows, 'createdAt', sortOrder);

                // const handleSortClick = () => {
                //     setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                // };
                let rows_temp = [];

                returns &&
                    returns.forEach((item, index) => {
                        if (Todaydate === Dates(item.returnCreatedAt)) {
                            rows_temp.push({
                                itemsQty: item.returnItems.length,
                                id: item._id,
                                status: item.returnStatus,
                                amount: item.returnTotalPrice,
                                createdAt: moment(item.returnCreatedAt).format("DD/MM/YY HH:MM:SS")
                            });
                        }
                    });
                setShow('today')
                setRows(rows_temp)
            }
            else if (showValue === 'all') {
                // const sortedRows = orderBy(rows, 'createdAt', sortOrder);

                // const handleSortClick = () => {
                //     setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                // };
                let rows_temp = [];
                returns &&
                    returns.forEach((item, index) => {
                        rows_temp.push({
                            itemsQty: item.returnItems.length,
                            id: item._id,
                            status: item.returnStatus,
                            amount: item.returnTotalPrice,
                            createdAt: moment(item.returnCreatedAt).format("DD/MM/YY HH:MM:SS"),
                        });
                    });
                setShow('all')
                setRows(rows_temp)

            }
        }
    }

    // returns &&
    //     returns.forEach((item, index) => {
    //         rows.push({
    //             itemsQty: item.returnItems.length,
    //             id: item._id,
    //             status: item.returnStatus,
    //             amount: moment(item.returnTotalPrice).format("DD/MM/YY hh:mm:ss"),
    //         });
    //     });
    const theme = createTheme();
    const goToOrderDetails = (e, id) => {
        navigate(`/returns/details/${id}`);
    }

    useEffect(() => {
        dispatch(myReturnOrders());
        showOrderDateWise("", "today")
    }, []);

    useEffect(() => {
        showOrderDateWise("", show)
    }, [returns]);

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearReturnErrors());
        }

        dispatch(myReturnOrders());
    }, [dispatch, alert, error]);

    return (
        <Fragment>
            <MetaData title={`${user.name} - Return Orders`} />

            {loading ? (
                <Loader />
            ) : (

                <div className="productListContainer" id="list">
                    <div className="awesome-phrase-container7">
                        {show === "today" &&
                            <p >{translator[language]["Today's Return Orders"]}</p>
                        }
                        {show === "month" &&
                            <p >{translator[language]["This Month's Return Orders"]}</p>
                        }
                        {show === "all" &&
                            <p >{translator[language]["All Return Orders"]}</p>
                        }
                    </div>

                    {/* <div class="dateToggleContainer" style={{ padding: '2px', display: 'block' }}>
                        <button className="dateToggle" onClick={(e) => showOrderDateWise(e, 'all')} style={{ cursor: 'pointer', fontWeight: 'bolder' }}>ALL ORDERS</button>
                        <button className="dateToggle" onClick={(e) => showOrderDateWise(e, 'today')} style={{ cursor: 'pointer', fontWeight: 'bolder' }}>TODAY</button>
                        <button className="dateToggle" onClick={(e) => showOrderDateWise(e, 'month')} style={{ cursor: 'pointer', fontWeight: 'bolder' }}>THIS MONTH</button>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'today')} variant="outlined" size="small">
                                {/* TODAY */}
                                <TodayIcon />
                                {translator[language]["Today"]}
                            </Button>
                            <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'month')} variant="outlined" size="small">
                                <CalendarMonthIcon />
                                {translator[language]["Month"]}
                            </Button>
                            <Button style={{ color: '#002244', backgroundColor: 'white', borderRadius: '5px', padding: '10px' }} className="card-button" onClick={(e) => showOrderDateWise(e, 'all')} variant="outlined" size="small" >
                                <CalendarTodayIcon />
                                {translator[language]["All"]}
                            </Button>
                        </Stack>
                    </div>
                    {/* <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        className="myReturnOrdersTable"
                        autoHeight
                        sx={{
                            margin: '2%',
                            bgcolor: '#F0F8FF',
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                    /> */}
                    <Button onClick={handleSortClick}>{translator[language]["Sort by date"]} ({sortOrder === 'asc' ? 'latest' : 'oldest'})</Button>
                    <div className="awesome-phrase-container8">
                        <p className="awesome-phrase2">
                            {translator[language]["The Recalled and Rejected: When shopping goes wrong, but at least you get a second chance."]} 🤔
                        </p>
                    </div>
                    <ThemeProvider theme={theme}>
                        <main style={{ overflowY: 'auto', height: '100%' }}>
                            <Container sx={{ py: 8 }}>
                                {/* End hero unit */}
                                <Grid container spacing={4}>
                                    {sortedRows.map((row) => (
                                        <Grid item key={row.id} xs={12} sm={6} md={6}>
                                            <Card variant="outlined"
                                                sx={{
                                                    height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', transition: 'transform 0.2s ease-in-out'
                                                }}
                                            >
                                                {row.status === 'Collected' ? (
                                                    <Typography style={{ color: '#69f0ae', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">{row.status}</Typography>
                                                ) : (
                                                    <Typography style={{ color: '#ff8a80', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h2">{row.status}</Typography>
                                                )
                                                }
                                                <CardContent sx={{ flexGrow: 1 }}>
                                                    <Typography style={{ color: '#002244', fontWeight: 'bolder', marginTop: '5%' }} gutterBottom variant="h5" component="h2">
                                                        {row.id}
                                                    </Typography>
                                                    <Typography style={{ color: '#002244', marginTop: '5%' }}>
                                                        {row.createdAt}
                                                    </Typography>
                                                    <Typography style={{ color: '#002244', marginTop: '5%' }}>
                                                        {translator[language]["Qty"]} {row.itemsQty}
                                                    </Typography>
                                                    <Typography style={{ marginTop: '10px', color: '#002244', fontWeight: 'bolder', marginTop: '5%' }}>
                                                        <span style={{ backgroundColor: '#e8eaf6', padding: '10px', borderRadius: '5px' }}>Rs {row.amount.toFixed(2)}</span>

                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small" onClick={(e) => goToOrderDetails(e, row.id)}>{translator[language]["View Details"]}</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </main>
                    </ThemeProvider>

                    {/* <div className="orderDetailsContainerBox">{user.name}'s Return Orders</div> */}
                </div>
            )}
        </Fragment>
    );
};

export default MyReturnOrders;