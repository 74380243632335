import {
    CREATE_COMMISSION_REQUEST,
    CREATE_COMMISSION_SUCCESS,
    CREATE_COMMISSION_FAIL,
    MY_COMMISSION_REQUEST,
    MY_COMMISSION_SUCCESS,
    MY_COMMISSION_FAIL,
    ALL_COMMISSIONS_REQUEST,
    ALL_COMMISSIONS_SUCCESS,
    ALL_COMMISSIONS_FAIL,
    UPDATE_COMMISSION_REQUEST,
    UPDATE_COMMISSION_SUCCESS,
    UPDATE_COMMISSION_FAIL,
    DELETE_COMMISSION_REQUEST,
    DELETE_COMMISSION_SUCCESS,
    DELETE_COMMISSION_FAIL,
    COMMISSION_DETAILS_REQUEST,
    COMMISSION_DETAILS_SUCCESS,
    COMMISSION_DETAILS_FAIL,
    CLEAR_COMMISSION_ERRORS,
} from "../constants/commissionConstant";

import axios from "axios";
const apiHelper = require("../helpers")

// Create Order
export const createCommission = (commission) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: CREATE_COMMISSION_REQUEST });
        const { data } = await axios.post(apiHelper.api + "commission/new", commission, config);

        dispatch({ type: CREATE_COMMISSION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CREATE_COMMISSION_FAIL,
            payload: error.response.data.message,
        });
    }
};

// My Commissions
export const myCommissions = () => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: MY_COMMISSION_REQUEST });

        const { data } = await axios.get(apiHelper.api + "commissions/me", config);

        dispatch({ type: MY_COMMISSION_SUCCESS, payload: data.commissions });
    } catch (error) {
        dispatch({
            type: MY_COMMISSION_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Commissions
export const getAllCommissions = () => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: ALL_COMMISSIONS_REQUEST });

        const { data } = await axios.get(apiHelper.api + "admin/commissions", config);

        dispatch({ type: ALL_COMMISSIONS_SUCCESS, payload: data.commissions });
    } catch (error) {
        dispatch({
            type: ALL_COMMISSIONS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update Commission
export const updateCommission = (id, commission, request) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    if (request === 'requestCommission') {
        try {
            dispatch({ type: UPDATE_COMMISSION_REQUEST });
            // if (order.status != "Cancelled") {
            const { data } = await axios.put(
                `${apiHelper.api}commission/request`,
                commission,
                config
            );
            dispatch({ type: UPDATE_COMMISSION_SUCCESS, payload: data.success });
            // } else {
            //     const { data } = await axios.put(
            //         `/api/v1/admin/orders/cancel/${id}`,
            //         order,
            //         config
            //     );
            //     dispatch({ type: UPDATE_COMMISSION_SUCCESS, payload: data.success });
            // }


        } catch (error) {
            dispatch({
                type: UPDATE_COMMISSION_FAIL,
                payload: error.response.data.message,
            });
        }
    } else if (request === 'processCommission') {

        try {
            dispatch({ type: UPDATE_COMMISSION_REQUEST });
            // if (order.status != "Cancelled") {
            const { data } = await axios.put(
                `${apiHelper.api}admin/commissions/process`,
                commission,
                config
            );
            dispatch({
                type: UPDATE_COMMISSION_SUCCESS, payload: data.success
            });
            // } else {
            //     const { data } = await axios.put(
            //         `/api/v1/admin/orders/cancel/${id}`,
            //         order,
            //         config
            //     );
            //     dispatch({ type: UPDATE_COMMISSION_SUCCESS, payload: data.success });
            // }


        } catch (error) {
            dispatch({
                type: UPDATE_COMMISSION_FAIL,
                payload: error.response.data.message,
            });
        }
    } else {
        try {
            dispatch({ type: UPDATE_COMMISSION_REQUEST });
            // if (order.status != "Cancelled") {
            const { data } = await axios.put(
                `${apiHelper.api}admin/commission/${id}`,
                commission,
                config
            );
            dispatch({
                type: UPDATE_COMMISSION_SUCCESS, payload: data.success
            });
            // } else {
            //     const { data } = await axios.put(
            //         `/api/v1/admin/orders/cancel/${id}`,
            //         order,
            //         config
            //     );
            //     dispatch({ type: UPDATE_COMMISSION_SUCCESS, payload: data.success });
            // }


        } catch (error) {
            dispatch({
                type: UPDATE_COMMISSION_FAIL,
                payload: error.response.data.message,
            });
        }
    }
};

// Delete Order
export const deleteCommission = (id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: DELETE_COMMISSION_REQUEST });

        const { data } = await axios.delete(`${apiHelper.api}admin/commission/${id}`, config);

        dispatch({ type: DELETE_COMMISSION_SUCCESS, payload: data.success });
    } catch (error) {
        dispatch({
            type: DELETE_COMMISSION_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Commission Details
export const getCommissionDetails = (id) => async (dispatch, getState) => {
    const { token } = getState().token

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        withCredentials: true,
    };
    try {
        dispatch({ type: COMMISSION_DETAILS_REQUEST });

        const { data } = await axios.get(`${apiHelper.api}commission/${id}`, config);

        dispatch({ type: COMMISSION_DETAILS_SUCCESS, payload: data.commission });
    } catch (error) {
        dispatch({
            type: COMMISSION_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clearing Errors
export const clearCommissionErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_COMMISSION_ERRORS });
};