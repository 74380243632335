import React, { Fragment, useEffect, useState } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartActions";
import MetaData from "../layout/metaData";
import PinDropIcon from '@mui/icons-material/PinDrop';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import PhoneIcon from '@mui/icons-material/Phone';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { Country, State } from "country-state-city";
import { useAlert } from "react-alert";
import CheckoutSteps from "../Cart/CheckoutSteps.js";
import { useNavigate } from 'react-router-dom';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CurrentLocation from '../Utils/FetchCurrentLocation'
import ScheduleDelivery from "./ScheduleDelivery";
import DateTimePicker from './ScheduleDelivery';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Divider,
  Typography
} from '@mui/material';
import { translator } from "../../translator"

const Shipping = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { shippingInfo } = useSelector((state) => state.cart);
  shippingInfo.country = "IN"
  // shippingInfo.pinCode = 0
  // shippingInfo.state = "State"
  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [state, setState] = useState(shippingInfo.state);
  const [country, setCountry] = useState(shippingInfo.country);
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [user_note, setUser_note] = useState(shippingInfo.user_note);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("english");

  const setUserLanguage = () => {
    if (isAuthenticated && user && user.language) {
      setLanguage(user.language);
    }
  };
  useEffect(() => {
    setUserLanguage();
  }, [isAuthenticated]);

  const handlePincodeChange = (e) => {
    const newPincode = e.target.value;
    setPinCode(newPincode);
    fetchStateFromPincode(newPincode);
  };
  const fetchStateFromPincode = (pincode) => {
    fetch(`https://api.postalpincode.in/pincode/${pincode}`)
      .then((response) => response.json())
      .then((data) => {
        if (data[0].Status === "Success") {
          setState(data[0].PostOffice[0].State);
        } else {
          setState("");
        }
      })
      .catch((error) => {
        console.error("Error fetching state from pincode:", error);
      });
  };

  const shippingSubmit = (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      alert.error("Phone Number should be 10 digits Long");
      return;
    }
    let scheduledDelivery = false
    if (selectedDateTime) {
      scheduledDelivery = true
    }
    dispatch(
      saveShippingInfo({ address, city, state, country, pinCode, phoneNo, user_note, selectedDateTime, scheduledDelivery })
    );
    navigate("/order/confirm");
  };

  const setCurrentLocation = (rawData) => {
    setAddress(rawData['address'])
    setCity(rawData['city'])
    setState(rawData['state'])
    // setCountry(rawData['country'])
    setPinCode(rawData['pincode'])
  };
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
  };

  const openDateTimePicker = () => {
    setIsDateTimePickerOpen(true);
  };

  const closeDateTimePicker = () => {
    setIsDateTimePickerOpen(false);
  };
  return (
    <Fragment>
      <MetaData title="Shipping Details" />

      <CheckoutSteps activeStep={0} />
      <Divider />
      <br></br>
      <CurrentLocation shippingDetails={setCurrentLocation} />
      {selectedDateTime ? (
        <div>
          <Box m="20px">
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Typography style={{ color: '#002244', fontWeight: 'bold', marginTop: '5%' }} gutterBottom variant="h5" component="h4">{translator[language]["Your order will be delivered on"]}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ color: '#69f0ae', margin: '5%', fontWeight: 'bolder' }} gutterBottom variant="h5" component="h3">{selectedDateTime}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="outlined" color="secondary" onClick={() => setSelectedDateTime(null)}>{translator[language]["Deliver Now"]}</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </div>
      ) : (
        <div className="button-container">
          <Button variant="outlined" color="primary" onClick={openDateTimePicker}>{translator[language]["Schedule Your Delivery"]}</Button>
        </div>
      )}
      {isDateTimePickerOpen && (
        <DateTimePicker
          open={isDateTimePickerOpen}
          onClose={closeDateTimePicker}
          onDateTimeChange={handleDateTimeChange}
        />
      )}
      <br></br>
      <Divider />
      <div className="shippingContainer">
        <div className="shippingBox">
          <h2 className="shippingHeading">{translator[language]["Shipping Details"]}</h2>
          {/* <div>
            <ScheduleDelivery onDateTimeChange={handleDateTimeChange} />
            {selectedDateTime && (
              <p>Selected Date and Time: {selectedDateTime.toString()}</p>
            )}
          </div> */}

          <form
            className="shippingForm"
            encType="multipart/form-data"
            onSubmit={shippingSubmit}
          >
            <div>
              <HomeIcon />
              <input
                type="text"
                placeholder={translator[language]["Address"]}
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div>
              <LocationCityIcon />
              <input
                type="text"
                placeholder={translator[language]["City"]}
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div>
              <PinDropIcon />
              <input
                type="number"
                placeholder={translator[language]["Pin Code"]}
                required
                value={pinCode}
                onChange={handlePincodeChange}
              />
            </div>

            <div>
              <PhoneIcon />
              <input
                type="number"
                placeholder={translator[language]["Phone Number"]}
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                size="10"
              />
            </div>

            <div>
              <PublicIcon />

              <select
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">{translator[language]["Country"]}</option>
                {Country &&
                  Country.getAllCountries().map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>

            {country && (
              <div>
                <TransferWithinAStationIcon />

                <select
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value={state}>{state}</option>
                  {State &&
                    State.getStatesOfCountry(country).map((item) => (
                      <option key={item.isoCode} value={item.isoCode}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <div>
              <EventNoteIcon />
              <input
                type="text"
                placeholder={translator[language]["Write a note for reference"]}
                value={user_note}
                onChange={(e) => setUser_note(e.target.value)}
              />
            </div>

            <input
              type="submit"
              value={translator[language]["Continue"]}
              className="shippingBtn"
              disabled={state ? false : true}
            />
            <br></br>
          </form>
        </div>

      </div>
    </Fragment>
  );
};

export default Shipping;