export const CREATE_COMMISSION_REQUEST = "CREATE_COMMISSION_REQUEST";
export const CREATE_COMMISSION_SUCCESS = "CREATE_COMMISSION_SUCCESS";
export const CREATE_COMMISSION_FAIL = "CREATE_COMMISSION_FAIL";

export const MY_COMMISSION_REQUEST = "MY_COMMISSION_REQUEST";
export const MY_COMMISSION_SUCCESS = "MY_COMMISSION_SUCCESS";
export const MY_COMMISSION_FAIL = "MY_COMMISSION_FAIL";

export const ALL_COMMISSIONS_REQUEST = "ALL_COMMISSIONS_REQUEST";
export const ALL_COMMISSIONS_SUCCESS = "ALL_COMMISSIONS_SUCCESS";
export const ALL_COMMISSIONS_FAIL = "ALL_COMMISSIONS_FAIL";

export const UPDATE_COMMISSION_REQUEST = "UPDATE_COMMISSION_REQUEST";
export const UPDATE_COMMISSION_SUCCESS = "UPDATE_COMMISSION_SUCCESS";
export const UPDATE_COMMISSION_RESET = "UPDATE_COMMISSION_RESET";
export const UPDATE_COMMISSION_FAIL = "UPDATE_COMMISSION_FAIL";

export const DELETE_COMMISSION_REQUEST = "DELETE_COMMISSION_REQUEST";
export const DELETE_COMMISSION_SUCCESS = "DELETE_COMMISSION_SUCCESS";
export const DELETE_COMMISSION_RESET = "DELETE_COMMISSION_RESET";
export const DELETE_COMMISSION_FAIL = "DELETE_COMMISSION_FAIL";

export const COMMISSION_DETAILS_REQUEST = "COMMISSION_DETAILS_REQUEST";
export const COMMISSION_DETAILS_SUCCESS = "COMMISSION_DETAILS_SUCCESS";
export const COMMISSION_DETAILS_FAIL = "COMMISSION_DETAILS_FAIL";

export const CLEAR_COMMISSION_ERRORS = "CLEAR_COMMISSION_ERRORS";