// import React from "react";
// import { ReactNavbar } from "overlay-navbar";
// import { MdAccountCircle } from "react-icons/md";
// import { MdSearch } from "react-icons/md";
// import { MdAddShoppingCart } from "react-icons/md"
// import { FaUserAlt } from "react-icons/fa"
// import logo from "../../../images/logo_icon.png";
// const options = {
//   burgerColorHover: "lightgreen",
//   logo,
//   logoWidth: "100px",
//   navColor1: "#252324",
//   logoHoverSize: "10px",
//   logoHoverColor: "lightblue",
//   link1Text: "Home",
//   link2Text: "Products",
//   link3Text: "Contact",
//   link4Text: "About",
//   link1Url: "/",
//   link2Url: "/products",
//   link3Url: "/contact",
//   link4Url: "/about",
//   link1Size: "1.3vmax",
//   link1Color: "lightgreen ",
//   nav1justifyContent: "flex-end",
//   nav2justifyContent: "flex-end",
//   nav3justifyContent: "flex-start",
//   nav4justifyContent: "flex-start",
//   link1ColorHover: "lightgreen",
//   link1Margin: "1vmax",
//   searchIconMargin: "1vmax",
//   cartIconMargin: "1vmax",
//   profileIconMargin: "1vmax",
//   profileIcon: true,
//   ProfileIconElement: MdAccountCircle,
//   profileIconUrl: "/login",
//   // profileIconColor: "lightgreen",
//   searchIcon: true,
//   SearchIconElement: MdSearch,
//   // searchIconColor: "lightgreen",
//   cartIcon: true,
//   CartIconElement: MdAddShoppingCart,
//   // cartIconColor: "lightgreen",
//   profileIconColorHover: "lightblue",
//   searchIconColorHover: "lightblue",
//   cartIconColorHover: "lightblue",
//   profileIconElement: { FaUserAlt },
//   nav1Transition: 0.4,
//   nav2Transition: 0.4,
//   nav3Transition: 0.4,
//   nav4Transition: 0.4,
//   link1Transition: 0.4,
//   link2Transition: 0.4,
//   link3Transition: 0.4,
//   link4Transition: 0.4,
//   link1AnimationTime: 0.4,
//   link2AnimationTime: 0.4,
//   link3AnimationTime: 0.4,
//   link4AnimationTime: 0.4,
//   searchIconTransition: 0.4,
//   cartIconTransition: 0.4,
//   profileIconTransition: 0.4,
//   searchIconAnimationTime: 0.4,
//   cartIconAnimationTime: 0.4,
//   profileIconAnimationTime: 0.4,
//   logoTransition: 0.4,
//   logoAnimationTime: 0.4
// };

// const Header = () => {
//   return <ReactNavbar {...options} />
// };

// export default Header;

import React, { Fragment, useState } from "react";
import "./Header.css";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Backdrop from '@mui/material/Backdrop';
// import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";
import logo from "../../../images/profile.png";
import PlumbingIcon from '@mui/icons-material/Plumbing';
import { } from "react-alert";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu'; // Import the MenuIcon

const Header = ({ user }) => {

  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  const options = [
    { icon: <HomeIcon />, name: "Home", func: home },
    { icon: <PlumbingIcon />, name: "Products", func: products },
    { icon: <BusinessIcon />, name: "About", func: about },
    { icon: <CallIcon />, name: "Contact", func: contact },
    // { icon: <SearchIcon />, name: "Search", func: search },
    { icon: <LoginIcon />, name: "Login", func: login },
  ];

  function home() {
    navigate("/");
  }
  function products() {
    navigate("/products");
  }
  function about() {
    navigate("/about");
  }
  function contact() {
    navigate("/contact");
  }
  // function search() {
  //   navigate("/search");
  // }
  function login() {
    navigate("/login");
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Fragment>
      <Backdrop open={open} style={{ zIndex: 9 }} />
      <SpeedDial
        sx={{ color: 'red' }}
        ariaLabel="SpeedDial tooltip example"
        FabProps={{
          size: 'small', style: {
            backgroundColor: 'transparent', boxShadow: 'none' // Set the background color to transparent
          },
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        style={{ zIndex: 10, elevation: 0 }}
        open={open}
        direction="down"
        className="speedDial flat"
        icon={<MenuIcon style={{ color: 'black' }} />} // Use the MenuIcon component
      >
        {options.map((item) => (
          <SpeedDialAction
            key={item.name}
            icon={item.icon}
            tooltipTitle={item.name}
            onClick={item.func}
            tooltipOpen={matches} // Use the matches value to determine tooltipOpen
          />
        ))}
      </SpeedDial>
    </Fragment>
    // <Fragment>
    //   <Backdrop open={open} style={{ zIndex: "10" }} />
    //   <SpeedDial
    //     ariaLabel="SpeedDial tooltip example"
    //     onClose={() => setOpen(false)}
    //     onOpen={() => setOpen(true)}
    //     style={{ zIndex: "11" }}
    //     open={open}
    //     direction="down"
    //     className="speedDial"
    //     icon={
    //       <img
    //         className="speedDialIcon"
    //         src={logo} alt="plumbpigen"
    //       />
    //     }
    //   >
    //     {options.map((item) => (
    //       <SpeedDialAction
    //         key={item.name}
    //         icon={item.icon}
    //         tooltipTitle={item.name}
    //         onClick={item.func}
    //         tooltipOpen={window.innerWidth <= 600 ? true : false}
    //       />
    //     ))}
    //   </SpeedDial>
    // </Fragment>
  );
};

export default Header;